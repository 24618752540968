import { VisitStatusEnum } from "@/__generated__/graphql";
import { formatDate } from "@/lib/helpers/date";
import { fullName } from "@/lib/helpers/string";

export function formatVisitSchedule(
  startTime: string,
  endTime: string,
  serviceDate: Date,
) {
  const formatTimeWithoutSeconds = (time: string) => time.slice(0, 5);
  return `${formatDate(serviceDate, "iiii, LLL d", true)} at ${formatTimeWithoutSeconds(startTime)} - ${formatTimeWithoutSeconds(endTime)}`;
}

export function formatVisitAddress(
  fullAddress: string,
  unitNumber: string,
  postalCode: string,
) {
  if (!fullAddress) {
    return "Address not available";
  }

  return `${fullAddress} ${unitNumber}, Singapore ${postalCode}`;
}

export function visitWorkerFullName(
  status: VisitStatusEnum,
  worker?: { firstName: string; lastName: string } | null,
) {
  if (!worker) {
    return status === VisitStatusEnum.UnableToSchedule
      ? "Unassigned"
      : "Pending Assignment";
  }

  return fullName(worker);
}

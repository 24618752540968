import { BottomDrawerModal } from "@/components/shared/bottom-drawer-modal";
import { Typography } from "@/components/shared/typography";
import { View, Button } from "@/components/ui";
import { onChatSales } from "@/lib/chat-sales";

export const SalesModal = ({
  open,
  setOpen,
  title,
  description,
}: {
  open: boolean;
  title: string;
  description: string;
  setOpen(open: boolean): void;
}) => {
  return (
    <BottomDrawerModal open={open} onOpenChange={() => setOpen(false)}>
      <View className="flex flex-col gap-4">
        <Typography variant={"label-xl"}>{title}</Typography>
        <Typography variant={"body-lg"} color={"foreground-intermediate"}>
          {description}
        </Typography>
        <Button
          variant="primary"
          color="success"
          onClick={() => {
            onChatSales(true);
          }}
          fullWidth="full"
          iconName="whatsappLogo"
          iconColor="fill-white"
          children="Chat with Us"
        />
      </View>
    </BottomDrawerModal>
  );
};

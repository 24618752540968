import { View, Image } from "@/components/ui";
import { cn } from "@/lib/utils";
import { Typography } from "../../typography";
import { useIntl } from "react-intl";
import AppStoreSocialBtn from "@/assets/icons/svgs/app-store-social-btn.svg";
import GooglePlaySocialBtn from "@/assets/icons/svgs/google-play-social-btn.svg";
import { links } from "@/constants";
import { handleOpenLink } from "@/lib/platform";
import { sendAnalyticData } from "@/lib/monitoring/analytics";

export function GetTheAppNow({
  size = "large",
  className = "",
}: {
  size?: "small" | "large";
  className?: string;
}) {
  const intl = useIntl();
  const sendToRudderStack = async (href: string) => {
    await handleOpenLink(href);
    sendAnalyticData({
      name: "app_download_button_click",
      values: {
        button_location: href,
      },
    });
  };
  return (
    <View
      className={cn("item grid", className, {
        "gap-2": size === "small",
        "gap-3": size === "large",
      })}
    >
      <Typography color="white" align="center">
        {intl.formatMessage({
          defaultMessage: "Get the app now!",
          id: "get-the-app-now",
        })}
      </Typography>

      <View className="flex items-center justify-center gap-2">
        <View
          onClick={() => sendToRudderStack(links.appStoreClientApp)}
          className={cn(
            "flex cursor-pointer items-center justify-center rounded-full border border-primary-border bg-primary-surface",
            {
              "h-[32px] w-[103px]": size === "small",
              "h-[38px] w-[122px]": size === "large",
            },
          )}
        >
          <Image src={AppStoreSocialBtn} />
        </View>
        <View
          onClick={() => sendToRudderStack(links.googlePlayClientApp)}
          className={cn(
            "flex cursor-pointer items-center justify-center rounded-full border border-primary-border bg-primary-surface",
            {
              "h-[32px] w-[103px]": size === "small",
              "h-[38px] w-[122px]": size === "large",
            },
          )}
        >
          <Image src={GooglePlaySocialBtn} />
        </View>
      </View>
    </View>
  );
}

import { Dialog, DialogContent, View, Button } from "@/components/ui";
import type { Icon } from "@/components/shared/icons";
import { CheckCircle } from "@/components/shared/icons";
import { cn } from "@/lib/utils";
import { Typography } from "../typography";

export const InfoDialog = ({
  title = "Thank you",
  subtitle,
  open,
  icon: Icon = CheckCircle,
  iconClassName,
  onClose,
  buttonText = "Ok",
}: {
  title?: string;
  subtitle: string;
  open: boolean;
  icon?: Icon;
  iconClassName?: string;
  onClose: () => void;
  buttonText?: string;
}) => (
  <Dialog open={open} onOpenChange={onClose}>
    <DialogContent className="w-[343px] max-w-full rounded-lg">
      <View className="flex flex-col items-stretch gap-2">
        <Icon
          size={60}
          weight="fill"
          className={cn("mx-auto color-success", iconClassName)}
        />
        <Typography variant="label-2xl" align="center">
          {title}
        </Typography>
        <Typography
          variant="body-lg"
          color="foreground-intermediate"
          align="center"
        >
          {subtitle}
        </Typography>
        <Button
          variant="primary"
          color="CTA"
          rounded="full"
          className="mt-2"
          onClick={onClose}
          children={buttonText}
        />
      </View>
    </DialogContent>
  </Dialog>
);

import * as React from "react";
import { View } from "@/components/ui";
import type { CarouselApi } from "@/components/ui/carousel";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { cn } from "@/lib/utils";
import { Divider } from "../divider";

export function HorizontalScrollView({
  showNavigation = true,
  reverseHeader = false,
  headerDivider = false,
  HeaderComponent = <View className="flex-1" />,
  className,
  contentClassName,
  contentClassNames,
  children,
  slideSize = "basis-full",
  setApi,
}: {
  showNavigation?: boolean;
  headerDivider?: boolean;
  reverseHeader?: boolean;
  HeaderComponent?: React.ReactNode;
  className?: string;
  contentClassName?: string;
  contentClassNames?: string[];
  contentWidths?: number[]; // native width only
  children: React.ReactNode[];
  slideSize?: `${"basis-"}${string}`; // set the slide size using flex basis
  setApi?: (api: CarouselApi) => void;
}) {
  return (
    <Carousel
      setApi={setApi}
      className={cn("HorizontalScrollView flex flex-col gap-4", className)}
    >
      <View
        className={cn(
          "flex justify-between gap-2",
          reverseHeader ? "flex-row-reverse" : "flex-row",
        )}
      >
        {HeaderComponent}
        {showNavigation && (
          <View className="flex flex-row items-center gap-2 max-md:hidden">
            <CarouselPrevious className="relative left-0 top-0 flex-1 transform-none" />
            <CarouselNext className="relative right-0 top-0 flex-1 transform-none" />
          </View>
        )}
      </View>
      {headerDivider && <Divider className="-mx-4" />}
      <CarouselContent className="overflow-visible">
        {children.map((item, idx) => {
          return (
            <CarouselItem
              key={idx}
              className={cn(
                slideSize,
                contentClassName,
                contentClassNames?.[idx],
              )}
            >
              {item}
            </CarouselItem>
          );
        })}
      </CarouselContent>
    </Carousel>
  );
}

import { createRequestFactory } from "@/lib/request-factory";
import {
  DeleteAddressDocument,
  type DeleteAddressMutation,
  type DeleteAddressMutationVariables,
} from "@/__generated__/graphql";

type Response = {
  result: boolean;
};

export const useDeleteAddressStore = createRequestFactory<
  DeleteAddressMutation,
  Response,
  DeleteAddressMutationVariables
>({
  method: "mutation",
  graphqlDocument: DeleteAddressDocument,
  transformFunction: (res) => {
    if (res.deleteAddress?.result) {
      return { result: res.deleteAddress.result } as Response;
    }
    throw new Error("Delete address failed");
  },
});

import { useWindowDimensions } from "@/components/hooks/use-window-dimension";
import { Button, Card, CardContent, Skeleton, View } from "@/components/ui";
import {
  CaretRight,
  Receipt,
  Notepad,
  ArrowLeft,
} from "@/components/shared/icons";
import { IconButton } from "@/components/ui/icon-button";
import { PaymentStatusBadge } from "@/components/shared/invoices/invoice-card/payment-status-badge";

import { Link } from "@/components/shared/link";
import { Divider } from "@/components/shared/divider";
import { formatDate } from "@/lib/helpers/date";
import { LineItemList } from "@/components/shared/line-item/line-item-list";
import { ListHeading } from "@/components/shared/list-header";
import { formatPrice, SINGAPORE_TAX } from "@/lib/helpers/number";
import { Typography } from "@/components/shared/typography";
import { extractTokenFromUrl, GST_REGISTRATION } from "@/lib/helpers/string";
import { InvoicePaymentStatusEnum } from "@/__generated__/graphql";
import { copyClipboard } from "@/lib/helpers/copy-clipboard";
import { IfElse } from "@/components/shared/if-else";
import { ApplyVoucherModal } from "../../apply-voucher-modal";
import type { InvoiceDetailProps } from "..";
import { ListItem } from "../list-item";
import { AvailableVoucher } from "../available-voucher";

export function InvoiceDetailScreen({
  handlePay,
  handleVoucher,
  invoice,
  loading,
  onCloseVoucherModal,
  showApplyVoucherModal,
  invoiceId,
}: InvoiceDetailProps) {
  const { isMobile } = useWindowDimensions();

  return (
    <View className="my-0 md:my-5">
      <View className="mb-2 flex flex-row items-center justify-between gap-2">
        <View className="flex flex-row items-center gap-2 p-4 md:p-0">
          {isMobile && (
            <Link href="/profile/invoices">
              <ArrowLeft className="size-5" />
            </Link>
          )}
          <Typography variant={{ md: "label-2xl", sm: "label-xl" }}>
            Invoice Details
          </Typography>
        </View>
        {[
          InvoicePaymentStatusEnum.Unpaid,
          InvoicePaymentStatusEnum.Underpaid,
        ].includes(invoice.paymentStatus) &&
          !invoice.clientPaymentIndicated && (
            <Button
              variant="primary"
              color="CTA"
              className="min-w-[100px]"
              rounded="full"
              onClick={handlePay}
              children="Pay"
            />
          )}
      </View>
      <Divider />

      {[
        InvoicePaymentStatusEnum.Unpaid,
        InvoicePaymentStatusEnum.Underpaid,
      ].includes(invoice.paymentStatus) &&
        !invoice.clientPaymentIndicated && (
          <View className="mb-5">
            <AvailableVoucher onClick={handleVoucher} />
          </View>
        )}
      <Card
        border={isMobile ? "none" : "default"}
        className="mb-[144px] md:mb-0"
      >
        <CardContent className="flex flex-col gap-2">
          <View className="flex flex-row justify-between gap-2">
            <View className="flex flex-row items-center gap-1">
              <Typography variant="label-lg">Invoice #{invoiceId}</Typography>
              <IconButton
                variant="ghost"
                color="foreground"
                size="sm"
                className="p-0"
                iconName="copy"
                onClick={() => copyClipboard(`Invoice #${invoiceId}`)}
              />
            </View>
            {loading ? (
              <Skeleton className="h-5 w-[100px]" />
            ) : (
              <Link
                href={
                  invoice.pdfUrl
                    ? `view-invoice-pdf?token=${extractTokenFromUrl(invoice.pdfUrl)}`
                    : "#"
                }
              >
                <View className="flex flex-row items-center gap-1">
                  <Typography variant="label-lg" color="brand-primary">
                    View PDF
                  </Typography>
                  <CaretRight className="size-4 text-brand-primary" />
                </View>
              </Link>
            )}
          </View>
          <Divider />
          {invoice.paymentStatus === InvoicePaymentStatusEnum.Paid && (
            <>
              <View className="flex flex-row justify-between gap-2">
                <View className="flex flex-row items-center gap-1">
                  <Typography variant="label-lg">
                    Receipt #{invoiceId}
                  </Typography>
                  <IconButton
                    variant="ghost"
                    color="foreground"
                    className="p-0"
                    size="sm"
                    iconName="copy"
                    onClick={() => copyClipboard(`Receipt #${invoiceId}`)}
                  />
                </View>
                {loading ? (
                  <Skeleton className="h-5 w-[100px]" />
                ) : (
                  <Link
                    href={
                      invoice.pdfReceiptUrl
                        ? `view-receipt-pdf?token=${extractTokenFromUrl(invoice.pdfReceiptUrl)}`
                        : "#"
                    }
                  >
                    <View className="flex flex-row items-center gap-1">
                      <Typography variant="label-lg" color="brand-primary">
                        View PDF
                      </Typography>
                      <CaretRight className="size-4 text-brand-primary" />
                    </View>
                  </Link>
                )}
              </View>
              <Divider />
            </>
          )}
          <ListItem label="Status">
            {loading ? (
              <Skeleton className="h-5 w-[100px]" />
            ) : (
              <PaymentStatusBadge
                balance={invoice.balance}
                amount={invoice.amount}
                clientPaymentIndicated={invoice.clientPaymentIndicated}
                dueDate={invoice.dueDate}
                status={invoice.paymentStatus}
              />
            )}
          </ListItem>
          <Divider />
          <ListItem label="Invoice Date">
            {loading ? (
              <Skeleton className="h-4 w-[200px]" />
            ) : (
              <Typography variant="label-lg">
                {formatDate(invoice.issueDate, "dd MMM yyyy")}
              </Typography>
            )}
          </ListItem>
          <Divider />
          <ListItem label="GST Registration No">
            <Typography variant="label-lg">{GST_REGISTRATION}</Typography>
          </ListItem>
          <Divider />
          <ListItem label="Due Date">
            {loading ? (
              <Skeleton className="h-4 w-[200px]" />
            ) : (
              <Typography variant="label-lg">
                {formatDate(invoice.dueDate, "dd MMM yyyy")}
              </Typography>
            )}
          </ListItem>
        </CardContent>
        <Divider />
        <CardContent className="flex flex-col gap-2">
          <View className="flex flex-col gap-1">
            <View className="py-2">
              <ListHeading icon={Notepad} title="Items" />
            </View>
            <LineItemList lineItems={invoice.lineItems} withPrice />
          </View>
          <Divider className="py-2" />
          <View className="flex flex-col gap-1">
            <View className="py-2">
              <ListHeading icon={Receipt} title="Payment Summary" />
            </View>
            <ListItem label="Subtotal">
              {loading ? (
                <Skeleton className="h-4 w-[200px]" />
              ) : (
                <View className="flex flex-col items-end">
                  <Typography variant="label-lg">
                    {formatPrice(invoice.baseAmount)}
                  </Typography>
                  <IfElse if={!!invoice.appliedVoucher}>
                    <Typography variant="label-xs" color="brand-primary">
                      The total of {formatPrice(invoice.baseAmount)} includes
                      your voucher discount*
                    </Typography>
                  </IfElse>
                </View>
              )}
            </ListItem>
            <ListItem label={`GST (${SINGAPORE_TAX * 100}%)`}>
              {loading ? (
                <Skeleton className="h-4 w-[200px]" />
              ) : (
                <Typography variant="label-lg">
                  {formatPrice(invoice.amount)}
                </Typography>
              )}
            </ListItem>
            <IfElse if={!!invoice.paidAmount}>
              <ListItem label="Payment confirmed">
                {loading ? (
                  <Skeleton className="h-4 w-[200px]" />
                ) : (
                  <Typography variant="label-lg">
                    -{formatPrice(invoice.paidAmount)}
                  </Typography>
                )}
              </ListItem>
            </IfElse>
          </View>
          <Divider className="py-2" />
          <View className="flex flex-row items-end justify-between gap-2">
            <Typography variant="label-xl">Total Amount</Typography>
            {loading ? (
              <Skeleton className="h-4 w-[200px]" />
            ) : (
              <View className="flex flex-row items-end gap-1">
                <Typography variant="bold-xl" color="brand-primary">
                  {formatPrice(invoice.balance)}
                </Typography>
                <Typography
                  as="span"
                  variant="body-sm"
                  color="foreground-intermediate"
                  className="mb-1"
                >
                  incl. GST
                </Typography>
              </View>
            )}
          </View>
        </CardContent>
      </Card>
      <ApplyVoucherModal
        invoice={invoice}
        open={showApplyVoucherModal}
        onClose={onCloseVoucherModal}
      />
    </View>
  );
}

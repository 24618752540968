import { useWindowDimensions } from "@/components/hooks/use-window-dimension";
import { BottomDrawerModal } from "@/components/shared/bottom-drawer-modal";
import { Typography } from "@/components/shared/typography";
import { Button, View } from "@/components/ui";
import { IconButton } from "@/components/ui/icon-button";
import type { Icon } from "@/components/shared/icons";
import { Lightning, Sparkle, UserSwitch } from "@/components/shared/icons";
import { onChatSales } from "@/lib/chat-sales";
import { Divider } from "@/components/shared/divider";

export function SuitableWorkerModal({
  open,
  onClose,
}: {
  open: boolean;
  onClose(): void;
}) {
  const { isDesktop } = useWindowDimensions();

  return (
    <BottomDrawerModal open={open} onOpenChange={onClose}>
      <View className="flex flex-row items-start justify-between gap-2 py-1">
        <View>
          <Typography>Hey there! 😊</Typography>
          <Typography variant="body-xl" className="mt-1">
            Looks like you've found a cleaner you're into! 👍
          </Typography>
        </View>
        {isDesktop && (
          <IconButton
            variant="ghost"
            color="foreground"
            size="md"
            iconName="x"
            onClick={onClose}
          />
        )}
      </View>

      <View className="my-2 flex-1">
        <ListItem
          icon={Lightning}
          description="Our top-notch cleaners often get booked for recurring sessions super fast."
        />
        <ListItem
          icon={Sparkle}
          description="Don't miss out – contact us for a recurring package and ensure your favorite cleaner is there for you every time!"
        />
        <ListItem
          icon={UserSwitch}
          description="Plus, you can switch your cleaner anytime within your package, guaranteeing you always have the perfect match!"
        />
        <ListItem description="Let's get you set up!" />
        <Divider />
      </View>

      <View className="flex flex-1 flex-row gap-2">
        <Button
          variant="secondary"
          color="CTA2"
          fullWidth="full"
          onClick={onClose}
          children="Continue Searching"
        />

        <Button
          variant="primary"
          color="CTA"
          fullWidth="full"
          onClick={() => onChatSales()}
          children="Book via Sales"
          iconName="whatsappLogo"
          iconColor="fill-white"
        />
      </View>
    </BottomDrawerModal>
  );
}

function ListItem({
  description,
  icon: IconComponent,
}: {
  description: string;
  icon?: Icon;
}) {
  return (
    <View className="flex flex-1 flex-row items-start gap-3 py-3">
      {!!IconComponent && (
        <IconComponent
          size={32}
          weight="fill"
          className="mt-2 color-brand-primary"
        />
      )}
      <Typography
        color="foreground-intermediate"
        className="flex-1"
        variant={{ sm: "body-lg", md: "body-xl" }}
      >
        {description}
      </Typography>
    </View>
  );
}

import React from "react";
import type { Spinner as PhosphorSpinner } from "@phosphor-icons/react";
import { cn, safeGet } from "@/lib/utils";
import type { LoadingColor } from "@/components/ui/button/styles";
import { buttonLoadingColorMap } from "@/components/ui/button/styles";

interface SpinnerProps extends React.ComponentPropsWithoutRef<"svg"> {
  className?: string;
  color?: LoadingColor;
}

export const Spinner = React.forwardRef<
  React.ElementRef<typeof PhosphorSpinner>,
  SpinnerProps
>(({ className, color = "white", ...props }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      className={cn("animate-spin", className)}
      {...props}
    >
      <path
        d="M26.8322 14.0003C26.8322 21.088 21.0865 26.8337 13.9989 26.8337C6.91121 26.8337 1.16553 21.088 1.16553 14.0003C1.16553 6.91267 6.91121 1.16699 13.9989 1.16699C21.0865 1.16699 26.8322 6.91267 26.8322 14.0003ZM4.37386 14.0003C4.37386 19.3161 8.68312 23.6253 13.9989 23.6253C19.3146 23.6253 23.6239 19.3161 23.6239 14.0003C23.6239 8.68459 19.3146 4.37533 13.9989 4.37533C8.68312 4.37533 4.37386 8.68459 4.37386 14.0003Z"
        fill="#202327"
        fillOpacity="0.2"
      />
      <path
        d="M13.9989 1.16699C15.6842 1.16699 17.353 1.49894 18.91 2.14387C20.467 2.78881 21.8817 3.7341 23.0734 4.92579C24.2651 6.11748 25.2104 7.53221 25.8553 9.08922C26.5003 10.6462 26.8322 12.315 26.8322 14.0003H23.6239C23.6239 12.7364 23.3749 11.4848 22.8912 10.317C22.4075 9.14924 21.6985 8.08819 20.8048 7.19442C19.911 6.30066 18.8499 5.59169 17.6822 5.10799C16.5144 4.62428 15.2628 4.37533 13.9989 4.37533V1.16699Z"
        fill={safeGet(buttonLoadingColorMap, color, "white") as string}
      />
    </svg>
  );
});

Spinner.displayName = "Spinner";

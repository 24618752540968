import AddCreditCard from "@/app/profile/add-credit-card";
import { Dialog, DialogContent } from "@/components/ui";

export const AddCreditCardScreenModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <AddCreditCard />
      </DialogContent>
    </Dialog>
  );
};

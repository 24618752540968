import { View, Button } from "@/components/ui";
import { BottomDrawerModal } from "@/components/shared/bottom-drawer-modal";
import { IconButton } from "@/components/ui/icon-button";
import { Typography } from "../typography";
import { copyClipboard } from "@/lib/helpers/copy-clipboard";
import { useRoute } from "../router";
import type { PackageDepartmentEnum } from "@/__generated__/graphql";
import { getServiceNameFromDepartment } from "@/lib/booking-lib";

export function RewardModal({
  code,
  validUntil,
  open,
  handleClose,
  description,
  defaultServiceUsage,
}: {
  code: string;
  validUntil: string;
  open: boolean;
  handleClose: () => void;
  description: string;
  defaultServiceUsage: PackageDepartmentEnum;
}) {
  const { push } = useRoute();
  const rewardDescription = (description: string): string[] => {
    const regex = /<[^>]*>([^<]+)<\/[^>]*>/g;
    const extractedText = Array.from(
      description.matchAll(regex),
      (match) => match[1],
    );
    return extractedText;
  };
  const extractedDescription = rewardDescription(description);
  const serviceName = getServiceNameFromDepartment(defaultServiceUsage);

  const handleCopy = () => {
    handleClose();
    copyClipboard(code);
  };

  const handleBookNow = () => {
    handleClose();
    push({ pageKey: "serviceDetail", params: { name: serviceName } });
  };

  return (
    <BottomDrawerModal open={open}>
      <View>
        <Typography variant="label-2xl">Promo Details</Typography>
        <Typography variant="body-xl">{extractedDescription[0]}</Typography>

        <View className="gap-2 py-4">
          <View className="flex flex-row items-center justify-between border border-dashed border-greyBlue-300 px-4 py-3">
            <Typography variant="body-xl">{code}</Typography>
            <IconButton iconName="copy" onClick={handleCopy} />
          </View>
          <Typography variant="body-lg" color="secondary-text">
            Valid until: {validUntil}
          </Typography>
        </View>
        <Typography variant="body-lg" color="secondary-text">
          Terms & Conditions:
        </Typography>

        {extractedDescription.slice(1).map((item, index) => (
          <View key={index} className="flex flex-row gap-1">
            <Typography variant="body-lg" color="secondary-text">
              {index + 1}.
            </Typography>
            <Typography variant="body-lg" color="secondary-text">
              {item}
            </Typography>
          </View>
        ))}
      </View>
      <View className="flex flex-col gap-2 py-4">
        <Button
          variant="primary"
          color="CTA"
          onClick={handleBookNow}
          children="Book Now"
        />
        <Button
          variant="secondary"
          color="CTA2"
          onClick={handleClose}
          children="Close"
        />
      </View>
    </BottomDrawerModal>
  );
}

import { app } from "@/config/env";
import { AD_TRACKING_COOKIE_KEY, GA_ID, LAST_GA_ID } from "@/constants";
import { getCookie, setCookie } from "@/lib/cookie";
import { getIdentifiersFromStorage } from "@/lib/identifiers";
import { rudderanalytics } from "@/lib/rudderstack";

const fetchTrackingUrl = async (url: URL): Promise<string> => {
  const response = await fetch(
    `${app.PUBLIC_API_ENDPOINT.replace("/graphql", "")}/api/v1/service/url_shortener/generate`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ url, category: "Tracking" }),
    },
  );

  if (!response.ok) {
    throw new Error("Response failed.");
  }

  const data = (await response.json()) as { shortened_url: string };
  return data.shortened_url;
};

export const getTrackingKey = async () => {
  const LUCE_WEBSITE_URL = window.location.origin;
  const gaId = getCookie(GA_ID) || "";
  const rudderstackId = rudderanalytics.getAnonymousId();
  const { gclid, fbclid, msclkid, ttclid, li_fat_id } =
    await getIdentifiersFromStorage();

  try {
    const url = new URL(LUCE_WEBSITE_URL);
    url.searchParams.set("gaid", gaId);
    url.searchParams.set("rudderstackid", rudderstackId);
    url.searchParams.set("gclid", gclid);
    url.searchParams.set("fbclid", fbclid);
    url.searchParams.set("msclkid", msclkid);
    url.searchParams.set("ttclid", ttclid);
    url.searchParams.set("li_fat_id", li_fat_id);
    const trackingUrl = await fetchTrackingUrl(url);
    const key = new URL(trackingUrl).searchParams.get("origin") || "";
    setCookie(AD_TRACKING_COOKIE_KEY, key);
    setCookie(LAST_GA_ID, gaId);
    return key;
  } catch (error) {
    console.error("Failed to shorten the url:", error);
    return "";
  }
};

export const embedTrackingUrl = (message: string) => {
  const trackingKey = getCookie(AD_TRACKING_COOKIE_KEY);
  const LUCE_WEBSITE_URL = window.location.origin;

  if (trackingKey && LUCE_WEBSITE_URL) {
    message += ` From your page ${LUCE_WEBSITE_URL}?origin=${trackingKey}`;
  }

  return message;
};

import { ListHeading } from "@/components/shared/list-header";
import { Button, Card, CardContent, View } from "@/components/ui";
import { SquaresFour } from "@/components/shared/icons";
import { ServiceIcon } from "@/components/shared/service-icon";
import { useIntl } from "react-intl";
import { popularServiceItems } from "@/containers/homepage/lib";
import { useState } from "react";
import { AllServicesModal } from "../homepage/all-services";

export function MakeABooking() {
  const intl = useIntl();
  const [modalOpen, handleOpenChange] = useState(false);
  const serviceItems = popularServiceItems(intl);

  const viewAll = () => {
    handleOpenChange(true);
  };

  return (
    <Card shadow="sm">
      <CardContent className="flex flex-col gap-4 p-4">
        <ListHeading
          icon={SquaresFour}
          title={intl.formatMessage({
            defaultMessage: "Make a Booking",
            id: "make-a-booking",
          })}
          action={
            <View className="block md:hidden">
              <Button
                variant="tertiary"
                color="CTA2"
                onClick={viewAll}
                children={intl.formatMessage({
                  defaultMessage: "View All",
                  id: "view-all",
                })}
              />
            </View>
          }
        />

        <View className="native:flex native:flex-row grid grid-flow-col grid-cols-4 gap-4">
          {serviceItems.map((service) => (
            <ServiceIcon key={service.name} {...service} />
          ))}
        </View>

        <AllServicesModal
          modalOpen={modalOpen}
          handleOpenChange={handleOpenChange}
        />
      </CardContent>
    </Card>
  );
}

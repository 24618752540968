import posthog, { type PostHog } from "posthog-js";
import { isLocal } from "@/lib/utils";
import { app } from "@/config/env";
import type { FeatureFlag } from "./types";

export let posthogInstance: PostHog | undefined;

const POSTHOG_DISTINCT_ID_KEY = "cross_posthog_distinct_id";
const POSTHOG_SESSION_ID_KEY = "cross_posthog_session_id";
const POSTHOG_API_HOST = "https://app.posthog.com";

const validateQueryValue = (value: string | null) => {
  if (!value || value === "undefined") {
    return undefined;
  }

  return value;
};

export const PosthogAnalytics = {
  init: () => {
    if (isLocal) {
      console.log("[PostHog] Development mode – analytics disabled");
      return;
    }

    const queryParams = new URLSearchParams(window.location.search);
    const distinct_id = validateQueryValue(queryParams.get("distinct_id"));
    const session_id = validateQueryValue(queryParams.get("session_id"));

    localStorage.setItem(POSTHOG_DISTINCT_ID_KEY, distinct_id ?? "");
    localStorage.setItem(POSTHOG_SESSION_ID_KEY, session_id ?? "");

    posthogInstance = posthog.init(app.POSTHOG_API_KEY, {
      api_host: POSTHOG_API_HOST,
      autocapture: true,
      bootstrap: {
        sessionID: session_id,
        distinctID: distinct_id,
        featureFlags: {
          home_cleaning_navigation: "luce",
        },
      },
    });
  },
  track: (event: string, properties?: Record<string, unknown>) => {
    if (isLocal) return;
    posthogInstance?.capture(event, properties);
  },
  identify: (userId: string, properties?: Record<string, unknown>) => {
    if (isLocal) return;
    posthogInstance?.identify(userId, properties);
    if (userId) {
      localStorage.setItem(POSTHOG_DISTINCT_ID_KEY, userId);
      posthogInstance?.reloadFeatureFlags();
    }
  },
  reset: () => {
    if (isLocal) return;
    posthogInstance?.reset();
    localStorage.removeItem(POSTHOG_DISTINCT_ID_KEY);
    localStorage.removeItem(POSTHOG_SESSION_ID_KEY);
  },
  isFeatureEnabled: (flag: FeatureFlag) => {
    if (isLocal) return false;
    return posthogInstance?.isFeatureEnabled?.(flag) ?? false;
  },
  getFeatureFlag: (flag: FeatureFlag) => {
    if (isLocal) return null;
    return posthogInstance?.getFeatureFlag?.(flag);
  },
  setUserProperties: (id: string, email: string, name: string) => {
    if (isLocal) return;
    posthogInstance?.identify(id, { email, name });
  },
  getIdentifiers: () => {
    const session_id = posthogInstance?.get_session_id();
    const distinct_id = posthogInstance?.get_distinct_id();

    return { distinct_id, session_id };
  },
};

import type { UseFormReturn } from "react-hook-form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

const feedbackSchema = z.object({
  feedback: z.string().optional(),
});

export type FeedbackFormData = z.infer<typeof feedbackSchema>;

export interface FeedbackFormReturn extends UseFormReturn<FeedbackFormData> {
  defaultValues: FeedbackFormData;
}

export const initialFormValues: FeedbackFormData = {
  feedback:
    "e.g. The new design is easier to navigate, especially in the booking flow., etc.",
};

export const useFeedbackForm = (
  initialValues?: FeedbackFormData,
): FeedbackFormReturn => ({
  defaultValues: initialValues || initialFormValues,
  ...useForm<FeedbackFormData>({
    mode: "onChange",
    defaultValues: initialValues,
    resolver: zodResolver(feedbackSchema),
  }),
});

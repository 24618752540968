import { View, Card, CardContent, Image } from "@/components/ui";
import { Divider } from "@/components/shared/divider";
import { NumChangeWidget } from "@/components/shared/num-change-widget";
import { IfElse } from "@/components/shared/if-else";
import { Typography } from "../../typography";

export const ServiceAddOnCard = ({
  value,
  title,
  subTitle,
  thirdTitle,
  description,
  imgUrl,
  onAdd,
  onMinus,
  slogan,
  footer,
  max,
}: {
  imgUrl: string;
  value: number;
  title: string;
  max?: number;
  subTitle?: string;
  thirdTitle?: string;
  description: string;
  onAdd(): void;
  onMinus(): void;
  slogan?: string;
  footer?: React.ReactElement;
}) => {
  return (
    <Card>
      <IfElse if={!!slogan}>
        <View className="absolute -top-2 left-[-1px] rounded-br-xl rounded-tl-xl bg-success px-4 shadow-sm">
          <Typography variant="label-sm" color="white" numberOfLines={1}>
            {slogan}
          </Typography>
        </View>
      </IfElse>

      <CardContent>
        <View className="flex h-20 flex-1 flex-row gap-4 p-2">
          <View className="overflow-hidden rounded-md">
            <Image src={imgUrl} width={80} />
          </View>
          <View className="flex flex-1 flex-col gap-1">
            <Typography variant="body-md">{title}</Typography>
            <Typography variant="label-md">{subTitle}</Typography>
            <Typography
              variant={{ md: "body-md", sm: "body-sm" }}
              color="foreground-intermediate"
            >
              {thirdTitle}
            </Typography>
          </View>
          <NumChangeWidget
            value={value}
            onAdd={onAdd}
            onMinus={onMinus}
            addDisabled={!!max && max === value}
          />
        </View>
        <Divider dashed className="my-2" />
        <Typography variant="body-sm" color="foreground-intermediate">
          {description}
        </Typography>
        <IfElse if={!!footer}>
          <>
            <Divider dashed className="my-2" />
            {footer}
          </>
        </IfElse>
      </CardContent>
    </Card>
  );
};

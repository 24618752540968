import { View, RadioGroup } from "@/components/ui";
import { Cube } from "@/components/shared/icons";
import { ListHeading } from "@/components/shared/list-header";
import type {
  FrequencyOption,
  ServiceRadioOptions,
} from "@/components/shared/service-radio-item/types";
import { Divider } from "@/components/shared/divider";
import { ServiceRadioItem } from "@/components/shared/service-radio-item";
import { Typography } from "@/components/shared/typography";
import { useIntl } from "react-intl";

export function SelectServices({
  selectedFrequencyOption,
  cleaningFrequencyOptions,
  onChooseCleaningFrequency,
}: {
  selectedFrequencyOption: FrequencyOption;
  cleaningFrequencyOptions: ServiceRadioOptions[];
  onChooseCleaningFrequency(item: FrequencyOption): void;
}) {
  const intl = useIntl();
  return (
    <View className="items-start p-4">
      <View className="native:pt-0 flex w-full flex-col gap-2">
        <View className="flex flex-col">
          <ListHeading
            title={intl.formatMessage({
              defaultMessage: "Choose Cleaning Frequency",
              id: "selectService.title",
            })}
            icon={Cube}
          />
          <Typography variant="body-lg" color="foreground-intermediate">
            {intl.formatMessage({
              defaultMessage:
                "Select your preferred cleaning frequency, you can always change this again after the booking.",
              id: "selectService.description",
            })}
          </Typography>
          <View className="mt-6 flex flex-col gap-4">
            {cleaningFrequencyOptions.map((groupItem) => (
              <View key={groupItem.id} className="flex flex-col gap-4">
                {groupItem.divider && (
                  <View className="mb-1 mt-3">
                    <Divider className="opacity-25" />
                  </View>
                )}
                {groupItem.title && (
                  <Typography variant={{ md: "label-xl", sm: "label-lg" }}>
                    {groupItem.title}
                  </Typography>
                )}
                <RadioGroup
                  value={selectedFrequencyOption.title}
                  onValueChange={(value) => {
                    onChooseCleaningFrequency(
                      groupItem.options.find(
                        (item) => item.title === value,
                      ) as FrequencyOption,
                    );
                  }}
                >
                  <View className="flex flex-col gap-4 overflow-visible">
                    {groupItem.options.map((item) => {
                      return (
                        <ServiceRadioItem
                          upsellText={item?.upsell || ""}
                          showSelectIcon={item.unselectable}
                          key={item.title}
                          title={item.title}
                          subTitle={item.subTitle || ""}
                          description={item.description}
                          selected={
                            selectedFrequencyOption.title === item.title
                          }
                          slogan={item.slogan}
                          value={item.title}
                          showSparkle
                          onSelect={() => onChooseCleaningFrequency(item)}
                        />
                      );
                    })}
                  </View>
                </RadioGroup>
              </View>
            ))}
          </View>
        </View>
      </View>
    </View>
  );
}

import type {
  ClientConfirmReservationMutation,
  PackageDepartmentEnum,
  PackageDetailFragmentFragment,
  ServiceTypeEnum,
  SignInProviderEnum,
} from "@/__generated__/graphql";
import { logAnalyticEvent, setAnalyticUserId } from "../../firebase";
import { isLocal } from "@/lib/utils";
import { trackHotjarEvent } from "../hotjar";
import { PosthogAnalytics } from "../posthog";
import { getIdentifiersFromStorage } from "@/lib/identifiers";
import { rudderanalytics } from "@/lib/rudderstack";
import type { BetaServiceName, ServiceName } from "@/types/service";
export type AnalyticsConversion =
  | EventFullPageView
  | EventScroll
  | EventPurchase
  | EventAddToCart
  | EventFindAvailability
  | EventUserSignUp
  | EventUserSignIn
  | EventViewService
  | EventViewBetaService
  | EventSelectServiceType
  | EventWAClick
  | EventAppDownloadButton
  | EventRedirectToOldApp
  | EventRebookClick
  | EventRebookComplete;

type Item = {
  item_id: string;
  item_category: string;
  price: number;
  quantity: number;
};

type EventRebookClick = {
  name: "rebook_click";
  values?: Record<string, string>;
};

type EventRebookComplete = {
  name: "rebook_complete";
  values: {
    currency: "SGD";
    value: number;
    transaction_id: string;
    coupon?: string;
    tax?: number;
    items: Item[];
  };
};

type EventFullPageView = {
  name: "full_page_view";
  values?: Record<string, string>;
};

type EventWAClick = {
  name: "wa_sales_button_click";
  values?: Record<string, string>;
};

type EventScroll = {
  name: "scroll";
  values: {
    threshold: number;
  };
};

type EventPurchase = {
  name: "purchase";
  values: {
    currency: "SGD";
    value: number;
    transaction_id: string;
    coupon?: string;
    tax?: number; // we can put GST for this
    items: Item[];
  };
};

type EventAddToCart = {
  name: "add_to_cart";
  values: {
    currency: "SGD";
    value: number;
    items: Item[];
  };
};

type EventFindAvailability = {
  name: "find_availability";
  values: {
    currency: "SGD";
    value: number;
    items: Item[];
  };
};

type EventUserSignUp = {
  name: "user_sign_up";
  values: {
    method: SignInProviderEnum;
  };
};

type EventUserSignIn = {
  name: "user_sign_in";
  values: {
    method: SignInProviderEnum;
  };
};

type EventViewService = {
  name: "view_service";
  values: {
    // service_type: PackageDepartmentEnum;
    service_type: ServiceName;
  };
};
type EventViewBetaService = {
  name: "view_beta_service";
  values: {
    // service_type: PackageDepartmentEnum;
    service_type: BetaServiceName;
  };
};
type EventSelectServiceType = {
  name: "select_service_type";
  values: {
    service_type: ServiceTypeEnum | PackageDepartmentEnum;
  };
};

type EventAppDownloadButton = {
  name: "app_download_button_click";
  values?: Record<string, string>;
};

type EventRedirectToOldApp = {
  name: "redirect_to_old_app";
  values?: Record<string, string>;
};

export function sendAnalyticData(eventData: AnalyticsConversion): void {
  if (isLocal) {
    return;
  }
  trackHotjarEvent(eventData.name);
  PosthogAnalytics.track(eventData.name, eventData.values);
  logAnalyticEvent(eventData.name, {
    ...eventData.values,
    timestamp: Date.now(),
  });
  getIdentifiersFromStorage().then((identifiers) => {
    rudderanalytics.track(eventData.name, {
      ...eventData.values,
      ...identifiers,
    });
  });
}

export function setAnalyticUser({
  userId,
  email,
  firstName,
}: {
  userId: string;
  email: string;
  firstName: string;
}) {
  if (isLocal) return;
  setAnalyticUserId(userId);
  PosthogAnalytics.setUserProperties(userId, email, firstName);
  rudderanalytics.identify(userId, {
    email,
    name: firstName,
  });
}

export function resetAnalyticUser() {
  if (isLocal) return;
  setAnalyticUserId(null);
  PosthogAnalytics.reset();
  rudderanalytics.reset();
}

export function transformBookingDataToAddToCart(
  packageDetails: PackageDetailFragmentFragment[],
): EventAddToCart["values"] {
  const totalServiceBillingValue = packageDetails.reduce<number>(
    (total, { serviceBillingValue }) => total + serviceBillingValue,
    0,
  );

  return {
    currency: "SGD",
    value: totalServiceBillingValue * (packageDetails[0].repeatEveryTimes || 1),
    items: packageDetails.map((packageDetail) => ({
      item_id: packageDetail.id,
      item_category: packageDetail.department,
      price: packageDetail.unitValue,
      quantity: packageDetail.units * (packageDetail.repeatEveryTimes || 1),
    })),
  };
}

export function transformReservationDataToPurchase(
  multiplier: number,
  reservation: NonNullable<
    ClientConfirmReservationMutation["clientConfirmReservation"]
  >,
): EventPurchase["values"] {
  // items price * quantity should equal to value;
  return {
    transaction_id: reservation.package.id,
    value: reservation.package.serviceBillingValue * multiplier,
    currency: "SGD",
    items: Array(multiplier).fill({
      item_id: reservation.package.packageDetail.id,
      item_category: reservation.package.packageDetail.serviceType,
      price: reservation.package.serviceBillingValue,
      quantity: 1,
    }) as Item[],
  };
}

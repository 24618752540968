import { Banner } from "@/components/shared/banner";
import { Button } from "@/components/ui";
import { useIntl } from "react-intl";
import { useRoute } from "@/components/shared/router";
import { useAuth } from "@/components/shared/auth-provider";
import { useSharerModalStore } from "@/store/sharer";

export function ShareReferralBanner() {
  const intl = useIntl();
  const { setOpen } = useSharerModalStore();

  const { isAuthenticated } = useAuth();
  const { push } = useRoute();

  const handleShare = () => {
    if (!isAuthenticated) {
      return push({
        pageKey: "login",
      });
    }

    setOpen(true);
  };
  return (
    <Banner
      type="referral"
      title={intl.formatMessage(
        {
          defaultMessage: "Give ${value}, Get ${value}",
          description: "promotion banner title",
          id: "homepage.promotionBannerTitle",
        },
        { value: 15 },
      )}
      description={intl.formatMessage(
        {
          defaultMessage:
            "Every friend you refer gets $15 off their first booking and you get $15",
          description: "promotion banner description",
          id: "homepage.promotionBannerDescription",
        },
        { value: 15 },
      )}
      action={
        <Button
          variant="primary"
          color="CTA"
          rounded="full"
          iconName="shareFat"
          iconSize={{ sm: "sm", md: "md" }}
          size={{ sm: "sm", md: "md" }}
          children={intl.formatMessage(
            {
              defaultMessage: "Share",
              id: "share",
            },
            { value: 15 },
          )}
          onClick={handleShare}
        />
      }
    />
  );
}

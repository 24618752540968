import { CS_NUMBER } from "@/constants";
import { handleWhatsappNumber } from "../platform";
import { sendAnalyticData } from "../monitoring/analytics";
import { embedTrackingUrl } from "../monitoring/oci";

export const onChatSales = (
  includeTrackingUrl = false,
  message = "Hi! I would like to speak to sales about booking a new appointment through your web application.",
) => {
  if (includeTrackingUrl) {
    message = embedTrackingUrl(message);
  }

  handleWhatsappNumber(CS_NUMBER, message);
  sendAnalyticData({
    name: "wa_sales_button_click",
  });
};

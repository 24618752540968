import { View, Button, Image } from "@/components/ui";
import { RewardCard } from "@/components/shared/reward-card";
import { RewardModal } from "@/components/shared/reward-modal";
import { useState, useEffect } from "react";
import { useAuthState } from "@/store/auth";
import { useAllRewardsByFiltersQuery } from "@/store/rewards/useAllRewardsByFiltersQuery";
import { IncentiveTypesEnum, RewardStatusEnum } from "@/__generated__/graphql";
import { Typography } from "@/components/shared/typography";
import type { RewardItem } from "@/types/reward";
import { Spin } from "@/components/shared/spin";
import { IfElse } from "@/components/shared/if-else";
import EmptyPromosAndRewards from "@/assets/images/empty-promos-and-rewards.png";
import { formatDate, today } from "@/lib/helpers/date";

export const YourRewards = () => {
  const {
    data: { userInfo },
  } = useAuthState();

  const { fetch: getAllRewards, data, loading } = useAllRewardsByFiltersQuery();
  const [selectedReward, setSelectedReward] = useState<RewardItem | null>(null);
  const [rewardLimit, setRewardLimit] = useState<number>(5);

  useEffect(() => {
    if (userInfo?.user?.id) {
      getAllRewards({
        requestPayload: {
          filters: {
            userId: userInfo.user.id,
            status: [
              RewardStatusEnum.Available,
              RewardStatusEnum.Earned,
              RewardStatusEnum.Used,
              RewardStatusEnum.Cancelled,
            ],
            incentiveType: IncentiveTypesEnum.PromoCode,
            limit: rewardLimit,
            validTo: formatDate(today()),
          },
        },
      });
    }
  }, [userInfo.user.id, rewardLimit]);

  const handleShowMore = () => {
    setRewardLimit(rewardLimit + 5);
  };

  const handleModalClose = () => {
    setSelectedReward(null);
  };

  const hasShowMoreButton = data?.length;

  return (
    <View className="flex flex-col gap-4 p-4 md:px-0">
      {loading ? (
        <Spin className="my-6 size-8" />
      ) : (
        <IfElse
          if={!!data?.length}
          else={
            <View className="flex flex-col items-center">
              <Image src={EmptyPromosAndRewards} width={160} height={160} />
              <Typography variant="label-xl">No Rewards Yet!</Typography>
              <Typography variant="body-lg">
                Start booking services to earn points and unlock exciting
                rewards
              </Typography>
            </View>
          }
        >
          {data?.map((item) => (
            <RewardCard
              validUntil={item.validUntil}
              discountValues={item.discountValues}
              rewardName={item.rewardName}
              discountType={item.discountType}
              handleDetail={() => setSelectedReward(item)}
            />
          ))}
        </IfElse>
      )}

      {selectedReward && (
        <RewardModal
          description={selectedReward.description}
          code={selectedReward.code ?? ""}
          validUntil={selectedReward.validUntil}
          open={!!selectedReward}
          handleClose={handleModalClose}
          defaultServiceUsage={selectedReward.defaultServiceUsage}
        />
      )}
      {!!hasShowMoreButton && (
        <View className="flex flex-row items-center justify-center px-2 py-6">
          <Button
            variant="tertiary"
            disabled={data.length < rewardLimit}
            color="CTA2"
            iconAlignment="end"
            children="Show More"
            className="gap-2"
            onClick={handleShowMore}
          />
        </View>
      )}
    </View>
  );
};

import { View, Input } from "@/components/ui";
import { InputFormControl } from "@/components/shared/input-form-control";
import type { Control } from "react-hook-form";
import type { TokenizePaymentMethodFormData } from "@/store/profile/forms/useTokenizePaymentMethodForm";
import { PasswordInput } from "@/components/shared/password-input";
import { MaskedTextInput } from "@/components/shared/masked-text-input";

export const AddCreditCardForm = ({
  control,
  loading,
}: {
  control: Control<TokenizePaymentMethodFormData>;
  loading: boolean;
}) => {
  return (
    <View className="flex w-full flex-1 flex-col">
      <InputFormControl
        name="name"
        label="Cardholder name"
        control={control}
        component={Input}
        disabled={loading}
        componentProps={{
          placeholder: "John Doe",
        }}
      />
      <MaskedTextInput
        mask="creditCard"
        label="Credit card number"
        name="number"
        control={control}
        disabled={loading}
        placeholder="**** **** **** ****"
      />
      <View className="flex flex-col md:flex-row md:gap-3">
        <MaskedTextInput
          mask="expDate"
          label="Exp. Date"
          name="expDate"
          control={control}
          placeholder="MM/YYYY"
          disabled={loading}
        />
        <InputFormControl
          name="cvc"
          label="CVC code"
          className="flex-1"
          control={control}
          component={PasswordInput}
          disabled={loading}
          componentProps={{
            placeholder: "3 or 4 digit",
            maxLength: 4,
          }}
        />
      </View>
    </View>
  );
};

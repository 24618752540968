import { useState } from "react";
import { InfoDialog } from "@/components/shared/info-dialog";
import { PackageIssueScreen } from "./PackageIssueScreen";
import { PackageIssueType } from "@/store/report-issue/package-issue/packageIssue";
import { usePackageIssueStore } from "@/store/report-issue/package-issue/packageIssue";
import { useCreatePackageIssueRequestStore } from "@/store/report-issue/package-issue/useCreatePackageIssueRequest";
import { useReportIssueForm } from "@/store/report-issue/forms/useReportIssueForm";
import { useRoute } from "@/components/shared/router";
import { getPlatform } from "@/lib/utils";

const getContentIssueType = (type: PackageIssueType | null) => {
  if (type === PackageIssueType.modifyPlan) {
    return {
      title: "Modify Plan",
      description:
        "Please provide us a reason to modify your package address, and fill the comment to describe the reason.",
    };
  } else if (type === PackageIssueType.editAddress) {
    return {
      title: "Edit Address",
      description:
        "Please provide us a reason to modify your package address, and fill the comment to describe the reason.",
    };
  }

  return {
    title: "Cancel package",
    description:
      "One more step to cancel your plan, could you let us the reason.",
  };
};

export function PackageIssueContainer() {
  const {
    data: { showModal, issueType, packageId },
    closePackageIssueModal,
  } = usePackageIssueStore();
  const { pull } = useRoute();
  const platform = getPlatform();
  const { fetch: reportPackage, loading } = useCreatePackageIssueRequestStore();

  const form = useReportIssueForm();

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const { title, description } = getContentIssueType(issueType);

  const onSubmit = form.handleSubmit(async (values) => {
    if (!packageId) return;

    try {
      const res = await reportPackage({
        requestPayload: {
          input: {
            comment: values.comment,
            issueId: values.reason,
            packageId,
          },
        },
      });

      if (!res.data?.createPackageIssueRequest?.result) {
        throw new Error("Unable to report package issue");
      }

      setShowSuccessModal(true);
    } catch (error) {
      console.error(error);
    }
  });

  const onClose = () => {
    if (platform === "native") {
      pull();
    }
    setShowSuccessModal(false);
    closePackageIssueModal();
    form.reset();
  };

  return (
    <>
      <PackageIssueScreen
        title={title}
        description={description}
        onClose={onClose}
        onSubmit={onSubmit}
        form={form}
        open={showModal}
        loading={loading}
      />
      <InfoDialog
        open={showSuccessModal}
        onClose={onClose}
        subtitle="Our support specialist will be getting in touch with you soon"
      />
    </>
  );
}

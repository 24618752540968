import { View, Input, Button } from "@/components/ui";
import { EmailIcon } from "@/components/shared/icons";
import { PasswordInput } from "@/components/shared/password-input";
import { ErrorMessage } from "../../error-message";
import { InputFormControl } from "@/components/shared/input-form-control";
import type { EmailFormReturn } from "@/store/auth/forms/useEmailLoginForm";
import { Typography } from "@/components/shared/typography";

export interface EmailFormData {
  email: string;
  password: string;
}

export const EmailForm = ({
  loading,
  errorMessage = "",
  formReturn,
  onSubmitForm,
  onForgotPassword,
}: {
  loading: boolean;
  errorMessage?: string;
  formReturn: EmailFormReturn;
  onSubmitForm: (data: EmailFormData) => void;
  onForgotPassword: () => void;
}) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitted },
  } = formReturn;

  const onSubmitFormData = handleSubmit(onSubmitForm);
  const onSubmit = () => {
    onSubmitFormData();
  };

  return (
    <View className="flex flex-col">
      <InputFormControl
        control={control}
        name="email"
        label="Email"
        component={Input}
        componentProps={{
          placeholder: "youremail@example.com",
          prefix: <EmailIcon className="size-5" />,
        }}
      />

      <InputFormControl
        control={control}
        name="password"
        label="Password"
        component={PasswordInput}
      />

      <Typography
        variant="body-md"
        className="cursor-pointer pt-1 text-right text-link"
        onClick={onForgotPassword}
      >
        Forgot Password?
      </Typography>

      {isSubmitted && (
        <ErrorMessage errorMessage={errorMessage || ""} className="mt-4" />
      )}

      <Button
        variant="primary"
        color="CTA"
        rounded="full"
        className="mt-4"
        onClick={onSubmit}
        loading={loading}
        children="Login"
      />
    </View>
  );
};

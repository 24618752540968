import React from "react";

type ImageProps = React.ImgHTMLAttributes<HTMLImageElement> & {
  contentFit?: never;
  maxHeight?: number;
  maxWidth?: number;
};

export const Image = React.forwardRef<HTMLImageElement, ImageProps>(
  ({ contentFit: _contentFit, maxHeight, maxWidth, ...props }, ref) => {
    return <img ref={ref} style={{ maxHeight, maxWidth }} {...props} />;
  },
);

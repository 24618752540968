import { View, Card, CardContent, Button } from "@/components/ui";
import { GoogleLogo } from "@/components/shared/icons";
import { Ratings } from "@/components/ui/ratings";
import { openBrowser } from "@/lib/open-browser";
import { Typography } from "@/components/shared/typography";

const review = {
  name: "Rachel W",
  date: "Feb 2021",
  rating: 5,
  description:
    "Anna did such great work in cleaning our home last week! I really like the way she was meticulous and patient ..I really think she deserves all the appreciation for her hard work",
};

export const GoogleReviews = () => {
  return (
    <View className="mx-4 flex flex-col gap-2 md:rounded-xl md:border md:border-secondary-border">
      <View className="flex flex-row items-center justify-between p-4 py-2 md:border-b md:py-4">
        <View className="flex flex-row items-center gap-2">
          <GoogleLogo
            className="rounded-full bg-primary text-white"
            size={20}
          />
          <Typography variant="label-lg">Google Reviews</Typography>
        </View>
        <Button
          variant="secondary"
          color="CTA2"
          rounded="full"
          size="sm"
          onClick={() =>
            openBrowser(
              "https://www.google.com/maps/place/Luce+Home+SG/@1.2761599,103.7962196,15z/data=!3m1!5s0x31da1bd4f24a0801:0xf825436b15d886d2!4m8!3m7!1s0x31da191347aa8fbf:0x42617a25b834f5eb!8m2!3d1.2761605!4d103.7962272!9m1!1b1!16s%2Fg%2F11b6hrt255?entry=ttu&g_ep=EgoyMDI0MTAxNC4wIKXMDSoASAFQAw%3D%3D",
            )
          }
          children="View all"
        />
      </View>
      <Card className="native:border-0 md:border-none">
        <CardContent>
          <View className="flex flex-row items-center gap-2 pb-4">
            <View className="flex size-8 items-center justify-center rounded-full bg-primary color-white">
              <Typography variant="label-lg" color="white">
                {review.name[0].toUpperCase()}
              </Typography>
            </View>
            <View>
              <Typography variant="label-md">{review.name}</Typography>
              <View className="flex flex-row items-center gap-2">
                <Ratings variant="yellow" rating={review.rating} />
                <Typography variant="body-md" color="foreground-intermediate">
                  {review.date}
                </Typography>
              </View>
            </View>
          </View>
          <Typography variant="body-md">{review.description}</Typography>
        </CardContent>
      </Card>
    </View>
  );
};

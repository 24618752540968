import { ListHeading } from "@/components/shared/list-header";
import {
  View,
  Button,
  Select,
  Input,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Textarea,
} from "@/components/ui";
import { Phone, MapPinPlus, Key, Notepad } from "@/components/shared/icons";
import { InputFormControl } from "@/components/shared/input-form-control";
import type { Control, UseFormSetValue } from "react-hook-form";
import type { BookingInfoFormData } from "../useBookingInfoForm";
import { AccessMode } from "@/types/booking";
import type { Contact } from "@/types/users";
import { Divider } from "@/components/shared/divider";

export const BookingInfoForm = ({
  control,
  setValue,
  contacts,
  onAddAnotherContact,
}: {
  setValue: UseFormSetValue<BookingInfoFormData>;
  control: Control<BookingInfoFormData>;
  contacts: Contact[];
  onAddAnotherContact: () => void;
}) => {
  return (
    <View className="flex flex-col gap-4">
      <View className="flex flex-col gap-2 px-4">
        <ListHeading title="Contact Info" icon={Phone} />
        <InputFormControl
          name="name"
          component={Select}
          label="Contact Name"
          control={control}
          changeFallBack={(val) => {
            const person = contacts.find(
              (contact) => `${contact.firstName} ${contact.lastName}` === val,
            );
            if (!person) return;
            setValue("phoneNumber", person.phoneNumber[0]);
            setValue("email", person.email[0]);
          }}
          componentProps={{
            children: (
              <>
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="John Doe" />
                </SelectTrigger>
                <SelectContent className="w-full">
                  <SelectGroup>
                    {contacts.map((value) => (
                      <SelectItem
                        value={`${value.firstName} ${value.lastName}`}
                        label={`${value.firstName} ${value.lastName}`}
                        key={`${value.firstName} ${value.lastName}`}
                      >
                        {`${value.firstName} ${value.lastName}`}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </>
            ),
          }}
        />
        <InputFormControl
          name="phoneNumber"
          component={Input}
          label="Phone Number"
          control={control}
          componentProps={{
            placeholder: "+6595623161",
            readOnly: true,
          }}
        />
        <InputFormControl
          name="email"
          component={Input}
          label="Email"
          control={control}
          componentProps={{
            placeholder: "Johndoe@gmail.com",
            readOnly: true,
          }}
        />

        <Button
          variant="tertiary"
          color="CTA2"
          iconColor={"CTA2"}
          size="md"
          iconName="plus"
          onClick={onAddAnotherContact}
          disabled={contacts.length >= 4}
          children="Add another contact"
        />
      </View>
      <Divider className="border-2 md:hidden" />
      <View className="flex flex-col gap-2 px-4">
        <ListHeading title="Address Info" icon={MapPinPlus} />
        <View className="flex flex-col gap-4">
          <InputFormControl
            name="postalCode"
            component={Input}
            className="flex-1"
            label="Postal Code"
            control={control}
            componentProps={{
              type: "number",
              readOnly: true,
            }}
          />
          <InputFormControl
            name="fullAddress"
            className="basis-3/5"
            component={Textarea}
            label="Full Address"
            control={control}
            componentProps={{
              readOnly: true,
            }}
          />
        </View>
      </View>
      <Divider className="border-2 md:hidden" />
      <View className="flex flex-col gap-2 px-4">
        <ListHeading title="Additional Info" icon={Key} />
        <View>
          <InputFormControl
            name="accessMode"
            component={Select}
            label="Access Mode"
            componentProps={{
              children: (
                <>
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Ring Doorbell" />
                  </SelectTrigger>
                  <SelectContent className="w-full">
                    <SelectGroup>
                      <SelectItem
                        value={AccessMode.KeypadPin}
                        label={AccessMode.KeypadPin}
                      >
                        {AccessMode.KeypadPin}
                      </SelectItem>
                      <SelectItem
                        value={AccessMode.RingDoorbell}
                        label={AccessMode.RingDoorbell}
                      >
                        {AccessMode.RingDoorbell}
                      </SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </>
              ),
            }}
            control={control}
          />
        </View>

        <InputFormControl
          name="accessInstructions"
          component={Textarea}
          label="Access Instructions"
          control={control}
          componentProps={{
            placeholder: "Enter Access Instructions",
          }}
        />
      </View>
      <Divider className="border-2 md:hidden" />
      <View className="flex flex-col gap-2 px-4">
        <ListHeading title="Note" icon={Notepad} />
        <InputFormControl
          name="note"
          component={Textarea}
          label="Additional Note"
          control={control}
          componentProps={{
            placeholder: "Enter Additional Notes.",
          }}
        />
      </View>
    </View>
  );
};

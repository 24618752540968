import {
  View,
  RadioGroup,
  RadioGroupItem,
  Image,
  Card,
  CardContent,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@/components/ui";
import PayNowImage from "@/assets/images/paynow.png";
import VisaImage from "@/assets/images/visa.png";
import MasterCardImage from "@/assets/images/master-card.png";
import AmericanExpressImage from "@/assets/images/american-express.png";
import { Bank } from "@/components/shared/icons";
import type { SelectPaymentScreenModalProps } from "..";
import { ApplyCredit } from "../apply-credit";
import type { PaymentType } from "@/store/invoices";
import { Typography } from "@/components/shared/typography";
import { Divider } from "@/components/shared/divider";
import { formatPrice, SINGAPORE_TAX } from "@/lib/helpers/number";
import { IfElse } from "@/components/shared/if-else";

export const SelectPaymentScreenModal = ({
  open,
  onShowModal,
  onPay,
  control,
  selectPayment,
  setSelectPayment,
  loading,
  baseAmount,
  amount,
  paidAmount,
  balance,
  appliedVoucher,
}: SelectPaymentScreenModalProps) => {
  return (
    <Dialog open={open} onOpenChange={() => onShowModal()}>
      <DialogContent className="bg-white">
        <DialogTitle>
          <Typography className="text-lg font-medium">
            Select Payment Method
          </Typography>
        </DialogTitle>

        <View className="flex flex-col gap-6">
          <RadioGroup
            value={selectPayment}
            onValueChange={(val) => {
              setSelectPayment(val as PaymentType);
            }}
          >
            <Card onClick={() => setSelectPayment("credit-card")}>
              <CardContent>
                <View className="flex flex-col gap-1">
                  <View className="flex flex-row justify-between">
                    <View className="flex flex-row gap-[10px]">
                      <Image src={VisaImage} width={46} height={32} />
                      <Image src={MasterCardImage} width={46} height={32} />
                      <Image
                        src={AmericanExpressImage}
                        width={53}
                        height={32}
                      />
                    </View>
                    <RadioGroupItem value="credit-card" checkCircle />
                  </View>

                  <Typography variant="body-xl" color="foreground-default">
                    Credit / Debit Card
                  </Typography>

                  {selectPayment === "credit-card" && (
                    <View className="flex flex-col gap-2">
                      <Typography
                        variant="body-md"
                        color="foreground-intermediate"
                      >
                        Pay with your card on file. A processing fee of 3.4% +
                        50 cents will be charged by the card processor.
                        Alternative, pay with PayNow with no additional fees.
                      </Typography>
                      <Typography
                        variant="body-md"
                        color="foreground-intermediate"
                      >
                        Payment status will be updated within 7 days due to bank
                        reconciliation process.
                      </Typography>
                    </View>
                  )}
                </View>
              </CardContent>
            </Card>
            <Card onClick={() => setSelectPayment("paynow")}>
              <CardContent>
                <View className="flex flex-col gap-1">
                  <View className="flex flex-row justify-between">
                    <View className="flex flex-row items-center gap-[10px]">
                      <Image src={PayNowImage} width={52} height={32} />
                      <Bank weight="fill" className="text-primary" size={32} />
                    </View>
                    <RadioGroupItem value="paynow" checkCircle />
                  </View>

                  <Typography variant="body-xl" color="foreground-default">
                    PayNow / Bank Transfer
                  </Typography>

                  {selectPayment === "paynow" && (
                    <View className="flex flex-col gap-2">
                      <Typography
                        variant="body-md"
                        color="foreground-intermediate"
                      >
                        You will be redirected to new page to complete the
                        payment via Paynow.
                      </Typography>
                      <Typography
                        variant="body-md"
                        color="foreground-intermediate"
                      >
                        Payment status will be updated within 7 days due to bank
                        reconciliation process.
                      </Typography>
                    </View>
                  )}
                </View>
              </CardContent>
            </Card>
            <ApplyCredit
              selectPayment={selectPayment === "apply-credit"}
              setSelectPayment={setSelectPayment}
              control={control}
            />
          </RadioGroup>
          <View className="flex flex-col gap-2">
            <Typography variant="body-xl">Payment Details</Typography>
            <Card>
              <CardContent>
                <View className="flex w-full flex-row justify-between">
                  <Typography variant="body-lg" color="foreground-intermediate">
                    Subtotal
                  </Typography>
                  <View className="flex flex-col items-end">
                    <Typography variant="label-lg">{baseAmount}</Typography>
                    <IfElse if={appliedVoucher}>
                      <Typography variant="label-xs" color="brand-primary">
                        The total of {baseAmount} includes your voucher
                        discount*
                      </Typography>
                    </IfElse>
                  </View>
                </View>
                <View className="flex w-full flex-row justify-between">
                  <Typography variant="body-lg" color="foreground-intermediate">
                    {`GST (${SINGAPORE_TAX * 100}%)`}
                  </Typography>
                  <Typography variant="body-xl" color="foreground-default">
                    {amount}
                  </Typography>
                </View>
                <IfElse if={!!paidAmount}>
                  <View className="flex w-full flex-row justify-between">
                    <Typography
                      variant="body-lg"
                      color="foreground-intermediate"
                    >
                      Payment Confirmed
                    </Typography>
                    <Typography variant="body-xl" color="foreground-default">
                      -{formatPrice(paidAmount)}
                    </Typography>
                  </View>
                </IfElse>
                <Divider className="mx-1 mt-2" />
                <View className="flex w-full flex-row justify-between p-1">
                  <Typography variant="body-lg" color="foreground-default">
                    Total amount
                  </Typography>
                  <View className="flex flex-row items-end gap-1">
                    <Typography variant="body-xl" color="brand-primary">
                      {balance}
                    </Typography>
                    <Typography
                      variant="label-md"
                      color="foreground-intermediate"
                      className="mb-[2px]"
                    >
                      incl. GST
                    </Typography>
                  </View>
                </View>
              </CardContent>
            </Card>
          </View>

          <View className="flex flex-col gap-4">
            <View className="flex w-full flex-row justify-between px-1">
              <Typography variant="body-lg" color="foreground-default">
                Balance Due
              </Typography>
              <View className="flex flex-row items-end gap-1">
                <Typography variant="body-xl" color="brand-primary">
                  {balance}
                </Typography>
                <Typography
                  variant="label-md"
                  color="foreground-intermediate"
                  className="mb-[2px]"
                >
                  incl. GST
                </Typography>
              </View>
            </View>
            <View className="flex w-full flex-row gap-2">
              <Button
                variant="secondary"
                color="CTA2"
                fullWidth="full"
                onClick={() => onShowModal()}
                children="Cancel"
                disabled={loading}
              />
              <Button
                variant="primary"
                color="CTA"
                fullWidth="full"
                onClick={onPay}
                children="Pay"
                disabled={loading}
              />
            </View>
          </View>
        </View>
      </DialogContent>
    </Dialog>
  );
};

import { UserDeviceEnum } from "@/__generated__/graphql";
import { copyClipboard } from "@/lib/helpers/copy-clipboard";
import { handleOpenLink, share, getUserDevice } from "@/lib/platform";
import { useAuthState } from "@/store/auth";
import { useClientReferralCodeStore } from "@/store/auth/clientReferralCode";
import { useEffect } from "react";

type SocialMediaPlatform =
  | "WhatsApp"
  | "Telegram"
  | "Messenger"
  | "WeChat"
  | "SMS"
  | "Instagram";

const buildReferralInvitationMessage = (code: string): string => {
  const usingCodeHelpPageURL =
    "https://help.lucemg.com/referring-a-friend/using-a-referral-code";

  return [
    "Hey there! 👋🏻 I'd love to invite you to experience Luce's cleaning and maintenance services! They offer home and office cleaning, aircon servicing, upholstery deep cleaning, and even home beauty services for your manicure-pedicure needs! 💅✨\n\n",
    `Unlock $15 off on your first booking with my special code: ${code}. A treat just for you! 🎁\n\n`,
    `If you're wondering where to enter the code, you can check out this helpful guide: ${usingCodeHelpPageURL}`,
  ].join("\r");
};

const appLuceMgUrl = "https://app.luce.com/";

function useShareSocialMedia(): {
  loading: boolean;
  copyLinkAction(): void;
  whatsappShareAction(): void;
  telegramShareAction(): void;
  facebookMessengerShareAction(): void;
  weChatShareAction(): void;
  smsShareAction(): void;
  sharerOtherAction(): void;
  instagramShareAction(): void;
} {
  const clientId = useAuthState((state) => state.data.userInfo.id);
  const { data: code, fetch, loading } = useClientReferralCodeStore();
  const device = getUserDevice();

  useEffect(() => {
    if (clientId) {
      fetch({
        requestPayload: {
          clientId,
        },
      });
    }
  }, [clientId]);

  const message = buildReferralInvitationMessage(code ?? "");
  const textBasedUrl = encodeURIComponent(message);

  const handleShareAction = (platform: SocialMediaPlatform) => {
    let url = "";

    const messengerFallback = `https://www.facebook.com/sharer/sharer.php?u=${appLuceMgUrl}&t=${textBasedUrl}}`;

    switch (platform) {
      case "WhatsApp":
        url = `https://wa.me/?text=${textBasedUrl}`;
        break;
      case "Instagram":
        url = `instagram-stories://share`;
        break;
      case "Telegram":
        url = `https://t.me/share/url?url=${appLuceMgUrl}&text=${textBasedUrl}`;
        break;
      case "Messenger":
        url =
          device === UserDeviceEnum.Android
            ? "fb-messenger://"
            : `https://m.me/lucegroupsg?text=${textBasedUrl}`;
        break;
      case "SMS":
        url =
          device === UserDeviceEnum.Ios
            ? `sms:&body=${textBasedUrl}`
            : `sms:?body=${textBasedUrl}`;
        break;
      case "WeChat":
        url = `weixin://dl/chat?content=${textBasedUrl}`;
        break;
      default:
        throw new Error("Invalid platform url");
    }

    handleOpenLink(url).then((success) => {
      if (platform === "Messenger" && !success) {
        handleOpenLink(messengerFallback);
      }
    });
  };

  const copyLinkAction = (): void => {
    copyClipboard(message);
  };

  const sharerOtherAction = () => {
    share(appLuceMgUrl, message);
  };

  return {
    loading,
    copyLinkAction,
    sharerOtherAction,
    whatsappShareAction: () => handleShareAction("WhatsApp"),
    telegramShareAction: () => handleShareAction("Telegram"),
    facebookMessengerShareAction: () => handleShareAction("Messenger"),
    weChatShareAction: () => handleShareAction("WeChat"),
    smsShareAction: () => handleShareAction("SMS"),
    instagramShareAction: () => handleShareAction("Instagram"),
  };
}

export default useShareSocialMedia;

import { View, Button } from "@/components/ui";
import { IfElse } from "@/components/shared/if-else";
import { useIntl } from "react-intl";

export const AddCreditButtonsAction = ({
  onRemoveToUpdate,
  hasPayment,
  onConfirm,
  loading,
  isUpdateCard,
  onCancelUpdate,
  onConfirmUpdateCard,
}: {
  onRemoveToUpdate: () => void;
  hasPayment: boolean;
  onConfirm: () => void;
  loading: boolean;

  isUpdateCard: boolean;
  onCancelUpdate: () => void;
  onConfirmUpdateCard: () => void;
}) => {
  const intl = useIntl();
  return (
    <View className="flex w-full flex-row gap-2 md:mt-4 md:p-0">
      <IfElse if={!hasPayment}>
        <Button
          variant="primary"
          color="CTA"
          size="lg"
          onClick={onConfirm}
          loading={loading}
          className="w-full md:ml-auto md:w-auto"
          children="Confirm"
        />
      </IfElse>
      <IfElse if={hasPayment && !isUpdateCard}>
        <View className="flex flex-1 flex-col gap-2 md:flex-row">
          <Button
            variant="primary"
            color="CTA"
            size="lg"
            onClick={onRemoveToUpdate}
            disabled={loading}
            children={intl.formatMessage({
              defaultMessage: "Update Credit Card",
              id: "update-card",
            })}
          />
        </View>
      </IfElse>
      <IfElse if={hasPayment && isUpdateCard}>
        <View className="flex flex-1 flex-col gap-2 md:flex-row">
          <Button
            variant="secondary"
            color="CTA2"
            size="lg"
            onClick={onCancelUpdate}
            className="md:ml-auto"
            loading={loading}
            children="Cancel"
          />
          <Button
            variant="primary"
            color="CTA"
            size="lg"
            onClick={onConfirmUpdateCard}
            loading={loading}
            children="Confirm Update"
          />
        </View>
      </IfElse>
    </View>
  );
};

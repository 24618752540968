import { View } from "@/components/ui";
import { useParams } from "@/components/hooks/use-params";
import { InvoiceDetail } from "@/containers/profile/invoices/invoice-detail";
import { Navigate } from "react-router-dom";

import { PaymentMethodContainer } from "@/containers/profile/invoices/payment-method";

export function InvoiceDetailPage() {
  const { id } = useParams<{ id: string }>();

  if (!id) {
    return <Navigate to="/profile/invoices" />;
  }

  return (
    <View>
      <InvoiceDetail invoiceId={id} />
      <PaymentMethodContainer />
    </View>
  );
}

import React from "react";
import { LoginPageWrapper } from "@/containers/auth/login/login-page-wrapper";
import { useAuth } from "../../auth-provider";
import { WebLayout } from "../../layout/homepage";
import { Spin } from "../../spin";
import { View } from "@/components/ui";
interface PrivateRouteProps {
  children: React.ReactElement;
}

export function PrivateRoute({ children }: PrivateRouteProps) {
  const { isGuest, isLoading } = useAuth();

  if (isLoading) {
    return (
      <WebLayout>
        <View className="flex h-screen items-center justify-center">
          <Spin className="size-10" />
        </View>
      </WebLayout>
    );
  }

  if (isGuest) {
    return <LoginPageWrapper />;
  }

  return children;
}

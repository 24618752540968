import { useEffect, useMemo, useState } from "react";
import type {
  DurationOption,
  FrequencyOption,
} from "@/components/shared/service-radio-item/types";
import { useAllClientPackageDetailsStore } from "@/store/booking/allClientPackageDetails";
import {
  PackageRecurrenceEnum,
  ServiceTypeEnum,
} from "@/__generated__/graphql";
import {
  getBookingPackages,
  getDateRangeFromFrequency,
} from "@/lib/booking-lib";
import { useBookingRoute } from "@/components/hooks/use-booking-route";
import { useBookingState } from "@/store/booking/useBookingState";
import {
  HomeCleaningDurationOptions,
  HomeCleaningFrequencyOptionItems,
  HomeCleaningFrequencyOptions,
  HomeCleaningUpsellOptions,
} from "@/constants/booking";
import { SelectServices } from "../../select-services";
import { FindBestHelperModal } from "@/components/shared/find-best-helper-modal";
import { PackageFrequencyGroupEnum } from "@/types/booking";
import { View, Image } from "@/components/ui";
import { Divider } from "@/components/shared/divider";
import { Typography } from "@/components/shared/typography";
import QuickCleaningTip from "@/assets/images/booking/quick-cleaning-tip.png";
import { CleaningDurationModal } from "../../home-cleaning/cleaning-duration-modal";
// import { UnlockMoreModal } from "../../home-cleaning/unlock-more-modal";

export function HomeCleaningBooking() {
  // const [upsellModalOpen, setUpsellModalOpen] = useState(false);
  const {
    data: { department, packageDetailsFilter, homeCleaningDurationModalOpen },
    setBookingState,
  } = useBookingState();
  const [findHelperModalOpen, setFindHelperModalOpen] = useState(false);
  const { forward } = useBookingRoute();

  const homeCleaningSelectedFrequencyOption = useMemo(() => {
    return (
      HomeCleaningFrequencyOptions.flat().find(
        (opt) => opt.frequency === packageDetailsFilter.frontEndFrequency,
      ) || HomeCleaningFrequencyOptions[0][0]
    );
  }, [packageDetailsFilter.frontEndFrequency]);

  const initFrequency = () => {
    setBookingState({
      packageDetailsFilter: {
        serviceTypes: [ServiceTypeEnum.HomeCleaning],
        repeatEvery: [PackageRecurrenceEnum.Week],
        repeatEveryTimes: 1,
        frontEndFrequency: PackageFrequencyGroupEnum.WEEKLY,
        duration: 0,
      },
    });
  };

  useEffect(() => {
    initFrequency();
  }, []);

  useEffect(() => {
    if (homeCleaningDurationModalOpen) {
      setBookingState({
        packageDetailsFilter: {
          ...packageDetailsFilter,
          duration: 4,
        },
      });
    }
  }, [homeCleaningDurationModalOpen]);

  const onChooseCleaningFrequency = (item: FrequencyOption) => {
    if (item.title === "> Once Per Week") {
      setFindHelperModalOpen(true);
      return;
    }
    setBookingState({
      packageDetailsFilter: {
        ...packageDetailsFilter,
        repeatEvery: [item.repeatEvery],
        repeatEveryTimes: item.repeatEveryTimes,
        frontEndFrequency: item.frequency,
      },
    });
  };

  const { fetch: getAllClientPackage, loading } =
    useAllClientPackageDetailsStore();

  const getPackages = () => {
    const { frontEndFrequency: _frontEndFrequency, ...filters } =
      packageDetailsFilter;

    return getAllClientPackage({
      requestPayload: {
        filters: {
          ...filters,
          department,
          active: true,
        },
      },
    }).then((res) => {
      if (res.error || !res.data) {
        return;
      }
      const packages = getBookingPackages(department, res.data);
      setBookingState({ selectedPackageIds: packages.map((pkg) => pkg.id) });
    });
  };

  // const onClickModalProceed = () => {
  //   const { frontEndFrequency: _frontEndFrequency, ...filters } =
  //     packageDetailsFilter;

  //   if (filters.repeatEveryTimes === 0) {
  //     setBookingState({
  //       homeCleaningDurationModalOpen: false,
  //     });
  //     setUpsellModalOpen(true);
  //     return;
  //   }

  //   getPackages().then(() => {
  //     forward();
  //   });
  // };

  const onSelectUpsellCard = (title: string) => {
    const option = HomeCleaningUpsellOptions.find((opt) => opt.title === title);
    if (option) {
      if (option.unselectable) {
        setFindHelperModalOpen(true);
      } else {
        setBookingState({
          packageDetailsFilter: {
            ...packageDetailsFilter,
            repeatEvery: [option.repeatEvery],
            repeatEveryTimes: option.repeatEveryTimes,
            frontEndFrequency: option.frequency,
          },
        });
      }
    }
    setBookingState({
      homeCleaningDurationModalOpen: false,
    });
  };

  useEffect(() => {
    if (!packageDetailsFilter.frontEndFrequency) return;

    const [startDate, endDate] = getDateRangeFromFrequency(
      packageDetailsFilter.frontEndFrequency,
    );
    setBookingState({
      startDate,
      endDate,
    });
  }, [packageDetailsFilter.frontEndFrequency]);

  const onSelectDuration = (option: DurationOption) =>
    setBookingState({
      packageDetailsFilter: {
        ...packageDetailsFilter,
        duration: option.duration,
      },
    });

  // const onTryRecurring = () => {
  //   setUpsellModalOpen(false);
  //   initFrequency();
  // };

  const onKeepOneTime = () => {
    getPackages().then(() => {
      // setUpsellModalOpen(false);
      forward();
    });
  };

  return (
    <View className="pb-4">
      <SelectServices
        selectedFrequencyOption={homeCleaningSelectedFrequencyOption}
        cleaningFrequencyOptions={HomeCleaningFrequencyOptionItems}
        onChooseCleaningFrequency={onChooseCleaningFrequency}
      />
      <View className="py-6">
        <Divider className="border-b-2" />
      </View>
      <View className="mx-4">
        <View className="flex flex-row items-center justify-between gap-2 rounded-lg bg-primary-surface p-3">
          <View>
            <Typography
              variant="label-lg"
              color="brand-primary"
              className="pb-1"
            >
              Quick Cleaning Tip
            </Typography>
            <Typography variant="body-md" color="brand-primary">
              For a hassle-free cleaning, gather your preferred equipment
              beforehand.
            </Typography>
          </View>
          <Image src={QuickCleaningTip} width={55} height={60} />
        </View>
      </View>

      {/* disable this for now due the A/B testint */}
      {/* <UnlockMoreModal
        loading={loading}
        open={upsellModalOpen}
        setOpen={setUpsellModalOpen}
        onKeepOneTime={onKeepOneTime}
        onTryRecurring={onTryRecurring}
      /> */}

      <CleaningDurationModal
        open={homeCleaningDurationModalOpen}
        setOpen={(open: boolean) =>
          setBookingState({ homeCleaningDurationModalOpen: open })
        }
        durationOptions={HomeCleaningDurationOptions}
        upsellOptions={HomeCleaningUpsellOptions}
        selectedFrequencyOption={homeCleaningSelectedFrequencyOption}
        selectedDurationOptionValue={String(packageDetailsFilter.duration)}
        onSelectDurationOption={onSelectDuration}
        onSelectUpsellCard={onSelectUpsellCard}
        onProceed={onKeepOneTime}
        loading={loading}
      />

      <FindBestHelperModal
        open={findHelperModalOpen}
        setOpen={setFindHelperModalOpen}
      />
    </View>
  );
}

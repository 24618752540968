import { Button, View } from "@/components/ui";
import { cn } from "@/lib/utils";
import type { ReactNode } from "react";
import { useState, useRef, useEffect } from "react";
import { useIntl } from "react-intl";

export function ExpandableView({
  initialHeight,
  children,
  className,
}: {
  initialHeight: number;
  children: ReactNode;
  className?: string;
}) {
  const intl = useIntl();
  const [isExpanded, setIsExpanded] = useState(false);
  const [contentHeight, setContentHeight] = useState(initialHeight);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!contentRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setContentHeight(contentRef.current?.scrollHeight ?? initialHeight);
    });
    resizeObserver.observe(contentRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  const toggleContent = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <View
      className={cn(
        "HideShowContent relative mx-auto overflow-hidden",
        className,
      )}
    >
      <View
        className={cn("overflow-hidden transition duration-500 ease-in-out")}
        style={{ height: isExpanded ? "auto" : initialHeight }}
      >
        <div ref={contentRef} className="content">
          {children}
        </div>
      </View>
      {contentHeight > initialHeight && (
        <View className="absolute bottom-0 z-10 w-full bg-white">
          <Button
            variant="tertiary"
            color="CTA2"
            fullWidth="full"
            onClick={toggleContent}
            iconAlignment="end"
            iconName={isExpanded ? "caretUp" : "caretDown"}
            children={
              isExpanded
                ? intl.formatMessage({
                    defaultMessage: "View Less",
                    id: "show-less",
                  })
                : intl.formatMessage({
                    defaultMessage: "View More",
                    id: "show-more",
                  })
            }
          />
        </View>
      )}
    </View>
  );
}

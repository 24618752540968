import { View } from "@/components/ui";
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";

const bottomsActionsVariants = cva(
  "z-50 flex w-full rounded-t-2xl bg-white p-8 shadow-sm web:fixed web:bottom-0 web:left-0 web:right-0",
  {
    variants: {
      direction: {
        row: "flex-row",
        col: "flex-col",
      },
      gap: {
        none: "gap-0",
        xs: "gap-0.5",
        sm: "gap-1",
        md: "gap-2",
        lg: "gap-3",
        xl: "gap-4",
      },
    },
    defaultVariants: {
      direction: "row",
      gap: "none",
    },
  },
);

type Props = VariantProps<typeof bottomsActionsVariants> & {
  children: React.ReactNode;
};

export function BottomActions({ direction, gap, children }: Props) {
  return (
    <View className={bottomsActionsVariants({ direction, gap })}>
      {children}
    </View>
  );
}

import { z } from "zod";
import type { UseFormReturn } from "react-hook-form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { matchDollarAmountRE } from "@/constants/regex";

const clientApplyCreditSchema = z.object({
  balance: z
    .string()
    .min(1, "Balance cannot be blank")
    .refine((value) => matchDollarAmountRE.test(value), {
      message: "Only numbers or decimals are allowed, Please check again.",
    }),
});

export type ClientApplyCreditFormData = z.infer<typeof clientApplyCreditSchema>;

export interface ClientApplyCreditFormReturn
  extends UseFormReturn<ClientApplyCreditFormData> {
  defaultValues: ClientApplyCreditFormData;
}

const initialFormValues: ClientApplyCreditFormData = {
  balance: "",
};

export const useClientApplyCreditForm = (
  initialValues?: ClientApplyCreditFormData,
): ClientApplyCreditFormReturn => ({
  defaultValues: initialValues || initialFormValues,
  ...useForm<ClientApplyCreditFormData>({
    mode: "onSubmit",
    defaultValues: initialValues,
    resolver: zodResolver(clientApplyCreditSchema),
  }),
});

import { PayNowScreenModal } from "./paynow-screen-modal";
import { SelectPaymentScreenModal } from "./select-payment-screen-modal";
import {
  ACCOUNT_NUMBER,
  UEN_NUMBER,
  MERCHANT_NAME,
} from "@/constants/invoices";
import { copyClipboard } from "@/lib/helpers/copy-clipboard";
import type { PaymentType } from "@/store/invoices";
import { useInvoiceState } from "@/store/invoices";
import { PaymentMethodScreenModal } from "@/types/invoice";
import { CreditCardScreenModal } from "./credit-card-screen-modal";
import { useUpdatePaymentIndicatedStore } from "@/store/invoices/updatePaymentIndicated";
import { useRoute } from "@/components/shared/router";
import { generatePaymentQRUrl, getErrorMessage } from "@/lib/helpers/string";
import { showToast } from "@/components/ui/toast/show-toast";
import { downloadFromUrl } from "@/lib/platform";
import type { Control } from "react-hook-form";
import type { ClientApplyCreditFormData } from "@/store/profile/forms/clientApplyCreditForm";
import { useClientApplyCreditForm } from "@/store/profile/forms/clientApplyCreditForm";
import { useState } from "react";
import { useClientApplyCreditStore } from "@/store/profile/clientApplyCredit";
import { formatPrice } from "@/lib/helpers/number";
import { useAuthState } from "@/store/auth";
import { AddCreditCardScreenModal } from "./add-credit-card-screen-modal";

export type SelectPaymentScreenModalProps = {
  open: boolean;
  onShowModal: (...modalNames: PaymentMethodScreenModal[]) => void;
  onPay: () => void;
  control: Control<ClientApplyCreditFormData>;
  loading: boolean;
  selectPayment: string;
  setSelectPayment: React.Dispatch<React.SetStateAction<PaymentType>>;
  baseAmount: string;
  amount: string;
  paidAmount: number;
  balance: string;
  appliedVoucher: boolean;
};

export type PayNowScreenModalProps = {
  open: boolean;
  invoiceId: string;
  accountNumber: string;
  eunNumber: string;
  merchantName: string;
  onCopyAccountNumber: () => void;
  onCopyUenNumber: () => void;
  paymentQrUri: string;
  onDownloadQr: () => void;
  onClose: () => void;
  onConfirmTransfer: () => Promise<void>;
  loading: boolean;
};

export const PaymentMethodContainer = () => {
  const { push } = useRoute();
  const {
    data: {
      invoiceId,
      baseAmount,
      amount,
      paidAmount,
      balance,
      selectPaymentScreenModal,
      paynowScreenModal,
      creditCardScreenModal,
      addCreditCardScreenModal,
      appliedVoucher,
    },
    showPaymentMethodModal,
  } = useInvoiceState();

  const {
    data: {
      userInfo: { paymentMethod },
    },
  } = useAuthState();

  const [selectPayment, setSelectPayment] =
    useState<PaymentType>("credit-card");

  const { fetch: updatePayment, loading: updatePaymentLoading } =
    useUpdatePaymentIndicatedStore();
  const { fetch: clientApplyCredit, loading: clientApplyLoading } =
    useClientApplyCreditStore();

  const paymentQrUri = generatePaymentQRUrl({ invoiceId, balance });

  const { control, handleSubmit } = useClientApplyCreditForm();

  const onCopyAccountNumber = () => {
    copyClipboard(ACCOUNT_NUMBER);
  };

  const onCopyUenNumber = () => {
    copyClipboard(UEN_NUMBER);
  };

  const onDownloadQr = () => {
    downloadFromUrl(paymentQrUri, "LUCE_QR_INVOICE_" + invoiceId + ".png");
  };

  const onConfirmTransfer = async () => {
    await updatePayment(
      { requestPayload: { input: { invoiceId } } },
      { selfHandleError: true },
    );

    const successMsg = `Success update payment Invoice #${invoiceId}`;
    showToast({
      title: successMsg,
      type: "success",
    });

    showPaymentMethodModal();
    push({ pageKey: "invoices" });
  };

  const onSubmit = handleSubmit(async (data: ClientApplyCreditFormData) => {
    try {
      const res = await clientApplyCredit(
        {
          requestPayload: {
            input: {
              invoiceId: invoiceId,
              amount: parseFloat(data.balance),
            },
          },
        },
        { selfHandleError: true },
      );

      showToast({
        title: "Success apply credit account",
        type: "success",
      });

      showPaymentMethodModal();

      push({ pageKey: "invoices" });

      if (res.error) throw new Error(res.error);
    } catch (error) {
      const errMsg = getErrorMessage(error, "Error apply credit account");
      showToast({
        title: errMsg,
        type: "error",
      });
    }
  });

  const onPay = () => {
    switch (selectPayment) {
      case "paynow":
        showPaymentMethodModal(PaymentMethodScreenModal.PAYNOW);
        break;
      case "credit-card":
        showPaymentMethodModal(
          paymentMethod
            ? PaymentMethodScreenModal.CREDIT_CARD
            : PaymentMethodScreenModal.ADD_CREDIT_CARD,
        );
        break;
      case "apply-credit":
        onSubmit();
        break;
      default:
        showPaymentMethodModal(PaymentMethodScreenModal.PAYNOW);
    }
  };

  return (
    <>
      <SelectPaymentScreenModal
        control={control}
        open={selectPaymentScreenModal}
        onShowModal={showPaymentMethodModal}
        loading={clientApplyLoading}
        onPay={onPay}
        selectPayment={selectPayment}
        setSelectPayment={setSelectPayment}
        baseAmount={formatPrice(baseAmount)}
        paidAmount={paidAmount}
        amount={formatPrice(amount)}
        balance={formatPrice(balance)}
        appliedVoucher={appliedVoucher}
      />

      <PayNowScreenModal
        open={paynowScreenModal}
        invoiceId={invoiceId}
        accountNumber={ACCOUNT_NUMBER}
        eunNumber={UEN_NUMBER}
        merchantName={MERCHANT_NAME}
        onCopyAccountNumber={onCopyAccountNumber}
        onCopyUenNumber={onCopyUenNumber}
        paymentQrUri={paymentQrUri}
        onDownloadQr={onDownloadQr}
        onClose={() =>
          showPaymentMethodModal(PaymentMethodScreenModal.SELECT_PAYMENT)
        }
        onConfirmTransfer={onConfirmTransfer}
        loading={updatePaymentLoading}
      />

      <CreditCardScreenModal
        open={creditCardScreenModal}
        onClose={() =>
          showPaymentMethodModal(PaymentMethodScreenModal.SELECT_PAYMENT)
        }
      />

      <AddCreditCardScreenModal
        open={addCreditCardScreenModal}
        onClose={() =>
          showPaymentMethodModal(PaymentMethodScreenModal.SELECT_PAYMENT)
        }
      />
    </>
  );
};

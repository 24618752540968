import { VisitStatusEnum } from "@/__generated__/graphql";
import { Badge, View } from "@/components/ui";
import type { Icon } from "@/components/shared/icons";
import {
  CalendarCheck,
  HourglassMedium,
  CheckCircle,
  XCircle,
} from "@/components/shared/icons";
import { visitStatusLabel } from "@/lib/service/helpers";
import { useMemo } from "react";
import type { BadgeVariant } from "@/components/ui/badge";
import { Typography } from "../../typography";

const statusIcon = (status: VisitStatusEnum): Icon => {
  switch (status) {
    case VisitStatusEnum.Completed:
    case VisitStatusEnum.Finished:
      return CheckCircle;
    case VisitStatusEnum.Scheduled:
      return CalendarCheck;
    case VisitStatusEnum.PendingClientSchedule:
    case VisitStatusEnum.UnableToSchedule:
      return HourglassMedium;
    case VisitStatusEnum.Started:
    case VisitStatusEnum.Expired:
    case VisitStatusEnum.CancelledByClient:
    case VisitStatusEnum.CancelledByOps:
    case VisitStatusEnum.CancelledByWorker:
    case VisitStatusEnum.MissedByWorker:
      return XCircle;
    default:
      return CalendarCheck;
  }
};

export function VisitStatusBadge({ status }: { status: VisitStatusEnum }) {
  const BadgeIcon = statusIcon(status);

  const { variant, color } = useMemo(() => {
    let variant: BadgeVariant = "primary";
    let color: string = "color-brand-primary text-brand-primary";

    switch (status) {
      case VisitStatusEnum.Completed:
      case VisitStatusEnum.Finished:
        variant = "success";
        color = "text-success-on-surface";
        break;
      case VisitStatusEnum.Scheduled:
        variant = "primary";
        color = "text-brand-primary";
        break;
      case VisitStatusEnum.PendingClientSchedule:
      case VisitStatusEnum.UnableToSchedule:
        variant = "warning";
        color = "text-warning-on-surface";
        break;
      case VisitStatusEnum.Started:
      case VisitStatusEnum.Expired:
        variant = "neutral";
        color = "text-foreground-intermediate";
        break;
      case VisitStatusEnum.CancelledByClient:
      case VisitStatusEnum.CancelledByOps:
      case VisitStatusEnum.CancelledByWorker:
      case VisitStatusEnum.MissedByWorker:
        variant = "danger";
        color = "text-danger-on-surface";
        break;
    }

    return { variant, color };
  }, [status]);

  return (
    <Badge variant={variant} className="self-center">
      <View className="flex flex-row gap-1 md:gap-2">
        <BadgeIcon weight="fill" size={16} className={color} />
        <Typography variant="label-md" numberOfLines={1} className={color}>
          {visitStatusLabel(status)}
        </Typography>
      </View>
    </Badge>
  );
}

import type {
  PackageDepartmentEnum,
  VisitClientFragmentFragment,
} from "@/__generated__/graphql";
import {
  PackageDetailFragmentFragmentDoc,
  PackageServiceTypeEnum,
  VisitStatusEnum,
} from "@/__generated__/graphql";
import { Button, Skeleton, View } from "@/components/ui";
import { getWorkerLabel, packageServiceTypeLabel } from "@/lib/service/helpers";
import { VisitStatusBadge } from "../visit-card/visit-status-badge";
import {
  formatVisitAddress,
  formatVisitSchedule,
  visitWorkerFullName,
} from "../utils";
import type { VisitWorker } from "../visit-card";
import { Divider } from "../../divider";
import { WorkerAvatar } from "../visit-card/worker-avatar";
import { CaretRight, Receipt, WarningCircle } from "@/components/shared/icons";
import { handleWhatsappNumber } from "@/lib/platform";
import { ListHeading } from "../../list-header";
import { LineItemList } from "../../line-item/line-item-list";
import type { LineItemDetail } from "@/types/line-item";
import { getLineItemClientDetail } from "@/lib/line-items/helpers";
import { useFragment } from "@/__generated__";
import {
  formatPrice,
  SINGAPORE_TAX,
  singaporeTaxCalculation,
} from "@/lib/helpers/number";
import { useMemo } from "react";
import { VisitRating } from "../visit-card/visit-rating";
import { Typography } from "../../typography";
import { formatDecimalHour } from "@/lib/utils";
import { copyClipboard } from "@/lib/helpers/copy-clipboard";
import { IconButton } from "@/components/ui/icon-button";
import { ActionButton } from "../visit-detail-modal/action-button";
import { AddToCalendarButton } from "@/containers/booking-complete/add-to-calendar";
import { formatDate } from "@/lib/helpers/date";

export type VisitDetailData = {
  id: string;
  department: PackageDepartmentEnum;
  serviceType: PackageServiceTypeEnum;
  status: VisitStatusEnum;
  value: number;
  serviceDate: Date;
  startTime: string;
  endTime: string;
  address: string;
  postalCode: string;
  worker: VisitWorker;
  rating: number;
  ratingComment: string;
  reviewImageUrls: string[];
  lineItems: LineItemDetail[];
  token: string | null;
  reportDate: Date | null;
};

export type VisitDetailHandler = {
  handleReschedule(id: string): void;
  handleChangeWorker(id: string): void;
  handleReportVisit(id: string): void;
  handleSkipVisit(id: string): void;
  handleRateVisit(id: string, rate?: number): void;
};

type Props = {
  visitData: VisitDetailData | null;
  loading: boolean;
} & VisitDetailHandler;

export function mapToVisitDetailData(
  visit: VisitClientFragmentFragment,
  token: string | null = null,
): VisitDetailData {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const pkg = visit.job!.package;

  let value = 0;

  const lineItems = visit.visitLineItems.map((item) => {
    value += item.billingValue;
    return getLineItemClientDetail(
      useFragment(PackageDetailFragmentFragmentDoc, item.packageDetail),
      {
        billingValue: item.billingValue,
        unitValue: item.unitValue,
        description: item.packageDescription,
        unitNumber: item.units,
      },
    );
  });

  return {
    id: visit.id,
    department: pkg.department,
    serviceType: pkg.serviceType,
    status: visit.status,
    value,
    serviceDate: new Date(visit.serviceDate),
    startTime: String(visit.startTime),
    endTime: String(visit.endTime),
    address: formatVisitAddress(
      pkg.fullAddress,
      pkg.unitNumber ?? "",
      pkg.postalCode,
    ),
    postalCode: pkg.postalCode,
    worker: {
      fullName: visitWorkerFullName(visit.status, visit.worker),
      phoneNumber: visit.worker?.contactNumber ?? null,
      avatarUrl: visit.worker?.avatarUrl ?? null,
      id: visit.worker?.id ?? "",
    },
    rating: visit.rating ?? 0,
    ratingComment: visit.ratingComment ?? "",
    reviewImageUrls:
      visit.reviewAttachments?.map((attachment) => attachment.downloadUrl) ??
      [],
    reportDate: visit.reportDateIssue ? new Date(visit.reportDateIssue) : null,
    lineItems,
    token,
  };
}

export function VisitDetailContent({
  visitData,
  loading,
  handleReschedule,
  handleChangeWorker,
  handleReportVisit,
  handleSkipVisit,
  handleRateVisit,
}: Props) {
  const handleContactProfessional = () => {
    if (visitData?.worker.phoneNumber) {
      handleWhatsappNumber(visitData.worker.phoneNumber);
    }
  };

  const lineItems = useMemo(
    () =>
      visitData?.lineItems.filter(
        (item) => item.serviceType !== PackageServiceTypeEnum.Discount,
      ) ?? [],
    [visitData?.lineItems],
  );

  const serviceDuration = useMemo(() => {
    const { totalDuration, totalUnit } = (visitData?.lineItems ?? []).reduce(
      (totals, item) => {
        totals.totalDuration += item.duration;
        totals.totalUnit += item.unitsNumber;

        return totals;
      },
      { totalDuration: 0, totalUnit: 0 },
    );
    return `${formatDecimalHour(totalDuration)} (${totalUnit} Items)`;
  }, [visitData?.lineItems]);

  const discounts = (visitData?.lineItems ?? []).filter(
    (item) => item.serviceType === PackageServiceTypeEnum.Discount,
  );

  const price = singaporeTaxCalculation(visitData?.value ?? 0);

  const renderActionButton = useMemo(() => {
    const horizontalReportButton = (
      <View
        className="flex cursor-pointer flex-row items-center justify-between gap-3"
        onClick={() => visitData && handleReportVisit(visitData.id)}
      >
        <WarningCircle size={24} className="color-foreground" />
        <Typography
          variant="body-md"
          color="foreground-default"
          className="flex-1"
        >
          Report Issue
        </Typography>
        <CaretRight size={16} className="color-foreground-intermediate" />
      </View>
    );

    if (!visitData) {
      return null;
    }

    if (visitData.status === VisitStatusEnum.Completed) {
      return (
        <View className="flex flex-col gap-4">
          <VisitRating
            variant="visitDetail"
            rating={visitData.rating}
            onClick={(rate) => handleRateVisit(visitData.id, rate)}
          />
          {horizontalReportButton}
        </View>
      );
    }

    if (visitData.status === VisitStatusEnum.PendingClientSchedule) {
      return horizontalReportButton;
    }

    if (visitData.status === VisitStatusEnum.Scheduled) {
      return (
        <View className="flex flex-row">
          <ActionButton
            label="Reschedule"
            iconName="calendarDots"
            onClick={() => handleReschedule(visitData.id)}
            variant="regular"
          />
          <ActionButton
            label={`Change ${getWorkerLabel(visitData.department)}`}
            iconName="userSwitch"
            onClick={() => handleChangeWorker(visitData.id)}
            variant="regular"
          />
          <ActionButton
            label="Report Issue"
            iconName="warningCircle"
            onClick={() => visitData && handleReportVisit(visitData.id)}
          />
          <ActionButton
            label="Skip Visit"
            iconName="xCircle"
            onClick={() => handleSkipVisit(visitData.id)}
            variant="danger"
          />
        </View>
      );
    }

    return horizontalReportButton;
  }, [visitData, loading]);

  const serviceTitle = useMemo(() => {
    if (!visitData?.serviceType || !visitData?.department) return "";
    return packageServiceTypeLabel(
      visitData?.serviceType || "",
      visitData?.department || "",
    );
  }, [visitData?.serviceType, visitData?.department]);

  return (
    <View className="flex flex-col gap-4">
      <View className="flex flex-row items-center justify-between md:mt-2">
        {!visitData || loading ? (
          <Skeleton className="h-4 w-[200px]" />
        ) : (
          <Typography variant="label-xl">{serviceTitle}</Typography>
        )}
        {visitData && <VisitStatusBadge status={visitData.status} />}
      </View>
      <Divider />
      <View className="flex flex-col gap-2">
        {loading ? (
          <>
            <Skeleton className="h-2 w-[30%]" />
            <Skeleton className="h-2 w-[70%]" />
            <Skeleton className="h-2 w-[70%]" />
          </>
        ) : (
          <>
            <View className="flex flex-row items-center gap-1">
              <Typography variant="label-md" color="foreground-intermediate">
                ID {visitData?.id ?? ""}
              </Typography>
              <IconButton
                variant="ghost"
                color="foreground"
                size="sm"
                className="p-0"
                iconName="copy"
                disabled={!visitData}
                onClick={() =>
                  visitData && copyClipboard(`Visit #${visitData.id}`)
                }
              />
            </View>
            <View className="flex flex-row items-center justify-between gap-1">
              <Typography variant="body-md" color="foreground-intermediate">
                {visitData
                  ? formatVisitSchedule(
                      visitData.startTime,
                      visitData.endTime,
                      visitData.serviceDate,
                    )
                  : "Pending schedule"}
              </Typography>
              {visitData?.status === VisitStatusEnum.Scheduled && (
                <AddToCalendarButton
                  title={serviceTitle}
                  description=""
                  startTime={`${formatDate(visitData?.serviceDate)}T${visitData?.startTime}`}
                  endTime={`${formatDate(visitData?.serviceDate)}T${visitData?.endTime}`}
                  location={visitData?.address || ""}
                />
              )}
            </View>
            <Typography variant="body-md" color="foreground-intermediate">
              {visitData?.address ?? "Not available"}
            </Typography>
          </>
        )}
      </View>
      <Divider />
      <View className="flex flex-row items-center justify-between">
        <WorkerAvatar
          fullName={visitData?.worker.fullName ?? ""}
          avatarUrl={visitData?.worker.avatarUrl ?? null}
          loading={loading}
        />
        {visitData?.status === VisitStatusEnum.Scheduled &&
          visitData?.worker.phoneNumber && (
            <Button
              variant="secondary"
              color="success"
              onClick={handleContactProfessional}
              iconName="whatsappIcon"
              iconColor="fill-success"
              children="Contact Professional"
              size="md"
              iconSize="md"
            />
          )}
      </View>
      <Divider />
      {/* <View className="flex flex-row justify-around gap-2"> */}
      {renderActionButton}
      {/* </View> */}
      <Divider />
      <View className="flex flex-col gap-1">
        <ListHeading icon={Receipt} title="Your Order" />
        {loading ? (
          <Skeleton className="h-10 w-full" />
        ) : (
          <LineItemList lineItems={lineItems} />
        )}
      </View>
      <Divider />
      <View className="flex flex-col gap-1">
        {loading ? (
          <View className="flex flex-col gap-1">
            <Skeleton className="h-3 w-1/4" />
            <Skeleton className="h-3 w-1/4" />
            <Skeleton className="h-3 w-1/4" />
          </View>
        ) : (
          <>
            <ListItem label="Service Duration/Visit" value={serviceDuration} />
            <ListItem
              label="Total Cost"
              value={formatPrice(visitData?.value ?? 0, 0)}
            />
            {discounts.map((discount, index) => (
              <ListItem
                key={discount.packageDetailId + index}
                label={discount.description}
                value={formatPrice(discount.billingValue)}
              />
            ))}
            <ListItem
              label={`GST (${SINGAPORE_TAX * 100}%)`}
              value={formatPrice(price.tax)}
            />
          </>
        )}
      </View>
      <Divider />
      <View className="flex flex-row gap-1">
        <Typography variant="label-2xl" className="flex-1 px-0">
          Total
        </Typography>
        <View className="flex flex-row items-center">
          <Typography className="font-medium color-brand-primary">
            {formatPrice(price.price)}{" "}
          </Typography>
          <Typography
            variant="body-md"
            color="foreground-intermediate"
            as="span"
          >
            incl. GST
          </Typography>
        </View>
      </View>
    </View>
  );
}

export function ListItem({ label, value }: { label: string; value: string }) {
  return (
    <View className="flex flex-row gap-1">
      <Typography
        variant="body-md"
        color="foreground-intermediate"
        className="flex-1 px-0"
      >
        {label}
      </Typography>
      <Typography variant="label-lg">{value}</Typography>
    </View>
  );
}

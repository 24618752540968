import { View } from "@/components/ui";
import { Promos } from "@/containers/homepage/promos";

export function PromosPage() {
  return (
    <View className="py-3">
      <Promos toggleHeader={false} />
    </View>
  );
}

import { RadioGroupItem } from "@/components/ui/radio-group";
import { View, Card, CardContent, Image } from "@/components/ui";
import { Divider } from "@/components/shared/divider";
import { cn } from "@/lib/utils";
import { Typography } from "@/components/shared/typography";
import { IfElse } from "@/components/shared/if-else";
import { CaretRight } from "@/components/shared/icons";

export const ServiceRadioCard = ({
  value,
  title,
  subTitle,
  thirdTitle,
  description,
  imgUrl,
  active,
  onSelect,
  isModal,
}: {
  active: boolean;
  imgUrl: string;
  value: string;
  title: string;
  subTitle?: string;
  thirdTitle?: string;
  description?: string;
  onSelect(): void;
  isModal?: boolean;
}) => {
  return (
    <Card
      className={cn({
        "border-primary-border bg-primary-surface": active,
        "hover:border-primary-border hover:bg-primary-surface": !isModal,
      })}
      onClick={onSelect}
    >
      <CardContent
        className={cn({
          "divide-primary-border": active,
          "hover:divide-primary-border": !isModal,
        })}
      >
        <View className="flex flex-row gap-4">
          <View className="h-16 overflow-hidden rounded-lg">
            <Image width={95} src={imgUrl} className="h-full rounded-md" />
          </View>
          <View className="flex flex-1 flex-col gap-0.5">
            {/* font size in UI looks too big, this one is better */}
            <Typography variant={{ md: "body-lg", sm: "body-md" }}>
              {title}
            </Typography>
            <Typography variant="bold-md">{subTitle}</Typography>
            <Typography variant="body-sm" color="foreground-intermediate">
              {thirdTitle}
            </Typography>
          </View>
          <IfElse if={!isModal} else={<CaretRight />}>
            <RadioGroupItem value={value} checkCircle />
          </IfElse>
        </View>
        <IfElse if={!!description}>
          <Divider dashed className="my-2" />
          <Typography variant="body-md" color="foreground-intermediate">
            {description}
          </Typography>
        </IfElse>
      </CardContent>
    </Card>
  );
};

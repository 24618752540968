import { Card, CardContent, View, Image, Button } from "@/components/ui";
import { Container, Column } from "@/components/ui/layout";
import { Package } from "@/components/shared/icons";
import { Typography } from "@/components/shared/typography";
import { RecommendedServices } from "@/components/shared/recommended-services";
import { useRoute } from "@/components/shared/router";
import { ServiceProfessional } from "../service-professional";
import type { BookingCompleteScreenProps } from "..";

import MultiPleStar from "@/assets/images/multiple-star.png";
import { AddToCalendar } from "../add-to-calendar";
import { formatDate } from "@/lib/helpers/date";
import { IfElse } from "@/components/shared/if-else";
import { useMemo } from "react";

export const BookingCompleteScreen = ({
  calendarEvent,
  cleanerInfo,
  reviews,
}: BookingCompleteScreenProps) => {
  const { push } = useRoute();
  const bookingDate = useMemo(() => {
    if (!calendarEvent.startTime) return "";
    return formatDate(
      new Date(calendarEvent.startTime),
      "EEEE, MMMM d '@' h:mmaaa",
    );
  }, [calendarEvent.startTime]);
  return (
    <View>
      <Container center>
        <Column desktop="8">
          <View className="flex flex-col gap-4 p-6 pb-16">
            <Card className="shadow-slate-200" border={"none"} shadow={"lg"}>
              <CardContent className="p-4">
                <View className="flex flex-col items-center gap-2 border-b-2 border-secondary-border p-4">
                  <View className="h-14 w-14">
                    <Image src={MultiPleStar} className="w-full" />
                  </View>

                  <Typography
                    variant={{ md: "bold-2xl", sm: "bold-xl" }}
                    color="brand-primary"
                  >
                    We're excited to serve you
                  </Typography>
                  <Typography
                    variant={{ md: "body-lg", sm: "body-md" }}
                    color="secondary-text"
                    align="center"
                  >
                    Your booking is confirmed! Sit back, relax, and let us take
                    care of everything so you can enjoy the results stress-free.
                  </Typography>
                  <IfElse if={!!calendarEvent.startTime}>
                    <Typography variant="bold-xl">{bookingDate}</Typography>
                  </IfElse>
                </View>
                <View>
                  <ServiceProfessional
                    reviews={reviews}
                    cleanerInfo={{
                      star: cleanerInfo.star,
                      name: cleanerInfo.name,
                      avatar: cleanerInfo.avatar,
                    }}
                  />
                </View>
              </CardContent>

              <View className="flex w-full flex-row justify-end gap-2 px-8 py-4">
                <AddToCalendar
                  title={calendarEvent.title}
                  description={calendarEvent.description}
                  startTime={calendarEvent.startTime}
                  endTime={calendarEvent.endTime}
                  location={calendarEvent.location}
                />
                <Button
                  variant="primary"
                  color="CTA"
                  rounded="full"
                  size={{ sm: "sm", md: "md" }}
                  onClick={() => push({ pageKey: "visits" })}
                  children="View my Visits"
                />
              </View>
            </Card>

            <RecommendedServices title="People also book" icon={Package} />
          </View>
        </Column>
      </Container>
    </View>
  );
};

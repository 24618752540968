import type { StoreApi } from "zustand";
import { getPlatform, isLocal } from "./utils";

type ConfigFn<T extends { data: Record<string, unknown> }> = (
  set: StoreApi<T>["setState"],
  get: StoreApi<T>["getState"],
  api: StoreApi<T>,
) => T;

// Define the log function with the correct types
export const log =
  <T extends { data: Record<string, unknown> }>(config: ConfigFn<T>) =>
  (
    set: StoreApi<T>["setState"],
    get: StoreApi<T>["getState"],
    api: StoreApi<T>,
  ) => {
    if (!isLocal || getPlatform() === "native") {
      return config(set, get, api);
    }

    return config(
      (...args) => {
        console.groupCollapsed(
          `State - ${Object.keys(args[0] || {})[0] || "Unknown state change"}`,
        );
        console.log("Applying new state", args[0]);
        set(...args);
        console.log("New State", get().data);
        console.groupEnd();
      },
      get,
      api,
    );
  };

import { Card, CardContent, Switch, View } from "@/components/ui";
import { CreditCard } from "@/components/shared/icons";
import { useAuthState } from "@/store/auth";
import { useState } from "react";
import { useUpdateClientPreferencesStore } from "@/store/profile/updateClientPreferences";
import { getErrorMessage } from "@/lib/helpers/string";
import { showToast } from "@/components/ui/toast/show-toast";
import { useRoute } from "../../router";
import { Typography } from "../../typography";
import { useClientStore } from "@/store/auth/client";

export function AutoPayWidget() {
  const {
    data: {
      userInfo: { id, autoPay, paymentMethod },
    },
    setUserInfo,
  } = useAuthState();
  const { push } = useRoute();
  const { fetch: updateClientPreferences, loading } =
    useUpdateClientPreferencesStore();
  const { fetch: client, data } = useClientStore();

  const clientId = id;
  const [enableAutoPay, setEnableAutoPay] = useState(autoPay);

  const handleAutoPay = async (value: boolean) => {
    if (!paymentMethod) {
      push({ pageKey: "payment" });
      return;
    }

    setEnableAutoPay(value);
    try {
      const updatePreferences = await updateClientPreferences({
        requestPayload: {
          input: {
            clientId,
            preferences: { auto_pay: value },
          },
        },
      });

      if (updatePreferences.error) {
        throw new Error(updatePreferences.error);
      }

      client({ requestPayload: { id: clientId } });

      if (data) {
        setUserInfo(data?.userInfo);
      }
    } catch (updatePreferencesError) {
      const errMsg = getErrorMessage(
        updatePreferencesError,
        "Error updating client preferences",
      );
      showToast({ title: errMsg, type: "error" });
    }
  };

  return (
    <Card variant="info" className="w-full">
      <CardContent className="flex flex-col items-start gap-2 p-4 md:gap-3">
        <View className="flex w-full flex-row justify-between gap-2">
          <View className="flex flex-row items-center gap-2">
            <CreditCard weight="fill" className="text-primary" size={28} />
            <Typography variant="body-xl">Auto Pay</Typography>
          </View>
          <Switch
            checked={enableAutoPay}
            onCheckedChange={handleAutoPay}
            disabled={loading}
          />
        </View>
        <Typography
          variant={{ md: "body-lg", sm: "body-md" }}
          color="foreground-intermediate"
        >
          Enjoy hassle-free payments! Your card will be charged 14 days after
          each service is completed.
        </Typography>
      </CardContent>
    </Card>
  );
}

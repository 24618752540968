import { useParams } from "@/components/hooks/use-params";
import { useAuthState } from "@/store/auth";
import { useRoute } from "@/components/shared/router";
import type { ContactFormData } from "@/store/auth/forms/useContactForm";
import { useContactForm } from "@/store/auth/forms/useContactForm";
import { useUpdateContactStore } from "@/store/profile/updateContact";
import { useDeleteContactStore } from "@/store/profile/deleteContact";
import {
  getErrorMessage,
  getNationCode,
  getPhoneNumber,
} from "@/lib/helpers/string";
import { defaultNationCode } from "@/constants";
import { useEffect } from "react";
import { showToast } from "@/components/ui/toast/show-toast";
import { useClientStore } from "@/store/auth/client";
import type { Control } from "react-hook-form";
import { UpdateContactScreen } from "./update-contact-screen";

export type UpdateContactProps = {
  control: Control<ContactFormData>;
  onDeleteContact: () => void;
  deleteContactLoading: boolean;
  onSubmit?: () => void;
  updateContactLoading?: boolean;
  trigger(): void;
};

export const UpdateContact = () => {
  const { id } = useParams<{ id: string }>();
  const {
    data: { userInfo },
  } = useAuthState();
  const { push, pull } = useRoute();
  const contact = userInfo.contacts.find((i) => i.id === id);

  useEffect(() => {
    if (!contact) {
      push({
        pageKey: "contacts",
      });
    }
  }, [contact]);

  const currentValues: ContactFormData = {
    firstName: contact?.firstName ?? "",
    lastName: contact?.lastName ?? "",
    primary: contact?.primary ?? false,
    email: contact?.email.map((e) => ({ value: e })) ?? [{ value: "" }],
    phoneNumber: contact?.phoneNumber.map((p) => ({
      value: getPhoneNumber(p),
      nationCode: getNationCode(p),
    })) ?? [{ value: "", nationCode: defaultNationCode }],
  };

  const { control, handleSubmit, trigger } = useContactForm(currentValues);

  const { fetch: updateContact, loading: updateContactLoading } =
    useUpdateContactStore();
  const { fetch: deleteContact, loading: deleteContactLoading } =
    useDeleteContactStore();
  const { fetch: client } = useClientStore();

  const onSubmit = handleSubmit(async (data: ContactFormData) => {
    try {
      if (!id) return;

      const formattedPhoneNumbers = data.phoneNumber?.map((phone) => {
        const [, countryCode] = phone.nationCode.split("/");
        return `+${countryCode}${phone.value}`;
      });

      const formattedEmails = data.email?.map((email) => email.value);

      const res = await updateContact(
        {
          requestPayload: {
            input: {
              id: id,
              firstName: data.firstName,
              lastName: data.lastName,
              email: formattedEmails,
              phoneNumber: formattedPhoneNumbers,
              primary: data.primary ?? false,
            },
          },
        },
        {
          selfHandleError: true,
        },
      );

      if (res.data?.contact) {
        if (res.data.contact.primary) {
          const existingContacts = userInfo.contacts || [];
          const lastPrimaryContact = existingContacts.find(
            (cont) => cont.primary,
          );

          try {
            if (lastPrimaryContact?.id) {
              const lastPrimaryRes = await updateContact(
                {
                  requestPayload: {
                    input: {
                      id: lastPrimaryContact.id,
                      firstName: lastPrimaryContact.firstName,
                      lastName: lastPrimaryContact.lastName,
                      email: lastPrimaryContact.email,
                      phoneNumber: lastPrimaryContact.phoneNumber,
                      primary: false,
                    },
                  },
                },
                {
                  selfHandleError: true,
                },
              );
              if (lastPrimaryRes.error)
                throw new Error(
                  `Last primary contact error: ${lastPrimaryRes.error}`,
                );
            }

            //TODO: send error when fail to get primary to sentry later

            showToast({
              type: "success",
              title: "Success update contact",
            });
            if (res.error) throw new Error(res.error);
          } catch (error) {
            const errMsg = getErrorMessage(error, "Error update contacts");
            showToast({
              title: errMsg,
              type: "error",
            });
          }
        }

        client({
          requestPayload: {
            id: userInfo.id,
          },
        });

        pull();
        showToast({
          title: "Success update contact",
          type: "success",
        });
      }
      if (res.error) throw new Error(res.error);
    } catch (error) {
      const errMsg = getErrorMessage(error, "Error update contact");
      showToast({
        title: errMsg,
        type: "error",
      });
    }
  });

  const onDeleteContact = async () => {
    try {
      if (!id) return;

      const res = await deleteContact(
        {
          requestPayload: {
            input: {
              id: id,
            },
          },
        },
        {
          selfHandleError: true,
        },
      );

      if (res.data?.result) {
        push({
          pageKey: "contacts",
        });
        showToast({
          title: "Success delete contact",
          type: "success",
        });
      }
      if (res.error) throw new Error(res.error);
    } catch (error) {
      const errMsg = getErrorMessage(error, "Error delete address");
      showToast({
        title: errMsg,
        type: "error",
      });
    }
  };

  return (
    <UpdateContactScreen
      trigger={trigger}
      control={control}
      onSubmit={onSubmit}
      deleteContactLoading={deleteContactLoading && updateContactLoading}
      onDeleteContact={onDeleteContact}
      updateContactLoading={updateContactLoading}
    />
  );
};

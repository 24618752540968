import { View, Label, Input } from "@/components/ui";
import type {
  Control,
  FieldErrors,
  FieldValues,
  Path,
  PathValue,
} from "react-hook-form";
import { Controller } from "react-hook-form";
import { cn, safeGet } from "@/lib/utils";
import { PhoneNumberSelectGroup } from "./select-group";

import React, { useId } from "react";
import { FormControlError } from "../form-control-error";
import { useIntl } from "react-intl";

export type PhoneNumberSelectGroupProps = {
  onChange: (code: string) => void;
  trigger: () => void;
  value?: string | undefined;
  disabled?: boolean | undefined;
  nationShort: string;
  nationCode: string;
};

interface PhoneNumberInputProps<T extends FieldValues> {
  control: Control<T>;
  errors: FieldErrors<T>;
  phoneNumberName?: Path<T>;
  nationCodeName?: Path<T>;
  label?: string;
  rightRender?: React.ReactElement;
  className?: string;
  trigger(): void;
  disabled?: boolean;
}

export const PhoneNumberInput = <T extends FieldValues>({
  control,
  errors,
  //to set default when not define the phoneNumber and nationCode
  phoneNumberName = "phoneNumber" as Path<T>,
  nationCodeName = "nationCode" as Path<T>,
  label = "Phone Number",
  rightRender,
  className,
  trigger,
  disabled,
}: PhoneNumberInputProps<T>) => {
  const id = useId();
  const intl = useIntl();
  return (
    <View className={(cn("flex flex-col web:flex-1"), className)}>
      {label && (
        <Label htmlFor={id} className="block pb-1 text-sm">
          {label}
        </Label>
      )}
      <View className="flex flex-row gap-2">
        <View
          className={cn(
            "ease flex flex-1 flex-row overflow-hidden rounded-md border text-secondary-text transition",
            safeGet(errors, phoneNumberName)
              ? "border-destructive"
              : "border-secondary-border",
          )}
        >
          <Controller<T>
            control={control}
            name={nationCodeName}
            disabled={disabled}
            defaultValue={"SG/65" as PathValue<T, Path<T>>}
            render={({ field: { onChange, value } }) => {
              const nationShort = (value as string).split("/")[0];
              const nationCode = (value as string).split("/")[1];

              return (
                <PhoneNumberSelectGroup
                  onChange={onChange}
                  trigger={trigger}
                  nationShort={nationShort}
                  nationCode={nationCode}
                />
              );
            }}
          />

          <Controller<T>
            control={control}
            name={phoneNumberName}
            defaultValue={"" as PathValue<T, Path<T>>}
            disabled={disabled}
            render={({ field: { onChange, value, onBlur } }) => (
              <Input
                value={value}
                disabled={disabled}
                onBlur={onBlur}
                onChangeText={(text) => onChange(text.replace(/[^0-9]/g, ""))}
                className="w-full border-0"
                inputMode="numeric"
                type="tel"
                placeholder={intl.formatMessage({
                  defaultMessage: "Phone Number",
                  id: "phone-number",
                })}
                id={id}
              />
            )}
          />
        </View>
        {rightRender}
      </View>

      <FormControlError
        error={safeGet(errors, phoneNumberName)?.message as string}
      />
    </View>
  );
};

import { View } from "@/components/ui";
import { ImageDetail } from "./ImageDetail";
export function ServiceDetailSection({
  detailImages,
}: {
  detailImages: string[];
}) {
  return (
    <View className="flex flex-col gap-2">
      <View className="service-detail-contents">
        {detailImages.map((img) => (
          <ImageDetail
            width="100%"
            key={img}
            maxHeight={1000}
            className="w-full"
            src={img}
          />
        ))}
      </View>
    </View>
  );
}

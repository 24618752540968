import type { PackageDetailsFilter } from "@/__generated__/graphql";
import {
  PackageDepartmentEnum,
  PackageRecurrenceEnum,
  ServiceTypeEnum,
} from "@/__generated__/graphql";
import type {
  BookingInfoFormData,
  BookingInfoFormReturn,
} from "@/containers/booking-info/useBookingInfoForm";
import type {
  AppliedPromo,
  ServiceDetail,
  ServiceReviews,
} from "@/types/booking";
import { create } from "zustand";
import type { PastWorker, TimeSlot } from "@/types/booking";
import type { Address } from "@/types/users";
import type {
  PackageDetailFragmentFragment,
  ReservationDetailFragmentFragment,
} from "@/__generated__/graphql";
import { addDays } from "date-fns";
import ServiceDetailData from "@/containers/service-detail/service-detail.json";
import type { ServiceDetailKey } from "@/constants/booking";
import { defaultServiceReviews } from "@/constants/booking";
import { log } from "@/lib/store-middleware";
import { PackageFrequencyGroupEnum } from "@/types/booking";

export function getRebookAdHocState(duration: number) {
  return {
    currentPath: "/booking/home-cleaning/select-slot",
    department: PackageDepartmentEnum.HomeCleaning,
    selectedPackageIds: [],
    selectedAddress: null,
    selectedWorkers: [],
    serviceReviews: defaultServiceReviews,
    packageDetailsFilter: {
      duration,
      serviceTypes: [ServiceTypeEnum.HomeCleaning],
      repeatEvery: [PackageRecurrenceEnum.AdHoc],
      repeatEveryTimes: 0,
      frontEndFrequency: PackageFrequencyGroupEnum.AD_HOC,
    },
    startDate: new Date(),
    endDate: addDays(new Date(), 6),
    isRebook: true,
  };
}

export const defaultServiceDetails = {
  title: "",
  ratingTotal: 0,
  bookingTotal: 0,
  descriptions: [],
  image: "",
  detailImages: [],
  faqs: [],
  rateFrom: 0,
  rateTo: 0,
  averageRating: 0,
  startPrice: 0,
  guarantee: true,
};

export type AddToCalendarProps = {
  title: string;
  description: string;
  startTime: string;
  endTime: string;
  location: string;
};

type BookingState = {
  // state "department" is more frequently used so move it to out.
  packageDetailsFilter: Omit<PackageDetailsFilter, "department"> & {
    frontEndFrequency?: PackageFrequencyGroupEnum;
  };
  // general
  department: PackageDepartmentEnum;
  serviceDetails: Record<ServiceDetailKey, ServiceDetail>;
  serviceReviews: ServiceReviews;
  currentPath: string;
  isRebook: boolean;
  // aircon Booking
  airconSalesModalOpen: boolean;
  // select slot
  startDate: Date;
  endDate: Date;
  timeSlots: Record<string, TimeSlot[]> | null;
  selectedSlot: TimeSlot | null;
  reservationTimeStamp: number;
  selectedWorkers: PastWorker[];
  selectedAddress: Address | null;
  packageDetails: PackageDetailFragmentFragment[];
  selectedPackageIds: string[];
  reservations: ReservationDetailFragmentFragment[];
  //   booking info
  bookingInfoFormReturn: BookingInfoFormReturn | null;
  bookingInfo: BookingInfoFormData | null;
  promo: AppliedPromo | null;
  // confirm
  confirmedWorker: {
    id: string;
    firstName: string;
    lastName: string;
    avatarUrl?: string | null;
    workerRating: number;
  } | null;
  //   verticals
  homeBeautyFreeGelServiceIds: string[]; // to identify services with Free Gel Removal Checked
  homeCleaningDurationModalOpen: boolean;
  homeBeautyPackagesLoading: boolean;
  chooseHBPackageModalOpen: boolean;
  slotExpiredModalOpen: boolean;
  // add to calendar
  addToCalendarModalOpen: boolean;
  eventAdded: string;
  // indicate booking is complete and reset state
  completedBooking: boolean;
};

const initialState: BookingState = {
  serviceReviews: defaultServiceReviews,
  packageDetailsFilter: {},
  serviceDetails: ServiceDetailData,
  // general
  department: PackageDepartmentEnum.HomeCleaning,
  currentPath: "",
  slotExpiredModalOpen: false,
  homeBeautyPackagesLoading: false,
  isRebook: false,
  // aircon Booking
  airconSalesModalOpen: false,
  // select slot
  timeSlots: null,
  selectedSlot: null,
  selectedAddress: null,
  startDate: new Date(),
  endDate: addDays(new Date(), 6),
  reservationTimeStamp: 0,
  selectedWorkers: [],
  packageDetails: [],
  selectedPackageIds: [],
  reservations: [],
  // confirm
  confirmedWorker: null,
  // booking info
  promo: null,
  bookingInfoFormReturn: null,
  bookingInfo: null,
  // home-cleaning
  homeCleaningDurationModalOpen: false,
  chooseHBPackageModalOpen: false,
  homeBeautyFreeGelServiceIds: [],
  // add to calendar
  addToCalendarModalOpen: false,
  eventAdded: "",
  completedBooking: false,
};

export const useBookingState = create(
  log<{
    data: BookingState;
    setBookingState(params: Partial<BookingState>): void;
    resetBookingState(): void;
  }>((set, get) => ({
    data: initialState,
    setBookingState: (params: Partial<BookingState>) => {
      set({ data: { ...get().data, ...params } });
    },
    resetBookingState: () => {
      set({ data: initialState });
    },
  })),
);

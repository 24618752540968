import { View } from "@/components/ui";
import { ArrowLeft } from "@/components/shared/icons";
import { Link } from "@/components/shared/link";
import { InfoCard } from "@/components/shared/info-card";
import { Typography } from "@/components/shared/typography";
import { AddCreditCardForm } from "../add-credit-card-form";
import type { AddCreditCardScreenProps } from "..";
import { AddCreditButtonsAction } from "../add-credit-buttons-action";

export const AddCreditCardScreen = ({
  loading,
  onConfirm,
  onUpdateCard,
  onCancelUpdateCard,
  onConfirmUpdateCard,
  isUpdateCard,
  creditCardFormTitle,
  control,
  paymentMethod,
  infoCardDesc,
}: AddCreditCardScreenProps) => {
  return (
    <>
      <View className="z-10 h-full flex-1 p-4 md:p-0">
        <View className="mb-4 border-b md:mb-8 md:pb-4">
          <View className="flex flex-row items-center gap-2 p-4 md:p-0">
            <Link href="/profile">
              <ArrowLeft className="visible size-5 md:hidden" />
            </Link>
            <Typography variant={{ md: "label-2xl", sm: "label-xl" }}>
              {creditCardFormTitle}
            </Typography>
          </View>
        </View>

        <View className="flex flex-col items-center gap-4">
          <AddCreditCardForm control={control} loading={loading} />
          <InfoCard title="Info" description={infoCardDesc} />
        </View>

        <AddCreditButtonsAction
          onConfirmUpdateCard={onConfirmUpdateCard}
          onCancelUpdate={onCancelUpdateCard}
          isUpdateCard={isUpdateCard}
          hasPayment={!!paymentMethod}
          loading={loading}
          onConfirm={onConfirm}
          onRemoveToUpdate={onUpdateCard}
        />
      </View>
    </>
  );
};

import { useWindowDimensions } from "@/components/hooks/use-window-dimension";
import { useEffect, useState } from "react";
import type { InvoiceDetail as InvoiceFragment } from "@/types/invoice";
import { PaymentMethodScreenModal } from "@/types/invoice";
import type { LineItemDetail } from "@/types/line-item";
import { useInvoiceDetailStore } from "@/store/invoices/invoiceDetail";
import { getInvoiceLineItems } from "@/lib/line-items/helpers";
import { useRoute } from "@/components/shared/router";
import { useScrollRefresh } from "@/components/shared/scroll-content/scroll-refresh-context";
import { useInvoiceState } from "@/store/invoices";
import { InvoiceDetailScreen } from "./invoice-detail-screen";

export type InvoiceDetailData = Omit<
  InvoiceFragment,
  | "dueDate"
  | "issueDate"
  | "fromDate"
  | "toDate"
  | "lineItems"
  | "clientPaymentIndicated"
> & {
  balance: number;
  dueDate: Date;
  issueDate: Date;
  fromDate: Date;
  toDate: Date;
  lineItems: LineItemDetail[];
  clientPaymentIndicated: boolean;
};

export function getInvoiceDetailData(
  invoice: InvoiceFragment,
): InvoiceDetailData {
  return {
    id: invoice.id,
    amount: invoice.amount,
    balance: invoice.totalAmount - invoice.paidAmount,
    paidAmount: invoice.paidAmount,
    baseAmount: invoice.baseAmount,
    paymentStatus: invoice.paymentStatus,
    fromDate: new Date(invoice.fromDate),
    toDate: new Date(invoice.toDate ?? 0),
    issueDate: new Date(invoice.issueDate ?? 0),
    dueDate: new Date(invoice.dueDate ?? 0),
    status: invoice.status,
    pdfUrl: invoice.pdfUrl,
    totalAmount: invoice.totalAmount,
    pdfReceiptUrl: invoice.pdfReceiptUrl,
    clientPaymentIndicated: Boolean(invoice.clientPaymentIndicated),
    appliedVoucher: invoice.appliedVoucher ?? null,
    lineItems: invoice.lineItems?.map(getInvoiceLineItems) ?? [],
  };
}

export type InvoiceDetailProps = {
  invoiceId: string;
  invoice: InvoiceDetailData;
  showApplyVoucherModal: boolean;
  onCloseVoucherModal: () => void;
  handlePay: () => void;
  handleVoucher: () => void;
  selectPayment: boolean;
  loading: boolean;
};

export function InvoiceDetail({ invoiceId }: { invoiceId: string }) {
  const { isMobile } = useWindowDimensions();
  const { refreshing, setRefreshing } = useScrollRefresh();
  const { push } = useRoute();
  const selectPayment = useInvoiceState(
    (state) => state.data.selectPaymentScreenModal,
  );

  const { setInvoiceId, showPaymentMethodModal } = useInvoiceState();
  const { fetch, data, loading, refetch, error } = useInvoiceDetailStore();
  const [showApplyVoucherModal, setShowApplyVoucherModal] = useState(false);
  const invoice = data as InvoiceDetailData;

  useEffect(() => {
    if (refreshing) {
      void refetch();
      setRefreshing(false);
    }
  }, [refreshing]);

  useEffect(() => {
    if (error) {
      push({
        pageKey: "invoices",
      });
    }
  }, [error]);

  useEffect(() => {
    void fetch({
      requestPayload: {
        id: invoiceId,
      },
    });
  }, [invoiceId]);

  const handleVoucher = () => {
    setShowApplyVoucherModal(true);
  };
  const onCloseVoucherModal = () => {
    setShowApplyVoucherModal(false);
  };

  const handlePay = () => {
    setInvoiceId(invoiceId);
    showPaymentMethodModal(PaymentMethodScreenModal.SELECT_PAYMENT);

    if (isMobile) {
      push({ pageKey: "selectPayment" });
    }
  };

  return (
    <InvoiceDetailScreen
      invoiceId={invoiceId}
      invoice={invoice}
      showApplyVoucherModal={showApplyVoucherModal}
      onCloseVoucherModal={onCloseVoucherModal}
      handlePay={handlePay}
      handleVoucher={handleVoucher}
      selectPayment={selectPayment}
      loading={loading}
    />
  );
}

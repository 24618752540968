import {
  ClientAccountTypeEnum,
  SignInProviderEnum,
  UserEnum,
} from "@/__generated__/graphql";
import { setUnitNumber } from "@/lib/helpers/string";
import { useAuthState } from "@/store/auth";
import { useSignUpStore } from "@/store/auth/signUp";
import { storage } from "@/lib/storage";
import { useAuth } from "@/components/shared/auth-provider";
import { sendAnalyticData } from "@/lib/monitoring/analytics";

export const useSignUp = () => {
  const { login } = useAuth();
  const {
    data: { signUpFormReturn, addressFormReturn },
    showModal,
    setUserInfo,
  } = useAuthState();

  const {
    loading: signUpLoading,
    fetch: signUp,
    error: signUpError,
  } = useSignUpStore();

  const onSignUp = () => {
    if (!signUpFormReturn || !addressFormReturn) return;

    const { email, firstName, lastName, phoneNumber, nationCode } =
      signUpFormReturn.getValues();
    const { postalCode, fullAddress, primary, apartmentNumber, unitFloor } =
      addressFormReturn.getValues();

    const getUnitFloor = setUnitNumber("unitFloor", unitFloor);
    const getApartmentNumber = setUnitNumber(
      "apartmentNumber",
      apartmentNumber,
    );

    signUp(
      {
        requestPayload: {
          input: {
            accountType: ClientAccountTypeEnum.Individual,
            email,
            firstName,
            lastName,
            phoneNumber: `+${nationCode.split("/")[1]}${phoneNumber}`,
            primaryAddress: {
              fullAddress: fullAddress,
              postalCode,
              unitNumber: `${getUnitFloor}-${getApartmentNumber}`,
              primary: primary,
            },
            primaryContact: {
              firstName,
              lastName,
              email: [email],
              phoneNumber: [`+${nationCode.split("/")[1]}${phoneNumber}`],
            },
            provider: SignInProviderEnum.PhoneNumber,
            refreshToken: "",
            type: UserEnum.Client,
            token: "",
            utm: {
              source: null,
              medium: null,
              campaign: null,
              term: null,
              content: null,
            },
          },
        },
      },
      { selfHandleError: true },
    ).then(({ data }) => {
      if (data?.userInfo) {
        setUserInfo(data.userInfo);
        login(data.loginArgs);
        sendAnalyticData({
          name: "user_sign_up",
          values: {
            method: SignInProviderEnum.PhoneNumber,
          },
        });
        storage.setItem("ONBOARDING", "true");
        showModal();
      }
    });
  };

  return {
    onSignUp,
    signUpError,
    signUpLoading,
  };
};

import {
  ServiceMassageIcon,
  ServiceAcIcon,
  ServiceHomeCleaningIcon,
  ServiceManicureIcon,
  ServiceMattressIcon,
  ServiceRugCleaningIcon,
  ServiceSofaIcon,
  ServiceHandimanIcon,
  ServiceCarServiceIcon,
  ServicePetGroomingIcon,
  ServiceBabySitterIcon,
  ServiceCurtainIcon,
  ServiceEldercare,
  ServiceMaidAgencyIcon,
  ServiceElectricianIcon,
  ServicePetSittingIcon,
  ServicePlumbingIcon,
  ServiceDeepCleaningIcon,
} from "@/components/shared/icons/generated";
import { app } from "@/config/env";
import { PosthogAnalytics } from "@/lib/monitoring/posthog";
import type { BetaServiceName, ServiceName } from "@/types/service";
import type { IntlShape } from "react-intl";

export const ServiceCategoryMap = {
  PopularService: "Popular Services",
  SpecializedCleaning: "Specialized Cleaning",
  Handyman: "Handyman",
  PetCare: "Pet Care",
  OtherServices: "Other Services",
};

export type ServiceCategory = typeof ServiceCategoryMap;

export type ServiceItem = {
  title: string;
  name: ServiceName;
  icon: JSX.Element;
  categories: Array<keyof ServiceCategory>;
};

export type BetaServiceItem = {
  title: string;
  name: BetaServiceName;
  icon: JSX.Element;
  link: string;
  categories: Array<keyof ServiceCategory>;
};

export function betaServiceItems(t: IntlShape): BetaServiceItem[] {
  const { distinct_id, session_id } = PosthogAnalytics.getIdentifiers();
  let posthogUserTrackingQuery = "";
  if (distinct_id && session_id) {
    posthogUserTrackingQuery = `?distinct_id=${distinct_id}&session_id=${session_id}`;
  }

  const landingPageDomain = app.LANDING_PAGE_DOMAIN;

  return [
    {
      title: t.formatMessage({
        defaultMessage: "Deep Cleaning",
        id: "deep-cleaning",
      }),
      name: "deep-cleaning",
      icon: <ServiceDeepCleaningIcon className="size-10 color-primary" />,
      link: `${landingPageDomain}/services/deep-cleaning${posthogUserTrackingQuery}`,
      categories: ["SpecializedCleaning"],
    },
    {
      title: t.formatMessage({
        defaultMessage: "Handyman",
        id: "handyman",
      }),
      name: "handyman",
      icon: <ServiceHandimanIcon className="size-10 color-primary" />,
      link: `${landingPageDomain}/services/handyman${posthogUserTrackingQuery}`,
      categories: ["Handyman"],
    },
    {
      title: t.formatMessage({
        defaultMessage: "Home Massage",
        id: "home-massage",
      }),
      name: "home-massage",
      icon: <ServiceMassageIcon className="size-10 color-primary" />,
      link: `${landingPageDomain}/services/home-massage${posthogUserTrackingQuery}`,
      categories: ["OtherServices"],
    },
    {
      title: t.formatMessage({
        defaultMessage: "Babysitter",
        id: "babysitter",
      }),
      name: "babysitter",
      icon: <ServiceBabySitterIcon className="size-10 color-primary" />,
      link: `${landingPageDomain}/services/babysitter${posthogUserTrackingQuery}`,
      categories: ["OtherServices"],
    },
    {
      title: t.formatMessage({
        defaultMessage: "Car Wash Services",
        id: "car-wash",
      }),
      name: "car-wash",
      icon: <ServiceCarServiceIcon className="size-10 color-primary" />,
      link: `${landingPageDomain}/services/car-wash${posthogUserTrackingQuery}`,
      categories: ["OtherServices"],
    },
    {
      title: t.formatMessage({
        defaultMessage: "Curtain Cleaning",
        id: "curtain-cleaning",
      }),
      name: "curtain-cleaning",
      icon: <ServiceCurtainIcon className="size-10 color-primary" />,
      link: `${landingPageDomain}/services/curtain-cleaning${posthogUserTrackingQuery}`,
      categories: ["SpecializedCleaning"],
    },
    {
      title: t.formatMessage({
        defaultMessage: "Plumbing",
        id: "plumbing",
      }),
      name: "plumbing",
      icon: <ServicePlumbingIcon className="size-10 color-primary" />,
      link: `${landingPageDomain}/services/plumbing${posthogUserTrackingQuery}`,
      categories: ["Handyman"],
    },
    {
      title: t.formatMessage({
        defaultMessage: "Electrician",
        id: "electrician",
      }),
      name: "electrician",
      icon: <ServiceElectricianIcon className="size-10 color-primary" />,
      link: `${landingPageDomain}/services/electrician${posthogUserTrackingQuery}`,
      categories: ["Handyman"],
    },
    {
      title: t.formatMessage({
        defaultMessage: "Pet Grooming",
        id: "pet-grooming",
      }),
      name: "pet-grooming",
      icon: <ServicePetGroomingIcon className="size-10 color-primary" />,
      link: `${landingPageDomain}/services/pet-grooming${posthogUserTrackingQuery}`,
      categories: ["PetCare"],
    },
    {
      title: t.formatMessage({
        defaultMessage: "Pet Sitting",
        id: "pet-sitting",
      }),
      name: "pet-sitting",
      icon: <ServicePetSittingIcon className="size-10 color-primary" />,
      link: `${landingPageDomain}/services/pet-sitting${posthogUserTrackingQuery}`,
      categories: ["PetCare"],
    },
    {
      title: t.formatMessage({
        defaultMessage: "Eldercare",
        id: "elderly-care",
      }),
      name: "elderly-care",
      icon: <ServiceEldercare className="size-10 color-primary" />,
      link: `${landingPageDomain}/services/elderly-care${posthogUserTrackingQuery}`,
      categories: ["OtherServices"],
    },
    {
      title: t.formatMessage({
        defaultMessage: "Maid Agency",
        id: "maid-agency",
      }),
      name: "maid-agency",
      icon: <ServiceMaidAgencyIcon className="size-10 color-primary" />,
      link: `${landingPageDomain}/services/maid-agency${posthogUserTrackingQuery}`,
      categories: ["OtherServices"],
    },
  ];
}

export function otherServiceItems(t: IntlShape): ServiceItem[] {
  return [
    {
      title: t.formatMessage({
        defaultMessage: "Sofa Cleaning",
        id: "sofa-cleaning",
      }),
      name: "sofa-cleaning",
      icon: <ServiceSofaIcon className="size-10 color-primary" />,
      categories: ["SpecializedCleaning"],
    },
    {
      title: t.formatMessage({
        defaultMessage: "Carpet Cleaning",
        id: "carpet-cleaning",
      }),
      name: "rug-cleaning",
      icon: <ServiceRugCleaningIcon className="size-10 color-primary" />,
      categories: ["SpecializedCleaning"],
    },
  ];
}

export function popularServiceItems(t: IntlShape): ServiceItem[] {
  return [
    {
      title: t.formatMessage({
        defaultMessage: "Home Cleaning",
        id: "home-cleaning",
      }),
      name: "home-cleaning",
      icon: <ServiceHomeCleaningIcon className="size-10 color-primary" />,
      categories: ["PopularService"],
    },
    {
      title: t.formatMessage({
        defaultMessage: "Aircon Service",
        id: "aircon-service",
      }),
      name: "aircon-service",
      icon: <ServiceAcIcon className="size-10 color-primary" />,
      categories: ["PopularService"],
    },
    {
      title: t.formatMessage({
        defaultMessage: "Mattress Cleaning",
        id: "mattress-cleaning",
      }),
      name: "mattress-cleaning",
      icon: <ServiceMattressIcon className="size-10 color-primary" />,
      categories: ["PopularService", "SpecializedCleaning"],
    },
    {
      title: t.formatMessage({
        defaultMessage: "Home Beauty",
        id: "home-beauty",
      }),
      name: "home-beauty",
      icon: <ServiceManicureIcon className="size-10 color-primary" />,
      categories: ["PopularService"],
    },
  ];
}

import { View, Image, Skeleton } from "@/components/ui";
import type { ServiceName } from "@/types/service";
import { Column, Container } from "@/components/ui/layout";
import { ServiceFaqSection } from "./service-faq-section";
import { ServiceHeroSection } from "./service-hero-section";
import { ServiceDetailSection } from "./service-detail-section";
import { CustomerReviews } from "./customer-reviews";
import { useWindowDimensions } from "@/components/hooks/use-window-dimension";
import { SectionWrapper } from "@/components/shared/sticky-scroll-navbar";
import { useBookingRouterInterceptor } from "@/components/hooks/use-booking-route";
import { getPlatform, safeGet } from "@/lib/utils";
import { useEffect, useMemo } from "react";
import {
  defaultServiceDetails,
  useBookingState,
} from "@/store/booking/useBookingState";
import type { ServiceDetail as IServiceDetail } from "@/types/booking";
import { ServiceDetailNavigation } from "./service-detail-navigation";
import { getDepartment, getServiceUnit } from "@/lib/booking-lib";
import { useClientReviewsStore } from "@/store/booking/clientReviews";
import { formatDate } from "@/lib/helpers/date";
import { addYears } from "date-fns";
import type { ServiceDetailKey } from "@/constants/booking";
import { defaultServiceReviews, serviceDetailCover } from "@/constants/booking";
import { ChooseHBPackageModal } from "@/components/shared/booking/choose-package-modal";
import { ChatWithSales } from "@/components/shared/chat-with-sales";
import { IfElse } from "@/components/shared/if-else";
import {
  PackageDepartmentEnum,
  PackageDetailSortByEnum,
  PackageRecurrenceEnum,
  ServiceTypeEnum,
  SortEnum,
} from "@/__generated__/graphql";
import { useAllClientPackageDetailsStore } from "@/store/booking/allClientPackageDetails";
import { GoogleReviews } from "./google-reviews";
import { Typography } from "@/components/shared/typography";
import { formatPrice } from "@/lib/helpers/number";
import { IconButton } from "@/components/ui/icon-button";
import { useRoute } from "@/components/shared/router";

export function ServiceDetail({ name }: { name: ServiceName }) {
  const { mediaQuery, isMobile } = useWindowDimensions();
  const { fetch: getReviews, loading } = useClientReviewsStore();
  const platform = getPlatform();
  const { pull } = useRoute();
  useBookingRouterInterceptor();
  const {
    data: { serviceDetails, packageDetailsFilter, serviceReviews },
    setBookingState,
  } = useBookingState();
  const { fetch: getPackages } = useAllClientPackageDetailsStore();

  const detailKey = (
    name === "aircon-service"
      ? `${name}-${packageDetailsFilter?.serviceTypes?.[0]}`
      : name
  ) as ServiceDetailKey;

  const pageServiceDetails = useMemo(() => {
    return safeGet(
      serviceDetails,
      detailKey,
      defaultServiceDetails,
    ) as IServiceDetail;
  }, [detailKey]);

  const initialHomeBeauty = () => {
    setBookingState({
      isRebook: false,
      department: PackageDepartmentEnum.HomeBeauty,
      homeBeautyPackagesLoading: true,
      packageDetailsFilter: {
        serviceTypes: [ServiceTypeEnum.Combo],
        repeatEvery: [PackageRecurrenceEnum.AdHoc],
      },
    });

    getPackages({
      requestPayload: {
        filters: {
          active: true,
          sort: [SortEnum.Asc],
          sortBy: [PackageDetailSortByEnum.Duration],
          serviceTypes: [
            ServiceTypeEnum.Combo,
            ServiceTypeEnum.Manicure,
            ServiceTypeEnum.Pedicure,
            ServiceTypeEnum.AddOn,
            ServiceTypeEnum.Upgrade,
            ServiceTypeEnum.Discount,
          ],
          department: PackageDepartmentEnum.HomeBeauty,
        },
      },
    })
      .then((res) => {
        if (!res.data || res.error) {
          return;
        }
        setBookingState({
          packageDetails: res.data,
        });
      })
      .finally(() => {
        setBookingState({
          homeBeautyPackagesLoading: false,
        });
      });
  };

  const { title, bookingTotal, descriptions, detailImages, faqs, startPrice } =
    pageServiceDetails;

  useEffect(() => {
    setBookingState({
      department: getDepartment(name),
      selectedPackageIds: [],
      selectedAddress: null,
      selectedWorkers: [],
      serviceReviews: defaultServiceReviews,
      isRebook: false,
    });

    getReviews({
      requestPayload: {
        filters: {
          startDate: formatDate(addYears(new Date(), -1)),
          endDate: formatDate(new Date()),
          department: getDepartment(name),
          limit: 30,
        },
      },
    });

    if (name === "home-beauty") {
      initialHomeBeauty();
    }
  }, []);

  const getServiceDetailCoverImage = (detailKey: ServiceDetailKey) => {
    return serviceDetailCover[detailKey] ?? serviceDetailCover["home-cleaning"];
  };

  return (
    <View className="py-0 md:py-6">
      <Container>
        <SectionWrapper keyName="service" className="relative">
          <Image
            src={getServiceDetailCoverImage(detailKey)}
            className="relative h-[211px] w-full overflow-hidden rounded-none object-cover md:h-[531px] md:rounded-xl"
            height={isMobile ? 211 : 531}
          />

          <IconButton
            className="z-2 absolute left-5 top-5 bg-black/50 web:hidden"
            rounded="full"
            iconName="arrowLeft"
            color="white"
            onClick={pull}
          />
        </SectionWrapper>
      </Container>
      <Container>
        <Column desktop="8">
          <View className="flex flex-col gap-4">
            <ServiceHeroSection
              title={title}
              bookingTotal={bookingTotal}
              descriptions={descriptions}
            />

            {mediaQuery === "mobile" && (
              <SectionWrapper keyName="reviews">
                <IfElse
                  if={!loading}
                  else={
                    <View className="flex w-full flex-1 p-4">
                      <Skeleton className="h-[250px] w-full" />
                    </View>
                  }
                >
                  <IfElse
                    if={!!serviceReviews?.reviews?.length}
                    else={<GoogleReviews />}
                  >
                    <CustomerReviews
                      serviceReviews={serviceReviews}
                      key="customer-review"
                    />
                  </IfElse>
                </IfElse>
              </SectionWrapper>
            )}

            <SectionWrapper keyName="details">
              <ServiceDetailSection detailImages={detailImages} />
            </SectionWrapper>

            <SectionWrapper keyName="faqs">
              <ServiceFaqSection faqs={faqs} />
            </SectionWrapper>
          </View>
          <ChooseHBPackageModal />
        </Column>

        <Column desktop="4">
          <IfElse if={platform === "web"}>
            <View className="relative flex flex-col gap-4 py-4 md:sticky md:top-14">
              <View className="hidden flex-row items-center justify-between md:flex">
                <Typography color="foreground-intermediate" variant="body-lg">
                  Starting from
                </Typography>
                <Typography variant="h6">{`${formatPrice(startPrice)}/${getServiceUnit(name)}`}</Typography>
              </View>
              <ServiceDetailNavigation name={name} />
              <View className="hidden flex-col gap-4 md:flex">
                <IfElse
                  if={!loading}
                  else={<Skeleton className="h-[350px] w-full" />}
                >
                  <IfElse
                    if={!!serviceReviews?.reviews?.length}
                    else={<GoogleReviews />}
                  >
                    <CustomerReviews
                      serviceReviews={serviceReviews}
                      key="customer-review"
                    />
                  </IfElse>
                </IfElse>

                <ChatWithSales />
              </View>
            </View>
          </IfElse>
        </Column>
      </Container>
    </View>
  );
}

import {
  Avatar as AvatarUI,
  AvatarFallback,
  AvatarImage,
  View,
} from "@/components/ui";
import { cn } from "@/lib/utils";
import { UserProfileIcon } from "@/components/shared/icons";

export const WorkerAvatar = ({
  src = "",
  alt = "",
  className = "",
}: {
  src?: string;
  alt?: string;
  className?: string;
}) => {
  return (
    <AvatarUI
      alt={alt}
      className={cn("size-12 overflow-hidden rounded-full", className)}
    >
      <AvatarImage src={src} />
      <AvatarFallback>
        <View className="bg-background-intermediate">
          <UserProfileIcon className="size-12 color-foreground-intermediate" />
        </View>
      </AvatarFallback>
    </AvatarUI>
  );
};

import { cn } from "@/lib/utils";
import * as React from "react";

export interface TextProps extends React.HTMLAttributes<HTMLDivElement> {
  as?: React.ElementType;
  asChild?: boolean;
  htmlFor?: string;
  numberOfLines?: never;
}

export const Text = React.forwardRef<HTMLDivElement, TextProps>(
  (
    {
      htmlFor,
      as: Element = htmlFor ? "label" : "p",
      asChild: _asChild,
      numberOfLines,
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <Element
        {...props}
        className={cn(className, "line-clamp-[var(--lineClamp)]")}
        ref={ref}
        htmlFor={htmlFor}
        style={{ "--lineClamp": numberOfLines }}
      />
    );
  },
);

import AppStoreSocialBtn from "@/assets/icons/svgs/app-store-social-btn.svg";
import GooglePlaySocialBtn from "@/assets/icons/svgs/google-play-social-btn.svg";
import { ChatWithSalesBall } from "@/components/shared/chat-with-sales";
import { Divider } from "@/components/shared/divider";
import { Link } from "@/components/shared/link";
import { Typography } from "@/components/shared/typography";
import { Image, View } from "@/components/ui";
import { links } from "@/constants";
import { sendAnalyticData } from "@/lib/monitoring/analytics";
import { handleOpenLink } from "@/lib/platform";

export const DownloadAppPage = () => {
  const sendToRudderStack = async (href: string) => {
    await handleOpenLink(href);
    sendAnalyticData({
      name: "app_download_button_click",
      values: {
        button_location: href,
      },
    });
  };

  return (
    <View className="relative flex h-screen flex-col items-center justify-center gap-4 bg-primary">
      <Typography variant="h5" color="white">
        Download Our App!
      </Typography>
      <View className="flex gap-2">
        <View
          onClick={() => sendToRudderStack(links.appStoreClientApp)}
          className="cursor-pointer rounded-full border border-primary-border bg-primary-surface px-4 py-2"
        >
          <Image src={AppStoreSocialBtn} />
        </View>
        <View
          onClick={() => sendToRudderStack(links.googlePlayClientApp)}
          className="cursor-pointer rounded-full border border-primary-border bg-primary-surface px-4 py-2"
        >
          <Image src={GooglePlaySocialBtn} />
        </View>
      </View>
      <Divider />
      <Typography variant="label-md" color="white">
        Need a help?{" "}
        <Link target="_blank" href={links.helpCenter}>
          <Typography
            as="span"
            variant="label-md"
            color="white"
            className="underline"
          >
            Help Center
          </Typography>
        </Link>
      </Typography>
      <View className="fixed bottom-0 right-0">
        <ChatWithSalesBall />
      </View>
    </View>
  );
};

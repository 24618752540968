import { useBookingState } from "@/store/booking/useBookingState";
import { Dialog, DialogContent, Button } from "@/components/ui";
import { Typography } from "@/components/shared/typography";

export const SlotExpiredModal = () => {
  const {
    data: { slotExpiredModalOpen },
    setBookingState,
  } = useBookingState();
  const onCloseModal = () => {
    setBookingState({
      slotExpiredModalOpen: false,
    });
  };
  return (
    <Dialog open={slotExpiredModalOpen} onOpenChange={onCloseModal}>
      <DialogContent className="w-[343px]">
        <Typography variant="bold-xl">Your slot expired</Typography>
        <Typography
          variant="body-md"
          className="pb-4"
          color="foreground-intermediate"
        >
          Your selected slot already expired, please select slot again
        </Typography>

        <Button variant="primary" color="CTA" size="lg" onClick={onCloseModal}>
          Ok
        </Button>
      </DialogContent>
    </Dialog>
  );
};

import { View } from "@/components/ui";
import { Card, CardContent } from "@/components/ui/card";
import { SealPercent } from "@/components/shared/icons";
import { Typography } from "../typography";
import type { DiscountTypeEnum } from "@/containers/rewards/lib";
import { formatDateString } from "@/lib/helpers/date";
import { CheckCircle } from "@/components/shared/icons";
import { IfElse } from "../if-else";
import { Divider } from "../divider";
import { cn } from "@/lib/utils";

type Props = {
  expirationDate: string;
  label: string;
  discountValues: string;
  discountType: DiscountTypeEnum;
  selected?: boolean;
  onClick?: () => void;
};

const selectedColor = "border-primary-border bg-primary-surface text-sky-500";

export function VoucherCard({
  expirationDate,
  label,
  discountValues,
  onClick,
  selected = false,
  // discountType, // TODO: used to differentiate icons
}: Props) {
  return (
    <View className="relative overflow-hidden">
      <Card
        radius="xxl"
        variant={selected ? "info" : "default"}
        onClick={onClick}
      >
        <View
          className={cn(
            "absolute -left-1 bottom-5 z-10 size-4 -translate-x-2/4 -translate-y-2/4 rounded-e-full border border-border-high bg-background",
            selected && selectedColor,
          )}
        />
        <View
          className={cn(
            "absolute -right-1 bottom-5 z-10 size-4 -translate-y-2/4 translate-x-2/4 rounded-s-full border border-border-high bg-background",
            selected && selectedColor,
          )}
        />

        <CardContent className="flex flex-col gap-2 px-4 py-3">
          <View className="flex flex-row items-center">
            <SealPercent className="text-green-400" weight="fill" size="40" />

            <View className="flex-1 gap-1 pl-4">
              <View className="flex flex-row justify-between gap-2">
                <Typography color="success" variant="bold-lg">
                  {discountValues}
                </Typography>
                <IfElse if={selected}>
                  <CheckCircle weight="fill" className="size-5 text-primary" />
                </IfElse>
              </View>
              <Typography variant="body-md" color="secondary-text">
                {label}
              </Typography>
            </View>
          </View>
          <Divider dashed className={cn(selected && selectedColor)} />
          <View className="flex flex-row items-center justify-between">
            <Typography variant="body-md" color="secondary-text">
              Expiring: {formatDateString(expirationDate)}
            </Typography>
          </View>
        </CardContent>
      </Card>
    </View>
  );
}

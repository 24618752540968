import { View, Button, Image } from "@/components/ui";
import { Typography } from "@/components/shared/typography";
import { SealPercent } from "@/components/shared/icons";
import { useRoute } from "@/components/shared/router";
import { PromoImageChatSales } from "@/components/shared/promo-card";
import { ListHeading } from "@/components/shared/list-header";
import EmptyPromosAndRewards from "@/assets/images/empty-promos-and-rewards.png";
import { useIntl } from "react-intl";
import { IfElse } from "@/components/shared/if-else";

const promoData = [
  {
    link: "https://luce-production.sgp1.cdn.digitaloceanspaces.com/public/1-promo-aircon-2024.png",
    message:
      "Hi! I'd like to speak to sales about booking a new aircon service appointment and use the $15 off promotion through your web application. Could you please assist me with the details?",
  },
  {
    link: "https://luce-production.sgp1.cdn.digitaloceanspaces.com/public/2-promo-home-beauty-2024.png",
    message:
      "Hi! I'd like to speak to sales about booking a new home beauty appointment and use the $15 off promotion through your web application. Could you please assist me with the details?",
  },
  // TODO: we will hide this for release
  // {
  //   link: "https://luce-production.sgp1.cdn.digitaloceanspaces.com/public/3-promo-main-agency-2024.png",
  //   message:
  //     "Hi! I'm interested in learning more about your new full-time maid service. Could you please provide more details and how I can book it?",
  // },
];

export function Promos({ toggleHeader = true }: { toggleHeader?: boolean }) {
  const { push } = useRoute();
  const intl = useIntl();
  return (
    <View className="flex max-w-screen-lg flex-col gap-2">
      {toggleHeader && (
        <ListHeading
          title="Promos"
          icon={SealPercent}
          action={
            !!promoData.length && (
              <Button
                variant="tertiary"
                color="CTA2"
                onClick={() => {
                  push({ pageKey: "promos" });
                }}
                children={intl.formatMessage({
                  defaultMessage: "View All",
                  id: "view-all",
                })}
              />
            )
          }
        />
      )}

      <View className="promo-list-contents flex flex-col gap-4">
        <IfElse
          if={!!promoData.length}
          else={
            <View className="flex flex-col items-center">
              <Image src={EmptyPromosAndRewards} width={160} height={160} />
              <Typography variant="label-xl">No Promos Available</Typography>
              <Typography variant="body-lg">
                Come back later for more exciting promos!
              </Typography>
            </View>
          }
        >
          {promoData.map((promo) => (
            <PromoImageChatSales
              key={promo.link}
              imageUrl={promo.link}
              message={promo.message}
            />
          ))}
        </IfElse>
      </View>
    </View>
  );
}

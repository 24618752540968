import { View } from "@/components/ui";
import { useIntl } from "react-intl";
import { BottomDrawerModal } from "@/components/shared/bottom-drawer-modal";
import { Divider } from "@/components/shared/divider";
import { Typography } from "@/components/shared/typography";
import { AllServicesContent } from "./all-services-content";

export function AllServicesModal({
  modalOpen,
  handleOpenChange,
}: {
  modalOpen: boolean;
  handleOpenChange(newState: boolean): void;
}) {
  const intl = useIntl();
  return (
    <BottomDrawerModal open={modalOpen} onOpenChange={handleOpenChange}>
      <View>
        <Typography variant="label-2xl">
          {intl.formatMessage({
            defaultMessage: "All Luce Services",
            id: "all-luce-services",
          })}
        </Typography>
        <Divider className="my-4" />

        <AllServicesContent />
      </View>
    </BottomDrawerModal>
  );
}

import { IfElse } from "@/components/shared/if-else";
import { Typography } from "@/components/shared/typography";
import { View, Card, CardContent, Skeleton } from "@/components/ui";
import type { UserPaymentMethod } from "@/types/users";
import { useIntl } from "react-intl";

export const PaymentCardScreen = ({
  paymentCard,
  loading,
}: {
  paymentCard: UserPaymentMethod;
  loading: boolean;
}) => {
  const intl = useIntl();
  const creditCardNumber = ["****", "****", "****", paymentCard.last4];
  return (
    <View className="flex w-full flex-1 flex-col items-center gap-4">
      <IfElse
        if={!loading}
        else={
          <Skeleton className="h-[210px] w-full rounded-3xl md:max-w-[343px]" />
        }
      >
        <Card className="w-full border border-button-cta2 bg-button-cta2 bg-opacity-50 bg-[url('@/assets/images/payment-luce-icon.png')] bg-[length:162px_162px] bg-center bg-repeat-x md:max-w-[343px]">
          <CardContent className="flex flex-col gap-12 rounded-2xl p-4">
            <Typography variant="body-xl" color="white">
              {intl.formatMessage({
                defaultMessage: "Credit card",
                id: "credit-card",
              })}
            </Typography>
            <View className="flex w-full flex-1 flex-row justify-between">
              {creditCardNumber.map((txt, index) => (
                <Typography key={index} variant="body-xl" color="white">
                  {txt}
                </Typography>
              ))}
            </View>

            <View className="flex flex-row justify-between">
              <View className="flex flex-col items-start">
                <Typography variant="body-md" color="white">
                  {intl.formatMessage({
                    defaultMessage: "Card Holder",
                    id: "card-holder",
                  })}
                </Typography>
                <Typography variant="body-lg" color="white">
                  {paymentCard.name}
                </Typography>
              </View>

              <View className="flex flex-col items-end">
                <Typography variant="body-xl" color="white">
                  {intl.formatMessage({
                    defaultMessage: "Expires",
                    id: "expires",
                  })}
                </Typography>
                <Typography
                  variant="body-lg"
                  color="white"
                >{`${paymentCard.expMonth}/${paymentCard.expYear}`}</Typography>
              </View>
            </View>
          </CardContent>
        </Card>
      </IfElse>
    </View>
  );
};

import { Button, View } from "@/components/ui";
import React from "react";
import { ChatWithSalesBall } from "../../chat-with-sales";

export function NativeActionNavigation({
  onClickButton,
  buttonText,
  leftRender,
  disabled,
}: {
  buttonText?: string;
  disabled?: boolean;
  leftRender?: React.ReactElement;
  onClickButton(): void;
}) {
  return (
    <>
      <View className="native:hidden h-16 p-6 md:hidden" />
      <View className="fixed bottom-0 left-0 z-10 flex w-full flex-row gap-4 rounded-lg bg-white p-8 shadow-sm md:hidden">
        <ChatWithSalesBall />

        {leftRender}

        <Button
          variant="primary"
          color="CTA"
          disabled={disabled}
          fullWidth="full"
          size="md"
          iconAlignment="end"
          iconName="arrowRight"
          onClick={onClickButton}
          children={buttonText ?? ""}
        />
      </View>
    </>
  );
}

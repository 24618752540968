import { BottomDrawerModal } from "@/components/shared/bottom-drawer-modal";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  View,
} from "@/components/ui";
import type { RescheduleVisitWorkerType } from "@/store/visits/visitDetail";
import { useVisitDetailStore } from "@/store/visits/visitDetail";
import { useWindowDimensions } from "@/components/hooks/use-window-dimension";
import { IconButton } from "@/components/ui/icon-button";
import { useConfirmations } from "@/components/hooks/use-confirmations";
import { useCancelVisitWithTokenStore } from "@/store/visits/useCancelVisitWithToken";
import { useClientCancelVisitStore } from "@/store/visits/useClientCancelVisit";
import { CancelVisitReasonEnum } from "@/__generated__/graphql";
import { getRequiredConfirmations } from "@/lib/request-factory";
import { ConfirmationDialog } from "@/components/shared/confirmation-dialog";
import type { GraphQLFormattedError } from "graphql";
import { showToast } from "@/components/ui/toast/show-toast";
import { getErrorMessage } from "@/lib/helpers/string";
import type { VisitDetailData } from "@/components/shared/visits/visit-detail";
import { Typography } from "@/components/shared/typography";
import { useIntl } from "react-intl";
import { useRoute } from "@/components/shared/router";
import { IfElse } from "@/components/shared/if-else";
import { OnlineBookingDepartments } from "@/types/service";
import { onChatSales } from "@/lib/chat-sales";

const responseSkipVisit = (res: {
  data: boolean | null;
  error: string | null;
  graphqlErrors?: ReadonlyArray<GraphQLFormattedError>;
}) => {
  if (res.error && res.graphqlErrors) {
    const confirmations = getRequiredConfirmations(res.graphqlErrors);
    return { success: false, confirmations, error: res.error };
  }

  return { success: Boolean(res.data), confirmations: [], error: res.error };
};

export function SkipVisitModalContainer({
  visitData,
  loading,
}: {
  visitData: VisitDetailData;
  loading: boolean;
}) {
  const intl = useIntl();
  const { push } = useRoute();

  const {
    data: { showSkipVisitModal },
    closeVisitModal,
    closeSkipVisitModal,
    setRescheduleVisitWorkerType,
    refreshVisitList,
  } = useVisitDetailStore();

  const { fetch: cancelWithToken, loading: loadingWithToken } =
    useCancelVisitWithTokenStore();
  const { fetch: cancelVisit, loading: loadingCancelVisit } =
    useClientCancelVisitStore();

  const {
    updateConfirmations,
    hasUnconfirmed,
    firstUnconfirmed,
    setConfirmations,
    confirmations,
  } = useConfirmations();

  const skipVisit = async () => {
    const visitId = visitData.id;
    const visitToken = visitData.token;

    try {
      if (!visitToken) {
        return cancelVisit(
          {
            requestPayload: {
              input: {
                visitId,
                reason: CancelVisitReasonEnum.CancelledByClient,
                confirmations,
              },
            },
          },
          {
            selfHandleError: true,
          },
        ).then(responseSkipVisit);
      } else {
        return cancelWithToken(
          {
            requestPayload: {
              input: {
                token: visitToken,
                reason: CancelVisitReasonEnum.CancelledByClient,
                confirmations,
              },
            },
          },
          {
            selfHandleError: true,
          },
        ).then(responseSkipVisit);
      }
    } catch (error) {
      const errMessage = getErrorMessage(error, "Failed to cancel visit");
      showToast({ type: "error", title: errMessage });

      return {
        success: false,
        confirmations: [],
        error: "Failed to cancel visit",
      };
    }
  };

  const handleSkipVisit = async (
    confirmedKey: string | undefined = undefined,
    confirmed = false,
  ) => {
    if (confirmedKey) {
      updateConfirmations(confirmedKey, confirmed);
    }

    if (hasUnconfirmed) {
      return;
    }

    const {
      success,
      error,
      confirmations: requiredConfirmations,
    } = await skipVisit();

    if (requiredConfirmations.length) {
      setConfirmations(requiredConfirmations);
      return;
    }

    if (!success && error) {
      showToast({ type: "error", title: error });
      return;
    }

    showToast({ type: "success", title: "Success cancel visit!" });
    closeVisitModal();
    refreshVisitList();
  };

  const handleReschedule = (type: RescheduleVisitWorkerType) => () => {
    setRescheduleVisitWorkerType(type);
    closeSkipVisitModal();
    push({
      pageKey: "rescheduleVisit",
      query: {
        id: visitData.id,
      },
    });
  };

  const { isDesktop } = useWindowDimensions();

  const disabled = loading || loadingCancelVisit || loadingWithToken;

  if (firstUnconfirmed) {
    return (
      <ConfirmationDialog
        key={firstUnconfirmed.key}
        cancelText="Yes, Continue"
        confirmText="No"
        title="Skip Visit"
        description={firstUnconfirmed.message ?? ""}
        onConfirm={() => handleSkipVisit(firstUnconfirmed.key)}
        onCancel={() => handleSkipVisit(firstUnconfirmed.key, true)}
        open
      />
    );
  }

  const onContactProfessional = () => {
    onChatSales(
      true,
      "Hi! I would like to speak to sales about canceling my appointment",
    );
  };

  if (showSkipVisitModal) {
    return (
      <BottomDrawerModal
        open
        onOpenChange={disabled ? undefined : closeSkipVisitModal}
      >
        <Dialog>
          <DialogContent>
            <DialogHeader>
              <View className="flex flex-row items-center justify-between gap-2 py-1">
                <DialogTitle>
                  {intl.formatMessage({
                    defaultMessage: "Skip Visit",
                    id: "skip-visit",
                  })}
                </DialogTitle>
                {isDesktop && (
                  <IconButton
                    variant="ghost"
                    color="foreground"
                    size="md"
                    iconName="x"
                    onClick={closeSkipVisitModal}
                  />
                )}
              </View>
            </DialogHeader>
          </DialogContent>
        </Dialog>
        <View className="my-2 flex-1">
          <Typography variant={{ sm: "body-lg", md: "body-xl" }}>
            {intl.formatMessage({
              defaultMessage:
                "If you'd like, you can reschedule this visit instead of skipping",
              id: "skipVisit.rescheduleSuggestion",
            })}
          </Typography>
        </View>
        <DialogFooter>
          <View className="flex flex-1 flex-col gap-2">
            <IfElse
              if={OnlineBookingDepartments.includes(visitData?.department)}
              else={
                <Button
                  variant="secondary"
                  color="success"
                  key="buttonContactProfessional"
                  fullWidth="full"
                  size="md"
                  iconSize="md"
                  onClick={onContactProfessional}
                  iconName="whatsappIcon"
                  iconColor="fill-success"
                  children="Contact Professional"
                />
              }
            >
              <>
                {visitData.worker && (
                  <Button
                    variant="primary"
                    color="CTA"
                    fullWidth="full"
                    disabled={disabled}
                    onClick={handleReschedule("CURRENT_WORKER")}
                    children={intl.formatMessage({
                      defaultMessage: " Reschedule with my Current Cleaner",
                      id: "skipVisit.rescheduleCurrentCleaner",
                    })}
                  />
                )}

                <Button
                  variant="secondary"
                  color="CTA2"
                  fullWidth="full"
                  disabled={disabled}
                  onClick={handleReschedule("ANY_WORKER")}
                  children={intl.formatMessage({
                    defaultMessage: "Reschedule with Any Cleaner",
                    id: "skipVisit.rescheduleAnyCleaner",
                  })}
                />
              </>
            </IfElse>

            <Button
              variant="tertiary"
              color="CTA2"
              fullWidth="full"
              disabled={disabled}
              onClick={handleSkipVisit}
              children={intl.formatMessage({
                defaultMessage: "No thanks, I'd like to skip my visit",
                id: "skipVisit.confirmSkip",
              })}
              loading={loadingCancelVisit}
            />
          </View>
        </DialogFooter>
      </BottomDrawerModal>
    );
  }

  return null;
}

import { BetaServiceIcon, ServiceIcon } from "@/components/shared/service-icon";
import { SquaresFour } from "@/components/shared/icons";
import { Card, CardContent } from "@/components/ui/card";
import { ListHeading } from "@/components/shared/list-header";
import { Button, View } from "@/components/ui";
import { AllServicesModal } from "../all-services";
import { useState } from "react";
import {
  betaServiceItems as betaServiceItemsFn,
  otherServiceItems as otherServiceItemsFn,
} from "../lib";
import { useIntl } from "react-intl";
import { getPlatform } from "@/lib/utils";
import { useRoute } from "@/components/shared/router";

export function OtherServices() {
  const intl = useIntl();
  const otherServiceItems = otherServiceItemsFn(intl);
  const betaServiceItems = betaServiceItemsFn(intl);
  const platform = getPlatform();
  const { push } = useRoute();

  const [modalOpen, setModalOpen] = useState(false);
  function handleOpenChange(newState: boolean) {
    setModalOpen(newState);
  }
  return (
    <Card shadow="sm">
      <CardContent className="flex flex-col gap-4 p-4">
        <ListHeading
          title={intl.formatMessage({
            defaultMessage: "Other Services",
            id: "other-services",
          })}
          icon={SquaresFour}
          action={
            <View>
              <Button
                variant="tertiary"
                color="CTA2"
                onClick={() => {
                  if (platform === "web") {
                    setModalOpen(true);
                    return;
                  }
                  push({ pageKey: "allServices" });
                }}
                children={intl.formatMessage({
                  defaultMessage: "View all",
                  id: "view-all",
                })}
              />
            </View>
          }
        />

        <View className="flex flex-row flex-wrap">
          {otherServiceItems.map((service) => (
            <View className="w-1/4 px-2 pb-4">
              <ServiceIcon key={service.name} {...service} />
            </View>
          ))}
          {betaServiceItems.slice(0, 6).map((service) => (
            <View className="w-1/4 px-2 pb-4">
              <BetaServiceIcon key={service.name} {...service} />
            </View>
          ))}
        </View>

        <AllServicesModal
          modalOpen={modalOpen}
          handleOpenChange={handleOpenChange}
        />
      </CardContent>
    </Card>
  );
}

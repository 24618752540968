import { Star } from "@/components/shared/icons";
import { View, Button } from "@/components/ui";
import { HorizontalScrollView } from "@/components/shared/horizontal-scroll-view";
import { ReviewCard } from "@/components/shared/review-card";
import { getPlatform } from "@/lib/utils";
import { useWindowDimensions } from "@/components/hooks/use-window-dimension";
import { useRoute } from "@/components/shared/router";
import { useState } from "react";
import { CustomerReviewsModal } from "@/components/shared/customer-reviews/customer-reviews-modal";
import { CustomerReviewsDetailModal } from "@/components/shared/customer-reviews/customer-reviews-detail-modal";
import { formatNumber } from "@/lib/helpers/number";
import type { ServiceReviews, Review } from "@/types/booking";
import { Typography } from "@/components/shared/typography";
import { useIntl } from "react-intl";

export function CustomerReviews({
  serviceReviews,
  loading,
}: {
  serviceReviews: ServiceReviews;
  loading?: boolean;
}) {
  const [open, setOpen] = useState(false);
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [review, setReview] = useState<Review>();
  const { width, mediaQuery } = useWindowDimensions();
  const platform = getPlatform();
  const { push } = useRoute();
  const intl = useIntl();

  const onViewAll = () => {
    if (platform === "web") {
      setOpen(true);
    } else {
      push({
        pageKey: "customerReviews",
      });
    }
  };

  return (
    <View className="CustomerReviewsSection mx-4 flex flex-col gap-2 md:mx-0 md:gap-0">
      <View className="CustomerReviews-header md:rounded-t-lg md:border md:border-b-0 md:p-4">
        <View className="flex flex-row items-center justify-between">
          <View className="flex flex-row items-center gap-2">
            <Star weight="fill" className="text-amber-500" size={20} />
            <View className="flex flex-row items-center gap-2">
              <Typography variant="bold-xl">
                {serviceReviews.rating.toFixed(1)}/5.0
              </Typography>
              <Typography variant="body-sm" color="foreground-intermediate">
                |
              </Typography>
              <Typography variant="bold-xl">
                {`${formatNumber(serviceReviews.ratingTotal)} Rating`}
              </Typography>
            </View>
          </View>
          {mediaQuery === "mobile" && (
            <Button
              variant="secondary"
              color="CTA2"
              rounded="full"
              onClick={onViewAll}
              children="View all"
            />
          )}
        </View>
      </View>
      <HorizontalScrollView
        className="md:rounded-b-lg md:border md:border-t-0 md:p-4"
        slideSize={
          getPlatform() === "web"
            ? "md:basis-full basis-11/12"
            : `${width * 0.9}`
        }
        reverseHeader
        headerDivider={mediaQuery === "desktop"}
        showNavigation={mediaQuery === "desktop"}
        HeaderComponent={
          mediaQuery === "desktop" && (
            <Button
              variant="secondary"
              color="CTA2"
              rounded="full"
              onClick={onViewAll}
              children={intl.formatMessage({
                defaultMessage: "View all",
                id: "view-all",
              })}
            />
          )
        }
      >
        {serviceReviews.reviews.slice(0, 5).map((review) => (
          <ReviewCard
            loading={loading}
            key={review.id}
            {...review}
            onClick={() => {
              setDetailModalOpen(true);
              setReview(review);
            }}
            cardBorderStyle="hiddenInLargeScreen"
          />
        ))}
      </HorizontalScrollView>
      <CustomerReviewsModal
        open={open}
        setOpen={setOpen}
        serviceReviews={serviceReviews}
        onClickCard={(r: Review) => {
          setDetailModalOpen(true);
          setReview(r);
        }}
      />
      {review && (
        <CustomerReviewsDetailModal
          open={detailModalOpen}
          setOpen={setDetailModalOpen}
          review={review}
        />
      )}
    </View>
  );
}

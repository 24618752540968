import { Card, CardContent, Switch, View, Input } from "@/components/ui";
import { Wallet } from "@/components/shared/icons";
import { Typography } from "@/components/shared/typography";
import { useAuthState } from "@/store/auth";
import { formatPrice } from "@/lib/helpers/number";
import { InputFormControl } from "@/components/shared/input-form-control";
import type { Control } from "react-hook-form";
import type { ClientApplyCreditFormData } from "@/store/profile/forms/clientApplyCreditForm";
import { CreditTypeEnum } from "@/__generated__/graphql";
import type { PaymentType } from "@/store/invoices";

export function ApplyCredit({
  selectPayment,
  setSelectPayment,
  control,
}: {
  selectPayment: boolean;
  setSelectPayment: (value: React.SetStateAction<PaymentType>) => void;
  control: Control<ClientApplyCreditFormData>;
}) {
  const {
    data: {
      userInfo: { creditAccounts },
    },
  } = useAuthState();

  const creditBalance =
    creditAccounts.find(
      (credit) => credit.creditType === CreditTypeEnum.General,
    )?.balance ?? 0;

  const isNoGeneralCreditBalance = creditBalance === 0;

  return (
    <Card>
      <CardContent className="flex flex-col gap-3">
        <View className="flex flex-row items-center gap-2 md:gap-3">
          <View className="flex w-full flex-1 flex-row gap-3">
            <Wallet weight="fill" className="text-primary" size={28} />
            <View className="flex flex-col gap-1">
              <Typography variant="body-xl">Use from account credit</Typography>
              <View className="flex flex-row gap-1">
                <Typography
                  variant={{ md: "body-lg", sm: "body-md" }}
                  color="foreground-intermediate"
                >
                  Your account credit balance
                </Typography>
                <Typography
                  variant={{ md: "body-lg", sm: "body-md" }}
                  color="brand-primary"
                >
                  {formatPrice(creditBalance)}
                </Typography>
              </View>
            </View>
          </View>
          <Switch
            checked={selectPayment}
            onCheckedChange={(checked) => {
              setSelectPayment(checked ? "apply-credit" : "credit-card");
            }}
            disabled={isNoGeneralCreditBalance}
          />
        </View>
        {selectPayment && (
          <InputFormControl
            name="balance"
            component={Input}
            control={control}
            componentProps={{
              prefix: <Typography>$</Typography>,
              placeholder: "Input Balance",
            }}
          />
        )}
      </CardContent>
    </Card>
  );
}

import type { Package, PackageStatusEnum } from "@/__generated__/graphql";
import { create } from "zustand";

export type PackageStatusFilterItem = {
  label: string;
  value: PackageStatusEnum | "ALL";
};

type PackageListState = {
  packageList: Package[];
  packageData: Package | null;
  rowsPerPage: number;
  packageStatus: PackageStatusEnum | "ALL";
};

export const usePackageState = create<{
  data: PackageListState;
  setPackageList(packages: Package[]): void;
  setPackage(packageData: Package | null): void;
  setLimit(prevOffset: number): void;
  setPackageStatus(packageStatus: PackageStatusEnum | "ALL"): void;
}>((set, get) => ({
  data: {
    packageList: [],
    packageData: null,
    rowsPerPage: 5,
    packageStatus: "ALL",
  },
  setPackageList: (packageList: Package[]) => {
    set({
      data: {
        ...get().data,
        packageList: packageList,
      },
    });
  },
  setPackage: (packageData: Package | null) => {
    set({
      data: {
        ...get().data,
        packageData: packageData,
      },
    });
  },
  setLimit: (prevOffSet: number) => {
    const updatedOffset = prevOffSet + 5;
    set({
      data: {
        ...get().data,
        rowsPerPage: updatedOffset,
      },
    });
  },
  setPackageStatus: (packageStatus: PackageStatusEnum | "ALL") => {
    set({
      data: {
        ...get().data,
        packageStatus: packageStatus,
      },
    });
  },
}));

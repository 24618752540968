import { View, RadioGroup } from "@/components/ui";
import { airconServiceTypeOptions } from "@/constants/booking";
import { ServiceRadioCard } from "@/components/shared/booking/service-radio-card";
import {
  PackageDepartmentEnum,
  PackageDetailSortByEnum,
  PackageRecurrenceEnum,
  ServiceTypeEnum,
  SortEnum,
} from "@/__generated__/graphql";
import { useBookingState } from "@/store/booking/useBookingState";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { SalesModal } from "@/components/shared/booking/sales-modal";
import { Typography } from "@/components/shared/typography";

export const SelectAirconService = () => {
  const {
    data: { packageDetailsFilter, airconSalesModalOpen },
    setBookingState,
  } = useBookingState();

  const onSelect = (value: ServiceTypeEnum) => {
    const airconService = airconServiceTypeOptions.find(
      (item) => item.value === value,
    );
    if (!airconService) {
      throw new Error("package not found");
    }
    setBookingState({
      packageDetailsFilter: {
        ...packageDetailsFilter,
        serviceTypes: [value],
        repeatEvery: [airconService.repeatEvery],
        repeatEveryTimes: airconService.duration,
      },
    });
  };

  useEffect(() => {
    setBookingState({
      department: PackageDepartmentEnum.Aircon,
      packageDetailsFilter: {
        repeatEvery: [PackageRecurrenceEnum.Year],
        repeatEveryTimes: 4,
        serviceTypes: [ServiceTypeEnum.GeneralServicing],
        sort: [SortEnum.Asc],
        sortBy: [PackageDetailSortByEnum.Duration],
      },
    });
  }, []);
  const intl = useIntl();
  return (
    <View className="mx-4">
      <Typography
        variant="body-lg"
        color="foreground-intermediate"
        className="my-4"
      >
        {intl.formatMessage({
          defaultMessage:
            "Please select your desired service from the options below to proceed.",
          id: "selectAirconService.description",
        })}
      </Typography>
      <RadioGroup
        value={
          packageDetailsFilter.serviceTypes?.[0] ||
          ServiceTypeEnum.GeneralServicing
        }
        onValueChange={() => {}}
        className="flex flex-col gap-4"
      >
        {airconServiceTypeOptions.map((option) => {
          return (
            <ServiceRadioCard
              key={option.value}
              active={
                (packageDetailsFilter.serviceTypes?.[0] ||
                  ServiceTypeEnum.GeneralServicing) === option.value
              }
              onSelect={() => {
                if (option.isModal) {
                  setBookingState({ airconSalesModalOpen: true });
                } else {
                  onSelect(option.value);
                }
              }}
              imgUrl={option.imgUrl}
              title={option.title}
              subTitle={option.subtitle}
              thirdTitle={option.thirdTitle}
              description={option.description}
              value={option.value}
              isModal={option.isModal}
            />
          );
        })}
      </RadioGroup>
      <SalesModal
        open={airconSalesModalOpen}
        setOpen={() =>
          setBookingState({ airconSalesModalOpen: !airconSalesModalOpen })
        }
        title="Need help with your aircon?"
        description="Our experts are here to diagnose and fix issues directly through WhatsApp!"
      />
    </View>
  );
};

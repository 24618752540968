import { PackageDepartmentEnum } from "@/__generated__/graphql";

export type BetaServiceName =
  | "handyman"
  | "home-massage"
  | "babysitter"
  | "deep-cleaning"
  | "car-wash"
  | "curtain-cleaning"
  | "plumbing"
  | "electrician"
  | "pet-grooming"
  | "pet-sitting"
  | "elderly-care"
  // | "pest-control"
  | "carpet-cleaning"
  | "maid-agency";

export type ServiceName =
  | "home-cleaning"
  | "aircon-service"
  | "home-beauty"
  | UpholsteryServiceName;

export type UpholsteryServiceName =
  | "mattress-cleaning"
  | "sofa-cleaning"
  | "rug-cleaning"
  | "pram-seat";

export const UpholsteryServiceNames: UpholsteryServiceName[] = [
  "mattress-cleaning",
  "sofa-cleaning",
  "rug-cleaning",
  "pram-seat",
];

export const OnlineBookingDepartments: PackageDepartmentEnum[] = [
  PackageDepartmentEnum.HomeCleaning,
  PackageDepartmentEnum.Aircon,
  PackageDepartmentEnum.CarpetUpholstery,
  PackageDepartmentEnum.HomeBeauty,
];

import { View } from "@/components/ui";
import { Input } from "@/components/ui/input/index.web";
import { Controller } from "react-hook-form";
import { Typography } from "../typography";
import type { MaskedTextInputProps, MaskType } from "./type";
import { IMaskMixin } from "react-imask";

const maskMap: Record<MaskType, string> = {
  creditCard: "0000 0000 0000 0000",
  expDate: "00/0000",
};

const unmaskMap: Record<MaskType, boolean> = {
  creditCard: true,
  expDate: false,
};

export const MaskedTextInput = ({
  label,
  name,
  mask,
  placeholder,
  defaultValue,
  control,
  disabled,
}: MaskedTextInputProps) => {
  return (
    <View className="flex flex-col gap-1 pb-3">
      <Typography variant="body-lg">{label}</Typography>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <MaskInput
              mask={maskMap[mask]}
              value={value}
              unmask={unmaskMap[mask]}
              placeholder={placeholder}
              onAccept={onChange}
              className={error ? "border-red-600" : ""}
              disabled={disabled}
            />
            {error && (
              <Typography variant="body-sm" className="text-red-600">
                {error.message}
              </Typography>
            )}
          </>
        )}
      />
    </View>
  );
};

const MaskInput = IMaskMixin<HTMLInputElement>(({ inputRef, ...props }) => (
  <Input {...props} ref={inputRef} />
));

MaskInput.displayName = "MaskInput";

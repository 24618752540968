import { useEffect } from "react";

export const useFocusScreen = (
  callback: () => void | undefined | (() => void),
) => {
  useEffect(() => {
    let callbackFn: void | undefined | (() => void);

    const onVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        callbackFn = callback();
      }
    };
    document.addEventListener("visibilitychange", onVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", onVisibilityChange);
      if (callbackFn && typeof callbackFn === "function") {
        callbackFn();
      }
    };
  }, [callback]);
};

import { Dialog, DialogContent, View, Button, Checkbox } from "@/components/ui";
import { Typography } from "@/components/shared/typography";
import { Warning } from "@/components/shared/icons";
import { useId } from "react";

export const ConfirmAccountDeletionModal = ({
  open,
  setOpen,
  checked,
  loading,
  setChecked,
  onDeleteAccount,
}: {
  open: boolean;
  checked: boolean;
  loading: boolean;
  setOpen(open: boolean): void;
  setChecked(open: boolean): void;
  onDeleteAccount(): void;
}) => {
  const id = useId();

  return (
    <Dialog open={open} onOpenChange={() => setOpen(false)}>
      <DialogContent className="md:w-[375px]">
        <View className="flex flex-col items-center">
          <Warning className="text-danger" size={28} />
          <Typography variant="label-xl" className="pt-2">
            Confirm Account Deletion
          </Typography>
          <View className="py-4">
            <Typography variant="body-md">
              Under Section{" "}
              <Typography variant="bold-md" className="inline">
                46(2)(b)
              </Typography>{" "}
              of the Singapore Goods and Service Tax Act, we have to keep full
              data of our customers, services, and billing for 5 years.
            </Typography>
            <Typography variant="body-md" className="mt-6">
              We will mark the account for deletion and the information can only
              be fully purged from our system after 5 years.
            </Typography>
          </View>

          <View className="flex flex-row items-center gap-2 self-start pb-6 pt-2">
            <Checkbox id={id} checked={checked} onCheckedChange={setChecked} />
            <Typography htmlFor={id} variant="body-sm">
              By ticking, you are confirming that you have read, understood, and
              agree
            </Typography>
          </View>

          <View className="flex w-full flex-row gap-2">
            <Button
              variant={"secondary"}
              color={"danger"}
              className="flex-1"
              onClick={onDeleteAccount}
              loading={loading}
              children="Delete Account"
            />

            <Button
              variant="primary"
              color={"CTA"}
              className="flex-1"
              onClick={() => setOpen(false)}
              children="Cancel"
            />
          </View>
        </View>
      </DialogContent>
    </Dialog>
  );
};

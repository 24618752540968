import { ChangePhoneNumberModalEnum } from "@/types/profile";
import { create } from "zustand";
import type { ChangePhoneNumberFormReturn } from "./forms/useChangePhoneNumberForm";

type ProfileState = {
  changePhoneNumberModalOpen: boolean;
  changeNumberOtpVerificationModalOpen: boolean;
  cancelChangePhoneNumber: boolean;
  changeNumberSuccessModal: boolean;
  changePhoneNumberFormReturn: ChangePhoneNumberFormReturn | null;
  phone: string;
  updatedPhone: string;
  pinCode: string;
  isUpdateCard: boolean;
};

export const useProfileState = create<{
  data: ProfileState;
  setChangePhoneNumberReturn: (
    data: ChangePhoneNumberFormReturn | null,
  ) => void;
  setPhone: (phone: string) => void;
  setUpdatedPhone: (phone: string) => void;
  setPinCode: (pinCode: string) => void;
  setIsUpdateCard: (isUpdateCard: boolean) => void;
  showModal: (...modalNames: ChangePhoneNumberModalEnum[]) => void;
}>((set, get) => ({
  data: {
    phone: "",
    updatedPhone: "",
    pinCode: "",
    changePhoneNumberModalOpen: false,
    changeNumberOtpVerificationModalOpen: false,
    cancelChangePhoneNumber: false,
    changeNumberSuccessModal: false,
    changePhoneNumberFormReturn: null,
    isUpdateCard: false,
  },
  setChangePhoneNumberReturn: (
    changePhoneNumberFormReturn: ChangePhoneNumberFormReturn | null,
  ) => {
    set({ data: { ...get().data, changePhoneNumberFormReturn } });
  },
  setPhone: (phone: string) => {
    set({ data: { ...get().data, phone } });
  },
  setUpdatedPhone: (updatedPhone: string) => {
    set({ data: { ...get().data, updatedPhone } });
  },
  setPinCode: (pinCode: string) => {
    set({ data: { ...get().data, pinCode } });
  },
  setIsUpdateCard: (isUpdateCard: boolean) => {
    set({ data: { ...get().data, isUpdateCard } });
  },
  showModal: (...modalNames: (ChangePhoneNumberModalEnum | null)[]) => {
    set({
      data: {
        ...get().data,
        changePhoneNumberModalOpen: modalNames.includes(
          ChangePhoneNumberModalEnum.CHANGE_PHONE_NUMBER,
        ),
        changeNumberOtpVerificationModalOpen: modalNames.includes(
          ChangePhoneNumberModalEnum.CHANGE_PHONE_NUMBER_OTP_VERIFICATION,
        ),
        cancelChangePhoneNumber: modalNames.includes(
          ChangePhoneNumberModalEnum.CANCEL_CHANGE_PHONE_NUMBER,
        ),
        changeNumberSuccessModal: modalNames.includes(
          ChangePhoneNumberModalEnum.CHANGE_PHONE_NUMBER_SUCCESS,
        ),
      },
    });
  },
}));

import { BottomDrawerModal } from "@/components/shared/bottom-drawer-modal";
import { View, Avatar, AvatarFallback, AvatarImage } from "@/components/ui";
import { ImageCarousel } from "@/components/shared/image-carousel";
import { Star, User } from "@/components/shared/icons";
import { Typography } from "../typography";

export function WorkerInfoModal({
  openModal,
  onClose,
  cleanerProfilePhoto,
  serviceCleaner,
  serviceRating,
  reviewNumber,
  slotDay,
  slotStartTime,
  slotEndTime,
  images,
}: {
  openModal: boolean;
  onClose: () => void;
  cleanerProfilePhoto: string;
  serviceCleaner: string;
  serviceRating: number;
  reviewNumber: number;
  slotDay: string;
  slotStartTime: string;
  slotEndTime: string;
  images: string[];
}) {
  const handleClose = (isOpen: boolean) => {
    if (!isOpen) {
      onClose();
    }
  };
  return (
    <BottomDrawerModal
      open={openModal}
      onOpenChange={handleClose}
      className="md:w-[375px]"
    >
      <View className="flex flex-col font-inter">
        <View className="mb-3 flex flex-row border-b pb-3">
          <Avatar alt="home-beauty-avatar" className="size-[56px]">
            <AvatarImage src={cleanerProfilePhoto ?? ""} />
            <AvatarFallback>
              <View className="p-2">
                <User className="h-full w-full" />
              </View>
            </AvatarFallback>
          </Avatar>
          <View className="pl-2">
            <Typography variant="label-xl" className="py-1">
              {serviceCleaner}
            </Typography>
            <View className="flex cursor-pointer flex-row items-center gap-1">
              <Star weight="fill" className="color-warning" size={16} />
              <View className="flex flex-row divide-x">
                <Typography variant="body-lg" color="info" className="mr-1">
                  {serviceRating.toFixed(1)}/5
                </Typography>
                <Typography variant="body-lg" color="info" className="pl-1">
                  {reviewNumber} Review(s)
                </Typography>
              </View>
            </View>
          </View>
        </View>
        <View>
          <Typography variant="body-lg" color="foreground-intermediate">
            Available: {slotDay} at {slotStartTime}- {slotEndTime}
          </Typography>
          <Typography className="my-3" variant={"body-2xl"}>
            Nail Design Samples
          </Typography>
          <View className="mb-5 overflow-hidden rounded-2xl">
            <ImageCarousel
              images={images}
              height={403}
              autoPlay={true}
              showArrows={false}
              pagingEnabled={false}
            />
          </View>
        </View>
      </View>
    </BottomDrawerModal>
  );
}

import { View, Button } from "@/components/ui";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
} from "@/components/ui/dropdown-menu";
import { useWindowDimensions } from "@/components/hooks/use-window-dimension";
import { cn } from "@/lib/utils";
import { useState } from "react";
import { CaretDown } from "@/components/shared/icons";
import { BottomDrawerModal } from "@/components/shared/bottom-drawer-modal";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { ClientAccountTypeEnum } from "@/__generated__/graphql";
import { useAuthState } from "@/store/auth";
import { useUpdateClientStore } from "@/store/auth/updateClient";
import { Typography } from "../../typography";
import { useIntl } from "react-intl";
import { showToast } from "@/components/ui/toast/show-toast";
import { useClientStore } from "@/store/auth/client";
import { getErrorMessage } from "@/lib/helpers/string";

export const ChangeAccountType = () => {
  const intl = useIntl();
  const { isMobile } = useWindowDimensions();
  const [openChangeAccountType, setOpenChangeAccountType] =
    useState<boolean>(false);

  const {
    data: { userInfo },
  } = useAuthState();

  const { fetch: updateClient } = useUpdateClientStore();
  const { fetch: client } = useClientStore();

  const onUpdateClient = async (accountType: ClientAccountTypeEnum) => {
    try {
      const response = await updateClient({
        requestPayload: {
          input: {
            id: userInfo.id,
            accountType,
          },
        },
      });

      if (response?.data) {
        client({ requestPayload: { id: userInfo.id } });
      }
    } catch (error) {
      const errMsg = getErrorMessage(error, "Error update client");
      showToast({
        type: "error",
        title: errMsg,
      });
    }
  };

  const handleClose = () => {
    setOpenChangeAccountType(false);
  };

  return (
    <>
      <DropdownMenu onOpenChange={setOpenChangeAccountType}>
        <DropdownMenuTrigger>
          {isMobile ? (
            <Typography variant="body-md" color="brand-primary">
              Change
            </Typography>
          ) : (
            <View
              className={cn("ml-2 transition duration-300 ease-in-out", {
                "rotate-180": openChangeAccountType,
              })}
            >
              <CaretDown />
            </View>
          )}
        </DropdownMenuTrigger>
        {!isMobile && (
          <DropdownMenuContent className="-left-10 p-2 px-3">
            <DropdownMenuRadioGroup
              value={userInfo.accountType}
              onValueChange={(value) => {
                onUpdateClient(value as ClientAccountTypeEnum);
              }}
            >
              <DropdownMenuRadioItem
                value={ClientAccountTypeEnum.Individual.toString()}
                className="border-b border-secondary-border px-0 pb-2"
              >
                {intl.formatMessage({
                  defaultMessage: "Personal",
                  id: "personal",
                })}
              </DropdownMenuRadioItem>
              <DropdownMenuRadioItem
                value={ClientAccountTypeEnum.Corporate.toString()}
                className="px-0"
              >
                {intl.formatMessage({
                  defaultMessage: "Corporate",
                  id: "corporate",
                })}
              </DropdownMenuRadioItem>
            </DropdownMenuRadioGroup>
          </DropdownMenuContent>
        )}
      </DropdownMenu>
      {isMobile && (
        <BottomDrawerModal
          open={openChangeAccountType}
          onOpenChange={setOpenChangeAccountType}
        >
          <View className="flex flex-col gap-4">
            <RadioGroup
              value={userInfo.accountType}
              onValueChange={(value) => {
                onUpdateClient(value as ClientAccountTypeEnum);
                handleClose();
              }}
            >
              <View
                className="mb-0 flex cursor-pointer flex-row items-center gap-2 border-b border-secondary-border pb-5"
                onClick={() => {
                  onUpdateClient(ClientAccountTypeEnum.Individual);
                  handleClose();
                }}
              >
                <Typography color="foreground-default" variant="bold-xl">
                  {intl.formatMessage({
                    defaultMessage: "Personal",
                    id: "personal",
                  })}
                </Typography>
                <RadioGroupItem
                  checkCircle
                  value={ClientAccountTypeEnum.Individual.toString()}
                  className="ml-auto"
                />
              </View>

              <View
                className="my-4 flex cursor-pointer flex-row items-center gap-2"
                onClick={() => {
                  onUpdateClient(ClientAccountTypeEnum.Corporate);
                  handleClose();
                }}
              >
                <Typography color="foreground-default" variant="bold-xl">
                  {intl.formatMessage({
                    defaultMessage: "Corporate",
                    id: "corporate",
                  })}
                </Typography>
                <RadioGroupItem
                  checkCircle
                  value={ClientAccountTypeEnum.Corporate.toString()}
                  className="ml-auto"
                />
              </View>
            </RadioGroup>
            <Button
              variant="primary"
              color="CTA"
              onClick={handleClose}
              children={intl.formatMessage({
                defaultMessage: "Close",
                id: "close",
              })}
            />
          </View>
        </BottomDrawerModal>
      )}
    </>
  );
};

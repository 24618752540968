import { useEffect } from "react";
import { RadioGroup, RadioGroupItem, View } from "@/components/ui";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu/index.web";
import type { Lang } from "@/store/language";
import { useLanguageStore } from "@/store/language";
import { storage } from "@/lib/storage";
import { Divider } from "@/components/shared/divider";
import { IconButton } from "@/components/ui/icon-button";
import { Typography } from "../typography";

function LanguageToggle() {
  const { language, setLanguage } = useLanguageStore((t) => t);
  useEffect(() => {
    async function checkStorage() {
      const lang = await storage.getItem<Lang>("LUCE_LANGUAGE");
      if (lang && lang !== "en") {
        setLanguage(lang);
      }
    }

    checkStorage();
  }, []);

  const changeLocale = (lang: Lang) => {
    setLanguage(lang);
    storage.setItem("LUCE_LANGUAGE", lang);
  };

  const english = language === "en";

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <IconButton
          rounded="full"
          variant="primary"
          className="cursor-pointer bg-brand-over-static"
          iconName={english ? "usaIcon" : "chinaIcon"}
        />
      </DropdownMenuTrigger>

      <DropdownMenuContent align="end" className="rounded-xl">
        <RadioGroup value={language} onValueChange={() => {}}>
          <DropdownMenuItem onClick={() => changeLocale("en")}>
            <View className="flex flex-1 cursor-pointer flex-row items-center justify-between">
              <Typography variant="body-lg">English</Typography>
              <RadioGroupItem value="en" checkCircle />
            </View>
          </DropdownMenuItem>

          <Divider />

          <DropdownMenuItem onClick={() => changeLocale("zh-CN")}>
            <View className="flex flex-1 cursor-pointer flex-row items-center justify-between">
              <Typography variant="body-lg">Chinese</Typography>
              <RadioGroupItem value="zh-CN" checkCircle />
            </View>
          </DropdownMenuItem>
        </RadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export { LanguageToggle };

import { View } from "@/components/ui";
import { ChatWithSales } from "@/components/shared/chat-with-sales";
import {
  BookingNavigationBottom,
  BookingOrderCard,
} from "@/components/shared/booking/order-card";
import { BookingPayLater } from "@/components/shared/booking/pay-later";
import { AgreeTerms } from "../agree-terms";
import { getPlatform } from "@/lib/utils";
import type { ServiceName } from "@/types/service";
import { useClientConfirmReservationStore } from "@/store/booking/clientConfirmReservation";
import { UserPlatformEnum } from "@/__generated__/graphql";
import { getUserDevice } from "@/lib/platform";
import { useBookingState } from "@/store/booking/useBookingState";
import { useBookingRoute } from "@/components/hooks/use-booking-route";
import {
  sendAnalyticData,
  transformReservationDataToPurchase,
} from "@/lib/monitoring/analytics";
import { showToast } from "@/components/ui/toast/show-toast";
import { getSalesPersonData } from "@/lib/get-sales-person-data";

export const BookingConfirmationNavigation = (props: { name: ServiceName }) => {
  const { forward } = useBookingRoute();
  const platform = getPlatform();
  const {
    data: {
      bookingInfo,
      promo,
      reservations,
      selectedAddress,
      packageDetailsFilter,
      reservationTimeStamp,
      isRebook,
    },
    setBookingState,
  } = useBookingState();

  const { fetch: confirmReservation, loading: confirmReservationLoading } =
    useClientConfirmReservationStore();

  const onContinue = () => {
    const now = new Date().getTime();
    if (now > reservationTimeStamp) {
      showToast({
        title: "Your selected slot already expired, please select slot again",
        type: "error",
      });
      return;
    }
    if (!selectedAddress || !reservations.length || !bookingInfo) return;
    confirmReservation({
      requestPayload: {
        input: {
          accessType: `${bookingInfo.accessMode}: ${bookingInfo.accessInstructions}`,
          additionalNotes: bookingInfo.note,
          fullAddress: selectedAddress.fullAddress as string,
          postalCode: selectedAddress.postalCode,
          promoCode: promo?.appliedPromoCode,
          reservationId: reservations[0].id,
          salespersonShortcode: getSalesPersonData(),
          unitNumber: selectedAddress.unitNumber,
          workerChosenByClient: false,
          creationSource: {
            device: getUserDevice(),
            platform:
              platform === "native"
                ? UserPlatformEnum.ClientApp
                : UserPlatformEnum.ClientWeb,
          },
        },
      },
    }).then((res) => {
      if (res.error) {
        return;
      }

      const reservation = res.data?.clientConfirmReservation;
      if (reservation) {
        const multiplier = !packageDetailsFilter?.repeatEveryTimes
          ? 1
          : packageDetailsFilter?.repeatEveryTimes;
        sendAnalyticData({
          name: "purchase",
          values: transformReservationDataToPurchase(multiplier, reservation),
        });
        if (isRebook) {
          sendAnalyticData({
            name: "rebook_complete",
            values: transformReservationDataToPurchase(multiplier, reservation),
          });
        }
      }
      setBookingState({
        confirmedWorker:
          res.data?.clientConfirmReservation?.package?.jobs?.[0]?.worker,
        completedBooking: true,
      });
      forward();
    });
  };

  return (
    <View>
      {/* large screen */}
      <View className="hidden flex-col gap-4 md:flex">
        <BookingOrderCard
          loading={confirmReservationLoading}
          onContinue={onContinue}
          buttonText="Complete Booking"
          icon="shieldCheck"
          iconAlignment="start"
          extraInfo={
            <View className="border-t py-3">
              <AgreeTerms />
            </View>
          }
        />
        <BookingPayLater />
        <ChatWithSales />
      </View>
      {/* narrow screen */}
      <BookingNavigationBottom
        loading={confirmReservationLoading}
        buttonText="Complete Booking"
        onContinue={onContinue}
        icon="shieldCheck"
        iconAlignment="start"
      />
    </View>
  );
};

import {
  PackageDepartmentEnum,
  PackageServiceTypeEnum,
  VisitStatusEnum,
  WorkerDepartmentEnum,
} from "@/__generated__/graphql";
import type { ServiceName } from "@/types/service";

export function getServiceLabel(name: ServiceName) {
  switch (name) {
    case "home-cleaning":
      return "Home Cleaning";
    case "aircon-service":
      return "Aircon Service";
    case "home-beauty":
      return "Home Beauty";
    case "mattress-cleaning":
      return "Mattress Cleaning";
    case "sofa-cleaning":
      return "Sofa Cleaning";
    case "rug-cleaning":
      return "Rug Cleaning";
    case "pram-seat":
      return "Pram & Car Seat";
    default:
      return "Cleaning Service";
  }
}

const visitStatusMapping: Record<VisitStatusEnum, string> = {
  [VisitStatusEnum.Completed]: "Completed",
  [VisitStatusEnum.Expired]: "Expired",
  [VisitStatusEnum.Scheduled]: "Scheduled",
  [VisitStatusEnum.CancelledByClient]: "Cancelled",
  [VisitStatusEnum.CancelledByOps]: "Cancelled",
  [VisitStatusEnum.PendingClientSchedule]: "Pending",
  [VisitStatusEnum.CancelledByWorker]: "Cancelled",
  [VisitStatusEnum.UnableToSchedule]: "Unable to Schedule",
  [VisitStatusEnum.MissedByWorker]: "Missed by Worker",
  [VisitStatusEnum.Finished]: "Finished",
  [VisitStatusEnum.Started]: "Started",
};

export function visitStatusLabel(status: VisitStatusEnum): string {
  return visitStatusMapping[status];
}

export const PackageServiceTypeMapping: Record<PackageServiceTypeEnum, string> =
  {
    [PackageServiceTypeEnum.AdHoc]: "AdHoc",
    [PackageServiceTypeEnum.Aircon]: "Aircon",
    [PackageServiceTypeEnum.ChemicalOverhaul]: "Chemical Overhaul",
    [PackageServiceTypeEnum.ChemicalWash]: "Chemical Wash",
    [PackageServiceTypeEnum.Dismantling]: "Dismantling",
    [PackageServiceTypeEnum.Equipment]: "Equipment",
    [PackageServiceTypeEnum.GasLeakTesting]: "Gas Leak Testing",
    [PackageServiceTypeEnum.GasTopUp]: "Gas Top Up",
    [PackageServiceTypeEnum.GeneralServicing]: "General Servicing",
    [PackageServiceTypeEnum.HomeCleaning]: "Home Cleaning",
    [PackageServiceTypeEnum.Installation]: "Installation",
    [PackageServiceTypeEnum.JetWash]: "Jet Wash",
    [PackageServiceTypeEnum.OfficeCleaning]: "Office Cleaning",
    [PackageServiceTypeEnum.RepairDiagnostic]: "Repair Diagnostic",
    [PackageServiceTypeEnum.RepairFollowUp]: "Repair Follow Up",
    [PackageServiceTypeEnum.CondenserWash]: "Compressor Wash",
    [PackageServiceTypeEnum.Adjustment]: "Adjustment",
    [PackageServiceTypeEnum.Warranty]: "Warranty",
    [PackageServiceTypeEnum.CarpetUpholstery]: "Carpet Upholstery",
    [PackageServiceTypeEnum.DeepExtraction]: "Deep Extraction",
    [PackageServiceTypeEnum.Discount]: "Discount",
    [PackageServiceTypeEnum.UltraVioletCleaning]: "UV-C Cleaning",
    [PackageServiceTypeEnum.AddOn]: "Add on",
    [PackageServiceTypeEnum.Combo]: "Manicure Pedicure",
    [PackageServiceTypeEnum.Manicure]: "Manicure",
    [PackageServiceTypeEnum.Pedicure]: "Pedicure",
    [PackageServiceTypeEnum.Upgrade]: "Upgrade",
    [PackageServiceTypeEnum.Hardware]: "Hardware",
    [PackageServiceTypeEnum.SteamCleaning]: "Steam Cleaning",
    [PackageServiceTypeEnum.DeepCleaning]: "Deep Cleaning",
    [PackageServiceTypeEnum.Electrician]: "Electrician",
    [PackageServiceTypeEnum.Lashes]: "Lashes",
    [PackageServiceTypeEnum.Mpv]: "MPV",
    [PackageServiceTypeEnum.Plumbing]: "Plumbing",
    [PackageServiceTypeEnum.Fee]: "Fee",
    [PackageServiceTypeEnum.Massage]: "Massage",
    [PackageServiceTypeEnum.Sedan]: "Sedan",
    [PackageServiceTypeEnum.Suv]: "SUV",
    [PackageServiceTypeEnum.Babysitting]: "Babysitting",
    [PackageServiceTypeEnum.PetCare]: "Pet Care",
    [PackageServiceTypeEnum.ElderCare]: "Elder Care",
  };

export const departmentMapping: Record<PackageDepartmentEnum, string> = {
  [PackageDepartmentEnum.HomeCleaning]: "Home Cleaning",
  [PackageDepartmentEnum.Aircon]: "Aircon Cleaning",
  [PackageDepartmentEnum.CarpetUpholstery]: "Carpet Upholstery Cleaning",
  [PackageDepartmentEnum.OfficeCleaning]: "Office Cleaning",
  [PackageDepartmentEnum.HomeBeauty]: "Home Beauty",
  [PackageDepartmentEnum.Babysitter]: "Babysitter",
  [PackageDepartmentEnum.CarWash]: "Car Wash",
  [PackageDepartmentEnum.Handyman]: "Handyman",
  [PackageDepartmentEnum.Massage]: "Massage",
  [PackageDepartmentEnum.PetCare]: "Pet Care",
  [PackageDepartmentEnum.DeepCleaning]: "Deep Cleaning",
  [PackageDepartmentEnum.ElderCare]: "Elder Care",
};

export function packageServiceTypeLabel(
  serviceType: PackageServiceTypeEnum,
  department?: PackageDepartmentEnum,
): string {
  if (department === PackageDepartmentEnum.CarpetUpholstery) {
    return PackageServiceTypeMapping[PackageServiceTypeEnum.CarpetUpholstery];
  }

  if (department === PackageDepartmentEnum.HomeCleaning) {
    return PackageServiceTypeMapping[PackageServiceTypeEnum.HomeCleaning];
  }

  return PackageServiceTypeMapping[serviceType];
}

export const departmentPackageToWorker = (
  department: PackageDepartmentEnum,
): WorkerDepartmentEnum => {
  switch (department) {
    case PackageDepartmentEnum.Aircon:
      return WorkerDepartmentEnum.Aircon;
    case PackageDepartmentEnum.CarpetUpholstery:
      return WorkerDepartmentEnum.Aircon;
    case PackageDepartmentEnum.HomeBeauty:
      return WorkerDepartmentEnum.HomeBeauty;
    case PackageDepartmentEnum.HomeCleaning:
      return WorkerDepartmentEnum.HomeCleaning;
    case PackageDepartmentEnum.OfficeCleaning:
      return WorkerDepartmentEnum.OfficeCleaning;

    default:
      return WorkerDepartmentEnum.HomeCleaning;
  }
};

export const departmentWorkerToPackage = (
  department: WorkerDepartmentEnum,
): PackageDepartmentEnum => {
  switch (department) {
    case WorkerDepartmentEnum.Aircon:
      return PackageDepartmentEnum.Aircon;
    case WorkerDepartmentEnum.HomeBeauty:
      return PackageDepartmentEnum.HomeBeauty;
    case WorkerDepartmentEnum.HomeCleaning:
      return PackageDepartmentEnum.HomeCleaning;
    case WorkerDepartmentEnum.OfficeCleaning:
      return PackageDepartmentEnum.OfficeCleaning;

    default:
      return PackageDepartmentEnum.HomeCleaning;
  }
};

export const getWorkerLabel = (department: PackageDepartmentEnum): string => {
  switch (department) {
    case PackageDepartmentEnum.CarpetUpholstery:
      return "technician";
    case PackageDepartmentEnum.Aircon:
      return "technician";
    case PackageDepartmentEnum.HomeCleaning:
      return "cleaner";
    case PackageDepartmentEnum.HomeBeauty:
      return "beauty technician";
    case PackageDepartmentEnum.OfficeCleaning:
      return "cleaner";

    default:
      return "cleaner";
  }
};

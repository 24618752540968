import {
  PackageDepartmentEnum,
  PackageRecurrenceEnum,
  ServiceTypeEnum,
} from "@/__generated__/graphql";
import { Divider } from "@/components/shared/divider";
import { IfElse } from "@/components/shared/if-else";
import { useRoute } from "@/components/shared/router";
import { Typography } from "@/components/shared/typography";
import {
  VisitCardInfo,
  type VisitData,
} from "@/components/shared/visits/visit-card";
import { Button, Card, CardContent } from "@/components/ui";
import { getBookingPackages, getDepartment } from "@/lib/booking-lib";
import { useAllClientPackageDetailsStore } from "@/store/booking/allClientPackageDetails";
import {
  getRebookAdHocState,
  useBookingState,
} from "@/store/booking/useBookingState";
import { useNext7DayVisitsStore } from "@/store/visits/next7dayVisits";
import { usePast30DayVisitsStore } from "@/store/visits/past30dayVisits";
import { addYears } from "date-fns";
import { useEffect, useMemo } from "react";
import { listType } from "../visits/visit-list";
import { useAuthState } from "@/store/auth";
import { sendAnalyticData } from "@/lib/monitoring/analytics";
import { useClientReviewsStore } from "@/store/booking/clientReviews";
import { formatDate, getTimeDifferenceHours } from "@/lib/helpers/date";

/**
 * Show Previous Booking when:
 * 1. User has one-Time Home Cleaning Booking in past 30 days.
 * 2. User don't have Home Cleaning Booking in next 7 days.
 */

export const PreviousBooking = () => {
  const { data: past30DaysVisits, fetch: getPast30DaysVisits } =
    usePast30DayVisitsStore();
  const { data: next7DaysVisits, fetch: getNext7DaysVisits } =
    useNext7DayVisitsStore();
  const user = useAuthState((state) => state.data.userInfo);
  const { setBookingState } = useBookingState();
  const { fetch: getAllClientPackage, loading } =
    useAllClientPackageDetailsStore();
  const { fetch: getReviews } = useClientReviewsStore();
  const { push } = useRoute();

  useEffect(() => {
    if (!user.id) return;
    getPast30DaysVisits({
      requestPayload: {
        filters: listType.past30days.initialFilters(user.id),
      },
    });
    getNext7DaysVisits({
      requestPayload: {
        filters: listType.next7days.initialFilters(user.id),
      },
    });
  }, [user.id]);

  const previousBooking = useMemo(() => {
    const previousHCBooking =
      past30DaysVisits.find(
        (visit) => visit.department === PackageDepartmentEnum.HomeCleaning,
      ) || null;
    const hasUpcomingHCBooking = next7DaysVisits.some(
      (visit) => visit.department === PackageDepartmentEnum.HomeCleaning,
    );
    return hasUpcomingHCBooking ? null : previousHCBooking;
  }, [past30DaysVisits, next7DaysVisits]);

  const onRebook = () => {
    const serviceName = "home-cleaning";
    const department = getDepartment(serviceName);
    const timeDiff = getTimeDifferenceHours(
      previousBooking?.startTime || "",
      previousBooking?.endTime || "",
    );
    const filters = {
      serviceTypes: [ServiceTypeEnum.HomeCleaning],
      repeatEvery: [PackageRecurrenceEnum.AdHoc],
      repeatEveryTimes: 0,
      duration: timeDiff,
    };

    // getReviews here for complete booking page, don't need to wait for it
    getReviews({
      requestPayload: {
        filters: {
          startDate: formatDate(addYears(new Date(), -1)),
          endDate: formatDate(new Date()),
          department,
          limit: 3,
        },
      },
    });

    // rebook one time cleaning
    setBookingState(getRebookAdHocState(timeDiff));

    return getAllClientPackage({
      requestPayload: {
        filters: {
          ...filters,
          department,
          active: true,
        },
      },
    }).then((res) => {
      if (res.error || !res.data) {
        return;
      }
      const packages = getBookingPackages(department, res.data);
      sendAnalyticData({ name: "rebook_click" });
      setBookingState({ selectedPackageIds: packages.map((pkg) => pkg.id) });
      push("/booking/home-cleaning/select-slot");
    });
  };

  return (
    <IfElse if={!!previousBooking} else={null}>
      <Card className="mt-4 bg-white">
        <CardContent className="border-b border-border-medium">
          <Typography
            variant={{ md: "bold-xl", sm: "bold-lg" }}
            color={"brand-primary"}
          >
            Previous Booking
          </Typography>
        </CardContent>

        <VisitCardInfo visitData={previousBooking as VisitData} />

        <Divider dashed className="mx-4" />

        <CardContent>
          <Button
            variant={"primary"}
            color={"CTA"}
            className="w-full"
            onClick={onRebook}
            loading={loading}
          >
            Rebook
          </Button>
        </CardContent>
      </Card>
    </IfElse>
  );
};

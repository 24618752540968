import { View } from "@/components/ui";
import {
  ShoppingCart,
  DotOutline,
  ShieldCheck,
} from "@/components/shared/icons";
import { formatNumber } from "@/lib/helpers/number";
import { Typography } from "@/components/shared/typography";

export function ServiceHeroSection({
  title,
  descriptions,
  bookingTotal,
}: {
  title: string;
  descriptions: string[];
  bookingTotal: number;
}) {
  return (
    <View className="flex w-full flex-1">
      <View className="mt-2 flex flex-col gap-2 max-lg:mx-4">
        <View className="flex flex-row items-start">
          <View className="flex flex-col gap-2">
            <Typography variant={{ sm: "h7", md: "h6" }}>{title}</Typography>

            <View className="flex flex-row gap-1">
              <View className="flex flex-row">
                <ShoppingCart
                  weight="fill"
                  className="size-4 text-primary"
                  size={16}
                />
                <Typography variant="label-md">{`${formatNumber(bookingTotal)} Bookings`}</Typography>
              </View>

              <View className="flex flex-row">
                <ShieldCheck
                  weight="fill"
                  className="size-4 text-primary"
                  size={16}
                />
                <Typography variant="label-md">Luce Guarantee</Typography>
              </View>
            </View>
          </View>
        </View>

        <View className="flex flex-col">
          {descriptions.map((desc) => (
            <View className="flex flex-row" key={desc}>
              <DotOutline
                size={18}
                weight="fill"
                className="text-foreground-intermediate"
              />
              <Typography
                variant="body-lg"
                color="foreground-intermediate"
                key={desc}
              >
                {`${desc}`}
              </Typography>
            </View>
          ))}
        </View>
      </View>
    </View>
  );
}

import { View, Image } from "@/components/ui";
import { cn } from "@/lib/utils";
import { NumChangeWidget } from "../../num-change-widget";
import { Typography } from "../../typography";
import { IfElse } from "../../if-else";
import { IconButton } from "@/components/ui/icon-button";
import { useBookingState } from "@/store/booking/useBookingState";

export const BookingItemCard = ({
  title,
  subTitle,
  description,
  value,
  selected,
  img,
  onAdd,
  onMinus,
  max,
}: {
  title: string;
  subTitle?: string;
  description?: string;
  value: number;
  selected?: boolean;
  img: string;
  onAdd(): void;
  onMinus(): void;
  max: number;
}) => {
  //Add the condition here so at other component don't need to check the condition anymore,
  //this component only rendered on aircon and upholstery

  const { setBookingState } = useBookingState();
  const isWABooking = title === "Portable" || title === "Window";

  return (
    <View className="relative">
      <View
        className={
          "absolute -left-2 flex h-full w-1 items-center justify-center rounded-md"
        }
      >
        <View
          className={cn("h-6 w-full rounded-full", {
            "bg-primary": selected,
          })}
        />
      </View>
      <View className="flex flex-row items-center gap-4 border-b border-secondary-border py-4">
        <View className="h-12 w-24 overflow-hidden rounded-sm">
          <Image src={img} />
        </View>
        <View className="flex-1">
          <Typography variant="label-lg" className="pb-1">
            {title}
          </Typography>
          <Typography
            variant="label-md"
            className="pb-1"
            color={"secondary-text"}
          >
            {subTitle}
          </Typography>
          <Typography variant="bold-lg">{description}</Typography>
        </View>
        <IfElse
          if={isWABooking}
          else={
            <View className="w-24">
              <NumChangeWidget
                value={value}
                onAdd={onAdd}
                onMinus={onMinus}
                addDisabled={!!max && max === value}
              />
            </View>
          }
        >
          <IconButton
            iconName={"caretRight"}
            variant="ghost"
            color="foreground"
            onClick={() => setBookingState({ airconSalesModalOpen: true })}
          />
        </IfElse>
      </View>
    </View>
  );
};

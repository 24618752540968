import {
  SelectGroup,
  SelectItem,
  View,
  Image,
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
} from "@/components/ui";
import { nations } from "@/constants";
import type { CountryCode } from "@/lib/country-flags";
import { getCountryFlagUrl } from "@/lib/country-flags";
import { Typography } from "../../typography";
import type { PhoneNumberSelectGroupProps } from "..";
import { useEffect, useState } from "react";

export const PhoneNumberSelectGroup = ({
  nationCode,
  nationShort,
  onChange,
  trigger,
  disabled,
  value,
}: PhoneNumberSelectGroupProps) => {
  const [defaultValue, setDefaultValue] = useState(value || "SG/65");

  useEffect(() => {
    if (value) {
      setDefaultValue(value);
    } else if (nationShort && nationCode) {
      setDefaultValue(`${nationShort}/${nationCode}`);
    }
  }, [value, nationShort, nationCode]);

  return (
    <Select
      onValueChange={(code) => {
        onChange(code);
        setDefaultValue(code);
        trigger?.();
      }}
      value={defaultValue}
      disabled={disabled}
      defaultValue={defaultValue}
    >
      <SelectTrigger
        className="w-28 rounded-none border-0 border-r"
        disabled={disabled}
      >
        <SelectValue placeholder="">
          <View className="flex flex-row items-center">
            <View className="mr-2">
              <Image
                src={getCountryFlagUrl(
                  nationShort.toLowerCase() as CountryCode,
                )}
                width={24}
                height={17}
                className="shrink-0"
              />
            </View>
            <Typography
              color="secondary-text"
              variant="label-md"
            >{`+${nationCode}`}</Typography>
          </View>
        </SelectValue>
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {nations
            .sort((a, b) => Number(a.tel) - Number(b.tel))
            .map((item) => {
              return (
                <SelectItem
                  value={item.short + "/" + item.tel}
                  label={item.short}
                  key={item.short}
                >
                  <View className="flex flex-row items-center gap-2 pr-2">
                    <Image
                      src={getCountryFlagUrl(
                        item.short.toLowerCase() as CountryCode,
                      )}
                      width={24}
                      height={17}
                    />
                    <Typography variant="body-lg" color="secondary-text">
                      +{item.tel}
                    </Typography>
                  </View>
                </SelectItem>
              );
            })}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};

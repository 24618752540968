import { View, Badge } from "@/components/ui";
import { Card, CardContent } from "@/components/ui/card";
import { cn } from "@/lib/utils";
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import React from "react";
import { Typography } from "../typography";

const accountInfoCardVariants = cva("flex justify-between p-4", {
  variants: {
    direction: {
      col: "flex-col",
      row: "flex-row",
    },
  },
  defaultVariants: {
    direction: "row",
  },
});

type AccountInfoCardVariantProps = VariantProps<typeof accountInfoCardVariants>;

interface AccountInfoCardProps extends AccountInfoCardVariantProps {
  primary: boolean;
  header: string;
  bodyText: string | number;
  subBodyText: string;
  actions?: React.ReactNode;
  colBodyText?: boolean;
}

export function AccountInfoCard({
  header,
  primary,
  bodyText,
  subBodyText,
  actions,
  direction,
  colBodyText,
}: AccountInfoCardProps) {
  return (
    <Card>
      <CardContent className={accountInfoCardVariants({ direction })}>
        <View className="flex flex-col gap-2 p-2">
          <View className="flex flex-row items-center gap-2">
            <Typography variant="body-xl">{header}</Typography>
            {primary && <Badge variant="primary">Default</Badge>}
          </View>
          <View
            className={cn(
              "flex gap-2",
              colBodyText ? "flex-col" : "flex-row",
              "line-clamp-1",
            )}
          >
            <View className="md:max-w-[230px]">
              <Typography
                variant="body-lg"
                color="muted-foreground"
                numberOfLines={1}
                className="truncate"
              >
                {bodyText}
              </Typography>
            </View>
            <Typography
              color="muted-foreground"
              variant="body-lg"
              className="truncate"
              numberOfLines={1}
            >
              {subBodyText}
            </Typography>
          </View>
        </View>

        <View className="flex flex-row gap-2 py-2">{actions}</View>
      </CardContent>
    </Card>
  );
}

import { iconMap } from "@/components/shared/icons/icon-map";
import { Typography } from "@/components/shared/typography";
import { View } from "@/components/ui";

export const ShareButton = ({
  iconName,
  onClick,
  label,
}: {
  iconName:
    | "whatsappLogo"
    | "telegramLogo"
    | "messengerLogo"
    | "instagramLogo"
    | "chatDots";
  onClick: () => void;
  label: string;
}) => {
  const Icon = iconMap[iconName];

  return (
    <View
      className="flex flex-1 cursor-pointer flex-col items-center gap-2"
      onClick={onClick}
    >
      <View className="rounded-full border border-button-cta2-border px-4 py-2">
        <Icon size={24} className="color-button-cta2" />
      </View>
      <Typography variant="body-md" className="text-foreground-intermediate">
        {label}
      </Typography>
    </View>
  );
};

import * as React from "react";
import type { SVGProps } from "react";
const SvgUserProfileIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" {...props}>
    <rect width={32} height={32} rx={16} />
    <path
      d="M16 6C13.38 6 11.25 8.13 11.25 10.75C11.25 13.32 13.26 15.4 15.88 15.49C15.96 15.48 16.04 15.48 16.1 15.49C16.12 15.49 16.13 15.49 16.15 15.49C16.16 15.49 16.16 15.49 16.17 15.49C18.73 15.4 20.74 13.32 20.75 10.75C20.75 8.13 18.62 6 16 6Z"
      fill="currentColor"
    />
    <path
      d="M21.0809 18.1499C18.2909 16.2899 13.7409 16.2899 10.9309 18.1499C9.66094 18.9999 8.96094 20.1499 8.96094 21.3799C8.96094 22.6099 9.66094 23.7499 10.9209 24.5899C12.3209 25.5299 14.1609 25.9999 16.0009 25.9999C17.8409 25.9999 19.6809 25.5299 21.0809 24.5899C22.3409 23.7399 23.0409 22.5999 23.0409 21.3599C23.0309 20.1299 22.3409 18.9899 21.0809 18.1499Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgUserProfileIcon;

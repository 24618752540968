import * as Sentry from "@sentry/react";
import { app } from "@/config/env";
import { isLocal } from "@/lib/utils";
import { SENTRY_IGNORE_STRS } from "@/constants/sentry";

export function init(): void {
  if (isLocal) {
    return;
  }

  if (app.SENTRY_DSN) {
    Sentry.init({
      environment: app.ENV,
      dsn: app.SENTRY_DSN,
      tracesSampleRate: 1.0,
      autoSessionTracking: true,
      beforeSend: (event) => {
        const error =
          event.exception?.values && event.exception.values.length > 0
            ? event.exception.values[0].value
            : {};
        if (
          error instanceof Error &&
          SENTRY_IGNORE_STRS.some((str) =>
            error.message?.toLowerCase().includes(str),
          )
        ) {
          window.location.reload();
          return null;
        }
        return event;
      },
    });
  }
}

type SentryCaptureExceptionOptions = Parameters<
  typeof Sentry.captureException
>[1];

export function collectError(
  error: unknown,
  options?: SentryCaptureExceptionOptions,
): void {
  if (isLocal) {
    return;
  }

  Sentry.captureException(error, options);
}

export function setUserContext(id: string, email: string): void {
  Sentry.setUser({
    id,
    email,
  });
}

export function resetUserContext() {
  Sentry.setUser(null);
}

export { Sentry };

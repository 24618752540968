import { Typography } from "../../typography";
import { iconMap, type IconNames } from "../../icons/icon-map";
import { View } from "@/components/ui";
import { cn } from "@/lib/utils";

export function ActionButton({
  label,
  iconName,
  onClick,
  variant = "regular",
}: {
  label: string;
  iconName: IconNames;
  onClick: () => void;
  variant?: "regular" | "danger";
}) {
  const Icon = iconMap[iconName];

  return (
    <View
      className="flex flex-1 cursor-pointer flex-col items-center gap-1"
      onClick={onClick}
    >
      <View
        className={cn(
          "rounded-full border bg-transparent px-4 py-2",
          variant === "regular" && "border-border-medium",
          variant === "danger" && "border-danger-border",
        )}
      >
        <Icon
          size={28}
          className={
            variant === "regular"
              ? "text-foreground-intermediate"
              : "text-danger"
          }
        />
      </View>
      <Typography
        variant="body-md"
        color={variant === "regular" ? "foreground-intermediate" : "danger"}
        numberOfLines={1}
        align="center"
      >
        {label}
      </Typography>
    </View>
  );
}

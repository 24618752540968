import {
  VisitStatusEnum,
  PackageDepartmentEnum,
  PackageServiceTypeEnum,
  type VisitsByClientQuery,
} from "@/__generated__/graphql";
import { Button, Card, CardContent, View } from "@/components/ui";
import { formatPrice } from "@/lib/helpers/number";
import { packageServiceTypeLabel } from "@/lib/service/helpers";
import type { ReactNode } from "react";
import { useCallback, useMemo } from "react";
import { WorkerAvatar } from "./worker-avatar";
import { VisitStatusBadge } from "./visit-status-badge";
import { handleWhatsappNumber } from "@/lib/platform";
import { cn } from "@/lib/utils";
import { VisitRating } from "./visit-rating";
import {
  formatVisitAddress,
  formatVisitSchedule,
  visitWorkerFullName,
} from "../utils";
import { Typography } from "../../typography";

export type VisitWorker = {
  fullName: string;
  phoneNumber: string | null;
  avatarUrl: string | null;
  id: string;
};

export type VisitData = {
  id: string;
  status: VisitStatusEnum;
  department: PackageDepartmentEnum;
  serviceType: PackageServiceTypeEnum;
  serviceDate: Date;
  startTime: string;
  endTime: string;
  address: string;
  rating: number;
  worker: VisitWorker;
  value: number;
};

interface Props {
  visitData: VisitData;
  handleViewDetail(visitId: string): void;
  handleSetSchedule?(visitId: string): void;
  handleRateVisit?(visitId: string, rate?: number): void;
  handleRebook?(department: PackageDepartmentEnum): void;
  handleReportVisit?(visitId: string): void;
}

export function mapToVisitData(
  visit: VisitsByClientQuery["visitsByClient"][number],
): VisitData {
  const job = visit.job;
  const fullAddress = job?.package.fullAddress ?? "";
  const unitNumber = job?.package.unitNumber ?? "";
  const postalCode = job?.package.postalCode ?? "";

  const cardData: VisitData = {
    id: visit.id,
    status: job ? visit.status : VisitStatusEnum.Started,
    department: job?.package.department ?? PackageDepartmentEnum.HomeCleaning,
    serviceType:
      job?.package.serviceType ?? PackageServiceTypeEnum.HomeCleaning,
    serviceDate: new Date(visit.serviceDate),
    startTime: String(visit.startTime),
    endTime: String(visit.endTime),
    value: visit.clientBillingValue ?? 0,
    address: formatVisitAddress(fullAddress, unitNumber, postalCode),
    worker: {
      id: visit.worker?.id ?? "",
      fullName: visitWorkerFullName(visit.status, visit.worker),
      phoneNumber: visit.worker?.contactNumber ?? null,
      avatarUrl: visit.worker?.avatarUrl ?? null,
    },
    rating: visit.rating ?? 0,
  };

  return cardData;
}

// removed CardContent component for reuse in PreviousBooking component.
export function VisitCardInfo({ visitData }: { visitData: VisitData }) {
  return (
    <View className="flex flex-col">
      <View className="p-4">
        <View className="flex flex-row justify-between">
          <Typography variant="label-xl">
            {packageServiceTypeLabel(
              visitData.serviceType,
              visitData.department,
            )}
          </Typography>
          <Typography variant="label-2xl" color="brand-primary">
            {formatPrice(visitData.value, 0)}
          </Typography>
        </View>
        <Typography
          variant="body-md"
          color="foreground-intermediate"
          numberOfLines={1}
        >
          {formatVisitSchedule(
            visitData.startTime,
            visitData.endTime,
            visitData.serviceDate,
          )}
        </Typography>
        <Typography
          variant="body-lg"
          color="foreground-intermediate"
          numberOfLines={1}
        >
          {visitData.address}
        </Typography>
      </View>
      <Divider />
      <View className="flex flex-row justify-between gap-2 px-4 py-2">
        <View className="max-w-[50%]">
          <WorkerAvatar
            fullName={visitData.worker.fullName}
            avatarUrl={visitData.worker.avatarUrl}
          />
        </View>
        <VisitStatusBadge status={visitData.status} />
      </View>
    </View>
  );
}

export function VisitCard({
  visitData,
  handleViewDetail,
  handleSetSchedule,
  handleRebook,
  handleRateVisit,
  handleReportVisit,
}: Props) {
  const handleContactProfessional = () => {
    if (visitData.worker.phoneNumber) {
      handleWhatsappNumber(visitData.worker.phoneNumber);
    }
  };

  const buttonContactProfessional = useMemo(
    () => (
      <Button
        variant="secondary"
        color="success"
        key="buttonContactProfessional"
        fullWidth="full"
        size="md"
        iconSize="md"
        onClick={handleContactProfessional}
        iconName="whatsappIcon"
        iconColor="fill-success"
        children="Contact Professional"
      />
    ),
    [handleContactProfessional],
  );

  const viewDetailButton = useCallback(
    (label = "View Details") => {
      const isPrimaryButton = [
        VisitStatusEnum.Expired,
        VisitStatusEnum.CancelledByClient,
        VisitStatusEnum.CancelledByOps,
        VisitStatusEnum.CancelledByWorker,
        VisitStatusEnum.MissedByWorker,
        VisitStatusEnum.Scheduled,
      ].includes(visitData.status);
      return (
        <Button
          variant={isPrimaryButton ? "primary" : "secondary"}
          color={isPrimaryButton ? "CTA" : "CTA2"}
          key="view-detail-btn"
          size="md"
          fullWidth="full"
          onClick={() => handleViewDetail(visitData.id)}
          children={label}
        />
      );
    },
    [handleViewDetail, visitData.id, visitData.status],
  );

  const renderButton = useMemo(() => {
    const buttons: ReactNode[] = [];

    if (visitData.status === VisitStatusEnum.Started) {
      return [
        <Typography
          variant="body-md"
          color="foreground-intermediate"
          className="max-w-[50%]"
        >
          Please contact the professional something went wrong.
        </Typography>,
        <Button
          variant="secondary"
          color="CTA2"
          key="report"
          iconName="warningCircle"
          iconColor="CTA2"
          iconSize="md"
          fullWidth="full"
          className="max-w-[50%]"
          size="md"
          onClick={() => handleReportVisit?.(visitData.id)}
          children="Report Issue"
        />,
      ];
    }

    if (visitData.status === VisitStatusEnum.Scheduled) {
      if (visitData.worker.phoneNumber) buttons.push(buttonContactProfessional);

      buttons.push(viewDetailButton("Manage Appointment"));
    }

    if (
      [
        VisitStatusEnum.PendingClientSchedule,
        VisitStatusEnum.UnableToSchedule,
      ].includes(visitData.status)
    ) {
      buttons.push(viewDetailButton());
      buttons.push(
        <Button
          variant="primary"
          color="CTA"
          key="set-schedule-btn"
          className="max-w-[50%]"
          size="md"
          onClick={() => handleSetSchedule?.(visitData.id)}
          fullWidth={{ sm: "full", md: "none" }}
          children="Set Schedule"
        />,
      );
    }

    if (
      [
        VisitStatusEnum.Expired,
        VisitStatusEnum.CancelledByClient,
        VisitStatusEnum.CancelledByOps,
        VisitStatusEnum.CancelledByWorker,
        VisitStatusEnum.MissedByWorker,
      ].includes(visitData.status)
    ) {
      buttons.push(
        <Button
          variant="secondary"
          color="CTA2"
          key="report"
          iconName="warningCircle"
          iconColor="CTA2"
          iconSize="md"
          fullWidth="full"
          className="max-w-[50%]"
          size="md"
          onClick={() => handleReportVisit?.(visitData.id)}
          children="Report Issue"
        />,
      );
      buttons.push(viewDetailButton());
    }
    if (visitData.status === VisitStatusEnum.Completed) {
      buttons.push(viewDetailButton());
      buttons.push(
        <Button
          variant="primary"
          color="CTA"
          key="rebook-btn"
          fullWidth={{ sm: "full", md: "none" }}
          className="max-w-[50%]"
          size="md"
          onClick={() => handleRebook?.(visitData.department)}
          children="Rebook"
        />,
      );
    }

    return buttons;
  }, [visitData.id, visitData.status, visitData.worker, visitData.department]);

  return (
    <Card radius="xl" onClick={() => handleViewDetail(visitData.id)}>
      <VisitCardInfo visitData={visitData} />
      <Divider />
      {renderButton.length !== 0 && (
        <CardContent className="gap-4 pt-0">
          <View
            className={cn(
              "mt-2 flex flex-row justify-end gap-2",
              visitData.rating ? "py-0" : "pt-0",
            )}
          >
            {renderButton}
          </View>
        </CardContent>
      )}
      {visitData.status === VisitStatusEnum.Completed && (
        <>
          <Divider />
          <CardContent className="pt-0">
            <VisitRating
              rating={visitData.rating}
              onClick={(rate) => handleRateVisit?.(visitData.id, rate)}
            />
          </CardContent>
        </>
      )}
    </Card>
  );
}

function Divider() {
  return (
    <View className="native:border-b-border native:border-solid mx-4 border-b border-dashed border-border-medium" />
  );
}

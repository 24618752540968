import type { UseFormReturn } from "react-hook-form";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const bookingInfoSchema = z.object({
  name: z.string(),
  phoneNumber: z.string(),
  email: z.string(),
  fullAddress: z.string(),
  postalCode: z.string(),
  accessMode: z.string(),
  accessInstructions: z.string(),
  note: z.string(),
});

export type BookingInfoFormData = z.infer<typeof bookingInfoSchema>;

export interface BookingInfoFormReturn
  extends UseFormReturn<BookingInfoFormData> {
  defaultValues: BookingInfoFormData;
}

export const initialValues: BookingInfoFormData = {
  name: "",
  phoneNumber: "",
  email: "",
  fullAddress: "",
  postalCode: "",
  accessMode: "",
  accessInstructions: "",
  note: "",
};

export const useBookingInfoForm = (
  values?: BookingInfoFormData,
): BookingInfoFormReturn => ({
  defaultValues: initialValues,
  ...useForm<BookingInfoFormData>({
    mode: "onChange",
    defaultValues: values || initialValues,
    resolver: zodResolver(bookingInfoSchema),
  }),
});

import { createRequestFactory } from "@/lib/request-factory";
import {
  DeleteContactDocument,
  type DeleteContactMutation,
  type DeleteContactMutationVariables,
} from "@/__generated__/graphql";

type Response = {
  result: boolean;
};

export const useDeleteContactStore = createRequestFactory<
  DeleteContactMutation,
  Response,
  DeleteContactMutationVariables
>({
  method: "mutation",
  graphqlDocument: DeleteContactDocument,
  transformFunction: (res) => {
    if (res.deleteContact?.result) {
      return { result: res.deleteContact.result } as Response;
    }
    throw new Error("Delete contact failed");
  },
});

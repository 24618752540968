import React from "react";
import ReactDOM from "react-dom/client";
import App from "./router";
import "../assets/global.css";
import "../assets/hamburger.css";
import * as Sentry from "@/lib/monitoring/sentry";
import { getFirebaseApp } from "@/lib/firebase";
import { initHotjar } from "@/lib/monitoring/hotjar";
import { PosthogAnalytics } from "@/lib/monitoring/posthog";

Sentry.init();
getFirebaseApp();
initHotjar();
PosthogAnalytics.init();

const el = document.getElementById("root");

if (!el) {
  throw new Error("Unable to find root element");
}

ReactDOM.createRoot(el).render(<App />);

import { View } from "@/components/ui";
import type { ReactNode } from "react";
import type { Icon } from "@/components/shared/icons";
import { Row } from "@/components/ui/layout";
import { Typography } from "../typography";

export function ListHeading({
  title,
  subtitle,
  icon: IconComponent,
  action,
}: {
  title: string;
  subtitle?: string;
  icon?: Icon;
  action?: ReactNode;
}) {
  return (
    <View className="flex flex-row justify-between">
      <Row gap="md" items="center">
        {!!IconComponent && (
          <IconComponent size={24} weight="fill" className="color-primary" />
        )}
        <View>
          <Typography variant={{ sm: "label-xl", md: "label-2xl" }}>
            {title}
          </Typography>
          {subtitle && (
            <Typography variant="bold-lg" color="secondary-text">
              {subtitle}
            </Typography>
          )}
        </View>
      </Row>
      {action}
    </View>
  );
}

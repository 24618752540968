import { View, Button } from "@/components/ui";
import { ReportIssueForm } from "../report-issue-form";
import type { PackageIssueScreenProps } from "./type";
import { MobileFullscreenModal } from "@/components/shared/mobile-fullscreen-modal";
import { Typography } from "@/components/shared/typography";

export function PackageIssueScreen({
  open,
  title,
  description,
  loading,
  onClose,
  onSubmit,
  form,
}: PackageIssueScreenProps) {
  return (
    <MobileFullscreenModal
      open={open}
      onClose={onClose}
      title={title}
      FooterComponent={
        <View className="flex w-full flex-1 flex-row gap-2">
          <Button
            variant="tertiary"
            color="CTA2"
            fullWidth="full"
            onClick={onClose}
            children="Cancel"
          />
          <Button
            variant="primary"
            color="CTA"
            fullWidth="full"
            onClick={onSubmit}
            loading={loading}
            disabled={loading}
            children="Submit"
          />
        </View>
      }
    >
      <Typography color="brand-primary">{description}</Typography>
      <ReportIssueForm form={form} />
    </MobileFullscreenModal>
  );
}

import * as React from "react";

import { cn } from "@/lib/utils";
import { View } from "../view";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  onChangeText?: (text: string) => void;
}

interface SuffixInputProps {
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
}

const RcInput = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, onChangeText, onChange, ...props }, ref) => {
    const onChangeInner = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChangeText) {
        onChangeText(e.target.value);
      } else {
        onChange?.(e);
      }
    };

    return (
      <input
        type={type}
        onChange={onChangeInner}
        className={cn(
          "flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 web:ring-offset-background web:focus-visible:outline-none web:focus-visible:ring-ring",
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);

const Input = React.forwardRef<HTMLInputElement, InputProps & SuffixInputProps>(
  ({ className, suffix, prefix, ...props }, ref) => {
    if (suffix || prefix) {
      return (
        <View
          className={cn(
            "flex flex-row items-center overflow-hidden rounded-lg border border-secondary-border bg-background",
            className,
          )}
        >
          {prefix && <View className="pl-3">{prefix}</View>}
          <RcInput
            className={cn((prefix || suffix) && "border-0")}
            {...props}
            ref={ref}
          />
          {suffix && <View className="pr-3">{suffix}</View>}
        </View>
      );
    }

    return <RcInput {...props} className={className} ref={ref} />;
  },
);

Input.displayName = "Input";

export { Input };

import { useWindowDimensions } from "@/components/hooks/use-window-dimension";
import { Card, CardContent, Skeleton, View } from "@/components/ui";
import { useEffect, useMemo } from "react";
import { CaretRight, SealPercent } from "@/components/shared/icons";
import { useInvoiceDetailStore } from "@/store/invoices/invoiceDetail";
import { formatPrice } from "@/lib/helpers/number";
import { useRoute } from "@/components/shared/router";
import { Typography } from "@/components/shared/typography";
import { useAvailableClientVouchersStore } from "@/store/invoices/useAvailableClientVouchers";
import { useAuthState } from "@/store/auth";
import { useRemoveVoucherInvoiceStore } from "@/store/invoices/removeVoucherInvoice";
import { VoucherDiscountTypeEnum } from "@/__generated__/graphql";
import { useShallow } from "zustand/react/shallow";

export function AvailableVoucher({ onClick }: { onClick: () => void }) {
  const { invoiceId, appliedVoucher, balance } = useInvoiceDetailStore(
    useShallow(({ data }) => ({
      invoiceId: data?.id as string,
      balance: data?.balance ?? 0,
      appliedVoucher: data?.appliedVoucher ?? null,
    })),
  );

  const {
    fetch: getVouchers,
    data,
    loading,
  } = useAvailableClientVouchersStore();

  const { fetch: removeVoucher, loading: loadingRemoveVoucher } =
    useRemoveVoucherInvoiceStore();

  const clientId = useAuthState((state) => state.data.userInfo.id);

  const { isMobile } = useWindowDimensions();

  const { replace } = useRoute();

  useEffect(() => {
    if (clientId) {
      getVouchers({
        requestPayload: {
          clientId,
        },
      });
    }
  }, [clientId]);

  const handleRemoveVoucher = async () => {
    const res = await removeVoucher({
      requestPayload: {
        input: {
          invoiceId,
        },
      },
    });
    if (!res.data) {
      return replace({
        pageKey: "invoices",
      });
    }

    replace({
      pageKey: "invoiceDetail",
      params: {
        id: res.data.id,
      },
    });
  };

  const numberVouchers = data?.length ?? 0;

  const discountValue = useMemo(() => {
    let discount = appliedVoucher?.discountValue ?? 0;
    if (appliedVoucher?.discountType === VoucherDiscountTypeEnum.Percentage) {
      discount = (balance * appliedVoucher.discountValue) / 100;
    }

    return discount;
  }, [appliedVoucher]);

  if (loading || loadingRemoveVoucher) {
    return <Skeleton className="h-6 w-full" />;
  }

  if (numberVouchers === 0) {
    return null;
  }

  return (
    <Card onClick={() => isMobile && !appliedVoucher && onClick()}>
      <CardContent className="flex flex-row items-center justify-between gap-2 p-3">
        <SealPercent className="size-5 text-green-400" weight="fill" />
        {appliedVoucher ? (
          <View
            className="flex flex-1 cursor-pointer flex-row items-center gap-1"
            onClick={onClick}
          >
            <Typography variant="label-lg">
              Voucher applied. You saved{" "}
              <Typography as="span" variant="label-lg" color="success">
                {formatPrice(discountValue, 0)}
              </Typography>
            </Typography>
            <CaretRight className="size-4" />
          </View>
        ) : (
          <View className="flex-1">
            <Typography variant="body-lg">
              You have {numberVouchers} vouchers waiting for you!
            </Typography>
          </View>
        )}
        {appliedVoucher ? (
          <View onClick={handleRemoveVoucher}>
            <Typography variant="label-lg" color="danger">
              Remove
            </Typography>
          </View>
        ) : (
          <View
            className="flex cursor-pointer flex-row items-center gap-2"
            onClick={onClick}
          >
            <Typography
              variant="label-lg"
              color="brand-primary"
              className="hidden md:block"
            >
              Apply Voucher
            </Typography>
            <CaretRight className="size-4" />
          </View>
        )}
      </CardContent>
    </Card>
  );
}

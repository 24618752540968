import { View } from "@/components/ui";
import { SuggestionServiceList } from "./suggestion-service-list";
import { ListHeading } from "@/components/shared/list-header";
import type { Icon } from "@/components/shared/icons";
import type { RecommendedService } from "@/components/shared/recommended-card";
import ManiPediCover from "@/assets/images/booking/mani-pedi-cover.png";

const services: RecommendedService[] = [
  {
    title: "Aircon General Servicing",
    imageSrc:
      "https://luce-production.sgp1.cdn.digitaloceanspaces.com/aircon-general-servicing-cover.png",
    price: 16,
    recommended: true,
    name: "aircon-service",
  },
  {
    title: "Mattress Cleaning",
    imageSrc:
      "https://luce-production.sgp1.cdn.digitaloceanspaces.com/mattress-cover.png",
    price: 81,
    recommended: false,
    name: "mattress-cleaning",
  },
  {
    title: "Home Beauty",
    imageSrc: ManiPediCover,
    price: 84,
    recommended: false,
    name: "home-beauty",
  },
  {
    title: "Sofa Cleaning",
    imageSrc:
      "https://luce-production.sgp1.cdn.digitaloceanspaces.com/sofa-cover.png",
    price: 45,
    recommended: false,
    name: "sofa-cleaning",
  },
];

export function SuggestionServiceSection({
  title,
  icon,
}: {
  title: string;
  icon: Icon;
}) {
  return (
    <View className="flex flex-col gap-2">
      <ListHeading title={title} icon={icon} />
      <SuggestionServiceList items={services} />
    </View>
  );
}

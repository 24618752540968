import type { InvoiceCardData } from "@/components/shared/invoices/invoice-card";
import { InvoiceCard } from "@/components/shared/invoices/invoice-card";
import { useScrollRefresh } from "@/components/shared/scroll-content/scroll-refresh-context";
import { Button, Skeleton, View } from "@/components/ui";
import { usePaidInvoicesStore } from "@/store/invoices/paidInvoices";
import { useUnpaidInvoicesStore } from "@/store/invoices/unpaidInvoices";
import { InvoiceFilterStatusEnum } from "@/types/invoice";
import { getInvoiceFilters } from "../lib";
import { useAuthState } from "@/store/auth";
import { EmptyInvoice } from "../empty-invoice";
import { useEffect } from "react";
import { useRoute } from "@/components/shared/router";
import { useShallow } from "zustand/react/shallow";
import type { RequestPaginatedFactoryType } from "@/lib/request-factory";
import { IfElse } from "@/components/shared/if-else";
import { useIntl } from "react-intl";
import type { InvoicesByFiltersQueryVariables } from "@/__generated__/graphql";

type InvoiceListOptions = {
  useStore: RequestPaginatedFactoryType<
    InvoiceCardData,
    InvoicesByFiltersQueryVariables
  >;
  initialFilters: (
    clientId: string,
  ) => InvoicesByFiltersQueryVariables["filters"];
};

const listType: Record<InvoiceFilterStatusEnum, InvoiceListOptions> = {
  [InvoiceFilterStatusEnum.UNPAID]: {
    useStore: useUnpaidInvoicesStore,
    initialFilters: (clientId) =>
      getInvoiceFilters(clientId, InvoiceFilterStatusEnum.UNPAID),
  },
  [InvoiceFilterStatusEnum.PAID]: {
    useStore: usePaidInvoicesStore,
    initialFilters: (clientId) =>
      getInvoiceFilters(clientId, InvoiceFilterStatusEnum.PAID),
  },
};

export function InvoiceList({ type }: { type: InvoiceFilterStatusEnum }) {
  const intl = useIntl();
  const { refreshing, setRefreshing } = useScrollRefresh();
  const { push } = useRoute();
  const user = useAuthState((state) => state.data.userInfo);

  const { useStore, initialFilters } = listType[type];

  const {
    data: invoices,
    loading,
    fetchMore,
    refetch,
    pagination,
  } = useStore(useShallow((state) => state));

  const shouldShowLoadMore = pagination.total > invoices.length;

  useEffect(() => {
    if (user.id) {
      refetch({
        requestPayload: { filters: initialFilters(user.id) },
      });
    }
  }, [user.id, type, initialFilters]);

  useEffect(() => {
    if (refreshing) {
      refetch();
      setRefreshing(false);
    }
  }, [refreshing]);

  const onLoadMore = () => {
    fetchMore({
      requestPayload: {
        filters: initialFilters(user.id),
      },
    });
  };

  const handleViewInvoice = (invoiceId: string) => () => {
    push({
      pageKey: "invoiceDetail",
      params: {
        id: invoiceId,
      },
    });
  };

  return (
    <View className="mt-4 flex flex-col gap-4 px-4 md:px-0">
      {loading ? (
        <>
          <Skeleton className="h-[120px] w-full" />
        </>
      ) : (
        <IfElse if={!!invoices?.length} else={<EmptyInvoice />}>
          <View className="flex flex-col gap-4">
            {invoices.map((invoice) => (
              <InvoiceCard
                key={invoice.id}
                {...invoice}
                onClick={handleViewInvoice(invoice.id)}
              />
            ))}
          </View>
        </IfElse>
      )}

      <IfElse if={shouldShowLoadMore}>
        <Button
          variant="tertiary"
          color="CTA2"
          fullWidth="full"
          loading={loading}
          onClick={onLoadMore}
          children={intl.formatMessage({
            defaultMessage: "Load more",
            id: "load-more",
          })}
        />
      </IfElse>
    </View>
  );
}

import { useEffect, useRef } from "react";
import { rudderanalytics } from "@/lib/rudderstack";
import { getIdentifiersFromStorage, storeIdentifiers } from "@/lib/identifiers";
import { getCookie } from "@/lib/cookie";
import { GA_ID, LAST_GA_ID } from "@/constants";
import { debounce } from "@/lib/utils";
import { getSalesPersonData } from "@/lib/get-sales-person-data";
import { sendAnalyticData } from "@/lib/monitoring/analytics";
import { usePathname } from "@/components/hooks/use-pathname";
import { getTrackingKey } from "@/lib/monitoring/oci/index";

// why it is a component: we need to do tracking in App layer but can't use "useLocation" function as it's not wrapped by Browserrouter
export const EventTracking = () => {
  const lastTrackedPercentage = useRef<number>(0);
  const pathname = usePathname();

  const getScrollPercentage = () => {
    const scrollPosition = window.scrollY;
    const scrollHeight = document.body.scrollHeight;
    const windowHeight = window.innerHeight;
    return (scrollPosition / (scrollHeight - windowHeight)) * 100;
  };

  const storeIdentifiersAndTrackEvent = async () => {
    await storeIdentifiers();
    const identifiers = await getIdentifiersFromStorage();
    rudderanalytics.track("page_view", {
      ...identifiers,
    });
  };

  useEffect(() => {
    lastTrackedPercentage.current = getScrollPercentage();
    // different with "page_view", it tracks every time when route changes.
    sendAnalyticData({
      name: "full_page_view",
    });
  }, [pathname]);

  useEffect(() => {
    storeIdentifiersAndTrackEvent();
    const handleScroll = () => {
      const thresholds = [90, 75, 50, 25];
      const scrollPercentage = getScrollPercentage();

      for (const threshold of thresholds) {
        if (
          scrollPercentage >= threshold &&
          lastTrackedPercentage.current < threshold
        ) {
          sendAnalyticData({
            name: "scroll",
            values: {
              threshold,
            },
          });
          lastTrackedPercentage.current = scrollPercentage;
          break;
        }
      }
    };
    const debounceHandleScroll = debounce(handleScroll, 200);
    window.addEventListener("scroll", debounceHandleScroll);
    return () => window.removeEventListener("scroll", debounceHandleScroll);
  }, []);

  useEffect(() => {
    getSalesPersonData();
    // this timer is to solve: race condition between app render and gaId set into cookie
    setTimeout(() => {
      const lastGaId = getCookie(LAST_GA_ID);
      const gaId = getCookie(GA_ID);
      if (gaId && lastGaId !== gaId) getTrackingKey();
    }, 1000);
  }, []);

  return null;
};

import { useBookingRouterInterceptor } from "@/components/hooks/use-booking-route";
import type { AddToCalendarProps } from "@/store/booking/useBookingState";
import { useBookingState } from "@/store/booking/useBookingState";
import type { Review } from "@/types/booking";
import { BookingCompleteScreen } from "./booking-complete-screen";
import { useClientReviewsStore } from "@/store/booking/clientReviews";
import { useEffect } from "react";
import { formatDate } from "@/lib/helpers/date";
import { addYears } from "date-fns";
import { getDepartment } from "@/lib/booking-lib";
import { type ServiceName } from "@/types/service";
import { useParams } from "@/components/hooks/use-params";

export type BookingCompleteScreenProps = {
  calendarEvent: AddToCalendarProps;
  reviews: Review[];
  cleanerInfo: {
    name: string;
    star: string;
    avatar: string;
  };
};

export const BookingComplete = () => {
  useBookingRouterInterceptor();
  const { name = "home-cleaning" } = useParams<{ name: ServiceName }>();
  const { serviceReviews, confirmedWorker, bookingInfo, selectedSlot } =
    useBookingState((state) => state.data);
  const { fetch: getReviews } = useClientReviewsStore();

  const safeGetTime = (date?: string, startTime?: string) => {
    if (date && startTime) return `${date}T${startTime}`;
    return "";
  };

  const calendarEvent: AddToCalendarProps = {
    title: serviceReviews.reviews[0]?.service ?? "-",
    description: bookingInfo?.note ?? "-",
    startTime: safeGetTime(selectedSlot?.date, selectedSlot?.startTime),
    endTime: safeGetTime(selectedSlot?.date, selectedSlot?.endTime),
    location: bookingInfo?.fullAddress ?? "-",
  };
  useEffect(() => {
    if (!serviceReviews?.reviews?.length) {
      getReviews({
        requestPayload: {
          filters: {
            startDate: formatDate(addYears(new Date(), -1)),
            endDate: formatDate(new Date()),
            department: getDepartment(name),
            limit: 3,
          },
        },
      });
    }
  }, []);
  return (
    <BookingCompleteScreen
      calendarEvent={calendarEvent}
      reviews={serviceReviews.reviews.slice(0, 3) || []}
      cleanerInfo={{
        name: `${confirmedWorker?.firstName || ""} ${confirmedWorker?.lastName || ""}`,
        star: String(confirmedWorker?.workerRating || 5),
        avatar: confirmedWorker?.avatarUrl || "",
      }}
    />
  );
};

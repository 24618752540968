import { SALES_NUMBER } from "@/constants";
import { handleOpenLink } from "../platform";

export const onChatSupport = async () => {
  const url = `https://wa.me/${SALES_NUMBER}?text=${encodeURIComponent(
    "Hi! I would like to speak to sales about booking a new appointment through your web application.",
  )}`;

  await handleOpenLink(url);
};

import { RescheduleVisitContent } from "./reschedule-visit-content";

export function RescheduleVisit({
  visitId,
  token,
}: {
  visitId?: string;
  token?: string;
}) {
  return <RescheduleVisitContent visitId={visitId} token={token} />;
}

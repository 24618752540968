import {
  PackageRecurrenceEnum,
  PackageUnitTypeEnum,
  ServiceTypeEnum,
  UnitSizeEnum,
} from "@/__generated__/graphql";
import type {
  AirconFrequencyOption,
  DurationOption,
  FrequencyOption,
} from "@/components/shared/service-radio-item/types";
import { PackageFrequencyGroupEnum } from "@/types/booking";
import ChemicalOverhaul from "@/assets/images/booking/chemical-overhaul.png";
import ChemicalWash from "@/assets/images/booking/chemical-wash.png";
import CondenserWash from "@/assets/images/booking/condenser-wash.png";
import GeneralServicing from "@/assets/images/booking/general-servicing.png";
import RepairDiagnostic from "@/assets/images/booking/repair-diagnostic.png";
import GasTopup from "@/assets/images/booking/gas-topup.png";
import Manicure from "@/assets/images/booking/manicure.png";
import ManiPedi from "@/assets/images/booking/mani-pedi.png";
import Pedicure from "@/assets/images/booking/pedicure.png";
import ClassicManiPedi from "@/assets/images/booking/classic-manipedi.png";
import ClassicManicure from "@/assets/images/booking/classic-manicure.png";
import ClassicPedicure from "@/assets/images/booking/classic-pedicure.png";
import GelManiClasssicPedi from "@/assets/images/booking/gel-mani-classic-pedi.png";
import GelManicure from "@/assets/images/booking/gel-manicure.png";
import GelManipedi from "@/assets/images/booking/gel-manipedi.png";
import GelPedicure from "@/assets/images/booking/gel-pedicure.png";
import GelRemoval from "@/assets/images/booking/gel-removal.png";
import NailArt from "@/assets/images/booking/nail-art.png";
import NailExtension from "@/assets/images/booking/nail-extension.png";
import FrenchTips from "@/assets/images/booking/french-tips.png";
import CallusTreatment from "@/assets/images/booking/callus-treatment.png";
import ExtendedMassage from "@/assets/images/booking/extended-massage.png";
import ExtensionRemoval from "@/assets/images/booking/extension-removal.png";
import KingBedMattress from "@/assets/images/booking/king-bed-mattress.png";
import QueenBedMattress from "@/assets/images/booking/queen-bed-mattress.png";
import BabyCot from "@/assets/images/booking/baby-cot.png";
import SingleBedMattress from "@/assets/images/booking/single-bed-mattress.png";
import Seater1 from "@/assets/images/booking/seater-1.png";
import Seater2 from "@/assets/images/booking/seater-2.png";
import Seater3 from "@/assets/images/booking/seater-3.png";
import Seater4 from "@/assets/images/booking/seater-4.png";
import Seater5 from "@/assets/images/booking/seater-5.png";
import Chair from "@/assets/images/booking/chair.png";
import ArmChair from "@/assets/images/booking/arm-chair.png";
import RugSmall from "@/assets/images/booking/rug-small.png";
import RugMedium from "@/assets/images/booking/rug-medium.png";
import RugLarge from "@/assets/images/booking/rug-large.png";
import RugExtraLarge from "@/assets/images/booking/rug-extra-large.png";
import BabyPram from "@/assets/images/booking/baby-pram.png";
import CarSeat from "@/assets/images/booking/child-seat.png";
import { PackageUnitTypeMapping } from "@/lib/line-items/helpers";
import Window from "@/assets/images/booking/window.png";
import Wall from "@/assets/images/booking/wall.png";
import Ceiling from "@/assets/images/booking/ceiling.png";
import Portable from "@/assets/images/booking/portable.png";
import Ducted from "@/assets/images/booking/ducted.png";
import AllUnits from "@/assets/images/booking/aircon-all-units.png";

import airconGeneralServicingCover from "@/assets/images/service-detail/aircon-general-servicing-cover.png";
import chemicalOverhaulCover from "@/assets/images/service-detail/chemical-overhaul-cover.png";
import chemicalWashCover from "@/assets/images/service-detail/chemical-wash-cover.png";
import compressorWashCover from "@/assets/images/service-detail/compressor-wash-cover.png";
import gasCover from "@/assets/images/service-detail/gas-cover.png";
import homeBeautyComboCover from "@/assets/images/service-detail/home-beauty-combo-cover.png";
import homeCleaningCover from "@/assets/images/service-detail/home-cleaning-cover.png";
import mattressCover from "@/assets/images/service-detail/mattress-cover.png";
import pramSeatCover from "@/assets/images/service-detail/pram-seat-cover.png";
import repairDiagnosticCover from "@/assets/images/service-detail/repair-diagnostic-cover.png";
import rugCleaningCover from "@/assets/images/service-detail/rug-cleaning-cover.png";
import sofaCover from "@/assets/images/service-detail/sofa-cover.png";

export const HomeCleaningFrequencyOptions = [
  [
    {
      title: "Weekly",
      subTitle: "from $18.00/hr",
      description: "Scheduled weekly cleaning, minimum 4 sessions",
      slogan: "Most Popular!",
      frequency: PackageFrequencyGroupEnum.WEEKLY,
      isRecurring: true,
      repeatEvery: PackageRecurrenceEnum.Week,
      repeatEveryTimes: 1,
    },
    {
      title: "Fortnightly",
      subTitle: "from $18.00/hr",
      description: "Scheduled cleaning every 2 weeks, minimum 4 sessions",
      frequency: PackageFrequencyGroupEnum.FORTNIGHT,
      isRecurring: true,
      repeatEvery: PackageRecurrenceEnum.Fortnight,
      repeatEveryTimes: 1,
    },
    {
      title: "> Once Per Week",
      subTitle: "from $18.00/hr",
      description:
        "For customized cleaning schedules and frequency, please contact us on WhatsApp",
      frequency: PackageFrequencyGroupEnum.WEEKLY,
      isRecurring: true,
      unselectable: true,
      repeatEvery: PackageRecurrenceEnum.Week,
      repeatEveryTimes: 1,
    },
  ],
  [
    {
      title: "Anytime Cleaning",
      subTitle: "from $23.00/hr",
      description: "One time cleaning scheduled in advance at your convenience",
      frequency: PackageFrequencyGroupEnum.AD_HOC,
      repeatEvery: PackageRecurrenceEnum.AdHoc,
      repeatEveryTimes: 0,
      upsell: "Try our Recurring Services & Save More!",
    },
    {
      title: "Same/Next Day",
      subTitle: "from $16.00/hr",
      description:
        "Discounted cleaning for same or next day service that are cannot be rescheduled or refunded once booked",
      frequency: PackageFrequencyGroupEnum.NEXT_DAY_AD_HOC,
      repeatEvery: PackageRecurrenceEnum.AdHoc,
      repeatEveryTimes: 0,
      upsell: "Try our Recurring Services & Save More!",
    },
  ],
];

export const AirconFrequencyOptions: AirconFrequencyOption[] = [
  {
    title: "Quarterly",
    slogan: "Most Popular",
    repeatEvery: PackageRecurrenceEnum.Year,
    repeatEveryTimes: 4,
    subTitle: "Save 10%",
  },
  {
    title: "Tri-yearly",
    repeatEvery: PackageRecurrenceEnum.Year,
    repeatEveryTimes: 3,
    subTitle: "Save 5%",
  },
  {
    title: "Just Once",
    repeatEvery: PackageRecurrenceEnum.AdHoc,
    repeatEveryTimes: 0,
    subTitle: "",
  },
];

export const HomeCleaningDurationOptions: DurationOption[] = [
  {
    title: "4 Hours",
    slogan: "Value for money",
    value: "4",
    duration: 4,
  },
  {
    title: "3 Hours",
    value: "3",
    duration: 3,
  },
];

export const HomeCleaningFrequencyOptionItems = [
  {
    id: "Recurring",
    title: "Repeat Cleaning",
    options: HomeCleaningFrequencyOptions[0],
  },
  {
    id: "OneTime",
    title: "One Time Cleaning",
    options: HomeCleaningFrequencyOptions[1],
  },
];

export const HomeCleaningUpsellOptions: FrequencyOption[] =
  HomeCleaningFrequencyOptions[0];

export const airconServiceTypeOptions = [
  {
    value: ServiceTypeEnum.GeneralServicing,
    title: "General Servicing",
    subtitle: "As low as $16.00/unit",
    thirdTitle: "5 types of units available",
    description:
      "Aircon servicing involves cleaning the filters, fan, and drainage, disinfecting, and checking for electrical problems.",
    imgUrl: GeneralServicing,
    repeatEvery: PackageRecurrenceEnum.Year,
    duration: 4,
  },
  {
    value: ServiceTypeEnum.ChemicalWash,
    title: "Chemical Wash",
    thirdTitle: "1 unit type available",
    subtitle: "As low as $68.00/unit",
    description:
      "Chemical wash removes dirt and mould by dismantling, cleaning, flushing, and checking gas pressure for poorly performing aircons.",
    imgUrl: ChemicalWash,
    repeatEvery: PackageRecurrenceEnum.AdHoc,
    duration: 0,
  },
  {
    value: ServiceTypeEnum.ChemicalOverhaul,
    title: "Chemical Overhaul",
    thirdTitle: "2 types of units available",
    subtitle: "As low as $110.00/unit",
    description:
      "Chemical overhaul is for aircons that aren't cold or have mould. It fully dismantles, cleans all parts, flushes drainage, checks gas pressure, and reassembles the unit for the most thorough cleaning.",
    imgUrl: ChemicalOverhaul,
    repeatEvery: PackageRecurrenceEnum.AdHoc,
    duration: 0,
  },
  {
    value: ServiceTypeEnum.CondenserWash,
    title: "Compressor Wash",
    thirdTitle: "1 unit type available",
    subtitle: "As low as $22.00/unit",
    description:
      "Compressor washing uses a jet wash to clean the coil and fan, removing dirt to improve airflow, cooling, and prevent damage.",
    imgUrl: CondenserWash,
    repeatEvery: PackageRecurrenceEnum.AdHoc,
    duration: 0,
  },
  {
    value: ServiceTypeEnum.GasTopUp,
    title: "Gas Top Up",
    thirdTitle: "All type available",
    // TODO: check this price
    subtitle: "As low as $80.00/unit",
    description:
      "Replenishes refrigerant levels to restore cooling efficiency, improve performance, and ensure optimal aircon function.",
    imgUrl: GasTopup,
    repeatEvery: PackageRecurrenceEnum.AdHoc,
    duration: 0,
  },
  {
    value: ServiceTypeEnum.RepairDiagnostic,
    title: "Repair & Diagnostic",
    subtitle: "As low as $60.00/unit",
    description:
      "Our service identifies and fixes AC issues, providing clear diagnosis, transparent pricing, and on-site repairs at no extra cost.",
    imgUrl: RepairDiagnostic,
    repeatEvery: PackageRecurrenceEnum.AdHoc,
    thirdTitle: "5 types of units available",
    duration: 0,
    isModal: true,
  },
];

export const homeBeautyServiceTypeOptions = [
  {
    value: ServiceTypeEnum.Combo,
    serviceTitle: "Choose your ManiPedi Package",
    title: "Manicure Pedicure",
    subtitle: "Starting at $84.00 +GST",
    description:
      "Lorem ipsum dolor sit amet consectetur. Lectus quis sagittis ultricies mi. Duis nisl.",
    imgUrl: ManiPedi,
    price: 84,
  },
  {
    value: ServiceTypeEnum.Manicure,
    serviceTitle: "Choose your Manicure Package",
    title: "Manicure",
    subtitle: "Starting at $37.00 +GST",
    description:
      "Lorem ipsum dolor sit amet consectetur. Lectus quis sagittis ultricies mi. Duis nisl.",
    imgUrl: Manicure,
    price: 37,
  },
  {
    value: ServiceTypeEnum.Pedicure,
    serviceTitle: "Choose your Pedicure Package",
    title: "Pedicure",
    subtitle: "Starting at $47.00 +GST",
    description:
      "Lorem ipsum dolor sit amet consectetur. Lectus quis sagittis ultricies mi. Duis nisl.",
    imgUrl: Pedicure,
    price: 47,
  },
];

export const defaultServiceReviews = {
  rating: 0,
  ratingTotal: 0,
  reviews: [],
};

// there's a code `HBNAGO` belongs to Upgrade serviceType, I don't know what it stands for.
export const getHomeBeautyPackageFromCode = (code: string) => {
  switch (code) {
    case "HBNCCMP":
      return {
        imgUrl: ClassicManiPedi,
        title: "Classic ManiPedi",
        description:
          "Enjoy timeless elegance with our classic manicure and pedicure for the perfect polished look and well-groomed hands and feet. Regular polish included.",
      };
    case "HBNCGMP":
      return {
        imgUrl: GelManipedi,
        title: "Gel ManiPedi",
        description:
          "Indulge in enduring beauty with our gel polish service, cured flawlessly under LED light for up to 3 weeks of chip-free radiance. Basic cleaning included.",
      };
    case "HBNCGMCP":
      return {
        imgUrl: GelManiClasssicPedi,
        title: "Gel Mani + Classic Pedi",
        description:
          "Elevate your ManiPedi experience with a durable gel manicure and classic pedicure care. ",
      };
    case "HBNCCMA":
      return {
        imgUrl: ClassicManicure,
        title: "Classic Manicure",
        description:
          "Elegant, clean nails, expertly shaped, polished and pampered for a classic and fresh look. Regular polish and short hand massage included.",
      };
    case "HBNCCPE":
      return {
        imgUrl: ClassicPedicure,
        title: "Classic Pedicure",
        description:
          "Step into relaxation with our classic pedicure. Enjoy expert nail care and polish finishing for soft and beautiful feet. Regular polish and short foot massage included.",
      };
    case "HBNCGMA":
      return {
        imgUrl: GelManicure,
        title: "Gel Manicure",
        description:
          "Elevate your nails with our gel manicure for a long-lasting, chip-resistant, and glossy finish for beautiful nails.",
      };
    case "HBNCGPE":
      return {
        imgUrl: GelPedicure,
        title: "Gel Pedicure",
        description:
          "Elevate your nails with our gel pedicure for a long-lasting, chip-resistant, and glossy finish for beautiful nails.",
        sortOrder: 1,
      };
    case "HBNANA":
      return {
        imgUrl: NailArt,
        title: "Nail Art (per nail)",
        description:
          "Express yourself in creative, personalized manicures and enjoy our nail art add on!",
        maxItem: 20,
        suffix: "/nail",
        mostPopular: true,
        minFreeGelAmount: 5,
      };
    case "HBNAFT":
      return {
        imgUrl: FrenchTips,
        title: "French Tips",
        description:
          "Perfect for anyone looking for a stylish and timeless design. It gives your manicure that extra touch of sophistication.",
        maxItem: 2,
        suffix: "/10 nails",
        minFreeGelAmount: 1,
      };
    case "HBNAEX":
      return {
        imgUrl: NailExtension,
        title: "Nail Extension",
        description:
          "Elevate your manicure with long-lasting nail extensions and get an even more glamorous manicure.",
        maxItem: 2,
        suffix: "/10 nails",
        minFreeGelAmount: 1,
      };
    case "HBNAGR":
      return {
        imgUrl: GelRemoval,
        title: "Gel Removal",
        description:
          "Seamless and precise gel removal for fresh, healthy nails.",
        maxItem: 2,
        sortOrder: 3,
        suffix: "/10 nails",
      };
    case "HBNAER":
      return {
        imgUrl: ExtensionRemoval,
        title: "Extension removal",
        description: `Gentle and precise nail extension removal, restoring your natural nails' beauty.`,
        maxItem: 2,
        sortOrder: 4,
        suffix: "/10 nails",
      };
    case "HBNAEM":
      return {
        imgUrl: ExtendedMassage,
        title: "Extended Massage",
        description:
          "Rejuvenate and experience silky, smooth, callus-free hands or feet with this treatment.",
        // imgCarousel: [Images.EXTENDED_MESSAGE],
        sortOrder: 1,
        mostPopular: true,
      };
    case "HBNACT":
      return {
        imgUrl: CallusTreatment,
        title: "Callus Treatment",
        description:
          "Indulge in post-manicure bliss and extend your hand massage time.",
        // imgCarousel: [Images.CALLUS_TREATMENT],
        sortOrder: 2,
        mostPopular: true,
      };
    case "HBNDFGR":
      return {
        imgUrl: GelRemoval,
        title: "Free Gel Removal",
        description:
          "Seamless and precise gel removal for fresh, healthy nails.",
      };
    default:
      return {
        imgUrl: "-",
        title: "-",
        description: "-",
        undefinedCode: true,
      };
  }
};

export const getCarpetUpholsteryFromUnit = (
  unitType: PackageUnitTypeEnum,
  unitSize: UnitSizeEnum,
) => {
  switch (unitType) {
    case PackageUnitTypeEnum.Mattress:
      if (unitSize === UnitSizeEnum.Single) {
        return {
          title: "Single Bed Mattress",
          description: "91x190cm",
          img: SingleBedMattress,
          recommended: false,
          sortOrder: 0,
          maxItem: 6,
        };
      }
      if (unitSize === UnitSizeEnum.Queen) {
        return {
          title: "Queen Bed Mattress",
          description: "152x190cm",
          img: QueenBedMattress,
          recommended: false,
          sortOrder: 1,
          maxItem: 6,
        };
      }
      if (unitSize === UnitSizeEnum.King) {
        return {
          title: "King Bed Mattress",
          description: "182x190cm",
          img: KingBedMattress,
          recommended: false,
          sortOrder: 2,
          maxItem: 6,
        };
      }
      if (unitSize === UnitSizeEnum.BabyCot) {
        return {
          title: "Baby Cot",
          img: BabyCot,
          recommended: false,
          sortOrder: 3,
          maxItem: 6,
        };
      }
      break;
    case PackageUnitTypeEnum.Sofa:
      if (unitSize === UnitSizeEnum.Seater_1) {
        return {
          title: "1 Seater",
          img: Seater1,
          sortOrder: 0,
          maxItem: 6,
        };
      }
      if (unitSize === UnitSizeEnum.Seater_2) {
        return {
          title: "2 Seater",
          img: Seater2,
          sortOrder: 1,
          maxItem: 6,
        };
      }
      if (unitSize === UnitSizeEnum.Seater_3) {
        return {
          title: "3 Seater",
          img: Seater3,
          sortOrder: 2,
          maxItem: 6,
        };
      }
      if (unitSize === UnitSizeEnum.Seater_4) {
        return {
          title: "4 Seater",
          img: Seater4,
          sortOrder: 3,
          maxItem: 6,
        };
      }
      if (unitSize === UnitSizeEnum.Seater_5) {
        return {
          title: "5 Seater",
          img: Seater5,
          sortOrder: 4,
          maxItem: 6,
        };
      }
      if (unitSize === UnitSizeEnum.Chair) {
        return {
          title: "Chair",
          img: Chair,
          sortOrder: 5,
          maxItem: 6,
        };
      }
      if (unitSize === UnitSizeEnum.ArmChair) {
        return {
          title: "Arm Chair",
          img: ArmChair,
          sortOrder: 6,
          maxItem: 6,
        };
      }
      break;
    case PackageUnitTypeEnum.Rug:
      if (unitSize === UnitSizeEnum.Small) {
        return {
          sortOrder: 0,
          title: "Small",
          img: RugSmall,
          description: "up to 100x100cm",
          maxItem: 6,
        };
      }
      if (unitSize === UnitSizeEnum.Medium) {
        return {
          sortOrder: 1,
          title: "Medium",
          img: RugMedium,
          description: "up to 100x200cm",
          maxItem: 6,
        };
      }
      if (unitSize === UnitSizeEnum.Large) {
        return {
          sortOrder: 2,
          title: "Large",
          description: "up to 200x200cm",
          img: RugLarge,
          maxItem: 6,
        };
      }
      if (unitSize === UnitSizeEnum.ExtraLarge) {
        return {
          sortOrder: 3,
          title: "ExtraLarge",
          description: "300x300cm",
          img: RugExtraLarge,
          maxItem: 6,
        };
      }
      break;
    case PackageUnitTypeEnum.Carpet:
      if (unitSize === UnitSizeEnum.C_600SqftBelow) {
        return {
          title: "Small",
          sortOrder: 0,
          maxItem: 6,
        };
      }
      if (unitSize === UnitSizeEnum.C_601SqftTo_900Sqft) {
        return {
          title: "Medium",
          sortOrder: 1,
          maxItem: 6,
        };
      }
      if (unitSize === UnitSizeEnum.C_901SqftTo_1500Sqft) {
        return {
          title: "Large",
          sortOrder: 2,
          maxItem: 6,
        };
      }
      if (unitSize === UnitSizeEnum.C_4000SqftAbove) {
        return {
          title: "Extra Large",
          sortOrder: 3,
          maxItem: 6,
        };
      }
      break;
    case PackageUnitTypeEnum.ChildSeat:
      if (unitSize === UnitSizeEnum.All)
        return {
          title: "Car Seat",
          sortOrder: 1,
          img: CarSeat,
          maxItem: 6,
        };
      break;
    case PackageUnitTypeEnum.BabyPram:
      if (unitSize === UnitSizeEnum.All)
        return {
          title: "Baby Pram",
          img: BabyPram,
          sortOrder: 0,
          maxItem: 6,
        };
      break;
    default:
      return {
        title: "",
        img: "",
      };
  }
};

export const getAirconUnitsFromUnit = (
  serviceType: ServiceTypeEnum | undefined,
  unitType: PackageUnitTypeEnum,
): { title: string; sortOrder: number; img: string; maxItem: number } => {
  switch (serviceType) {
    case ServiceTypeEnum.GeneralServicing:
      if (unitType === PackageUnitTypeEnum.Wall) {
        return {
          title: PackageUnitTypeMapping[unitType],
          img: Wall,
          sortOrder: 0,
          maxItem: 8,
        };
      }
      if (unitType === PackageUnitTypeEnum.Ceiling) {
        return {
          title: PackageUnitTypeMapping[unitType],
          img: Ceiling,
          sortOrder: 1,
          maxItem: 8,
        };
      }
      if (unitType === PackageUnitTypeEnum.Ducted) {
        return {
          title: PackageUnitTypeMapping[unitType],
          img: Ducted,
          sortOrder: 2,
          maxItem: 8,
        };
      }
      if (unitType === PackageUnitTypeEnum.Portable) {
        return {
          title: PackageUnitTypeMapping[unitType],
          img: Portable,
          sortOrder: 3,
          maxItem: 8,
        };
      }
      if (unitType === PackageUnitTypeEnum.Window) {
        return {
          title: PackageUnitTypeMapping[unitType],
          img: Window,
          sortOrder: 4,
          maxItem: 8,
        };
      }
      break;
    case ServiceTypeEnum.ChemicalWash:
      if (unitType === PackageUnitTypeEnum.Wall) {
        return {
          title: PackageUnitTypeMapping[unitType],
          img: Wall,
          sortOrder: 0,
          maxItem: 6,
        };
      }
      if (unitType === PackageUnitTypeEnum.Ceiling) {
        return {
          title: PackageUnitTypeMapping[unitType],
          img: Ceiling,
          sortOrder: 1,
          maxItem: 6,
        };
      }
      break;
    case ServiceTypeEnum.ChemicalOverhaul:
      if (unitType === PackageUnitTypeEnum.Wall) {
        return {
          title: PackageUnitTypeMapping[unitType],
          img: Wall,
          sortOrder: 0,
          maxItem: 6,
        };
      }
      break;
    case ServiceTypeEnum.CondenserWash:
      if (unitType === PackageUnitTypeEnum.All) {
        return {
          title: PackageUnitTypeMapping[unitType],
          img: AllUnits,
          sortOrder: 0,
          maxItem: 8,
        };
      }
      break;
    case ServiceTypeEnum.GasTopUp:
      if (unitType === PackageUnitTypeEnum.All) {
        return {
          title: "All Units (Compressor)",
          img: AllUnits,
          sortOrder: 0,
          maxItem: 6,
        };
      }
      break;
    default:
      return {
        title: "",
        img: "",
        sortOrder: 0,
        maxItem: 0,
      };
  }
  return {
    title: "",
    img: "",
    sortOrder: 0,
    maxItem: 0,
  };
};

export type ServiceDetailKey =
  | "home-cleaning"
  | "mattress-cleaning"
  | "rug-cleaning"
  | "pram-seat"
  | "sofa-cleaning"
  | "aircon-service-GENERAL_SERVICING"
  | "aircon-service-CHEMICAL_WASH"
  | "aircon-service-CHEMICAL_OVERHAUL"
  | "aircon-service-CONDENSER_WASH"
  | "aircon-service-GAS_TOP_UP"
  | "aircon-service-REPAIR_DIAGNOSTIC"
  | "home-beauty";

export const serviceDetailCover: Record<ServiceDetailKey, string> = {
  "home-cleaning": homeCleaningCover,
  "mattress-cleaning": mattressCover,
  "rug-cleaning": rugCleaningCover,
  "pram-seat": pramSeatCover,
  "sofa-cleaning": sofaCover,
  "aircon-service-GENERAL_SERVICING": airconGeneralServicingCover,
  "aircon-service-CHEMICAL_WASH": chemicalWashCover,
  "aircon-service-CHEMICAL_OVERHAUL": chemicalOverhaulCover,
  "aircon-service-CONDENSER_WASH": compressorWashCover,
  "aircon-service-GAS_TOP_UP": gasCover,
  "aircon-service-REPAIR_DIAGNOSTIC": repairDiagnosticCover,
  "home-beauty": homeBeautyComboCover,
};

import { View, Image, Button } from "@/components/ui";
import { PackageCard } from "@/components/shared/package-card";
import { PackagesFilter } from "../packages/packages-filter";
import EmptyPackages from "@/assets/images/empty-packages.png";
import { usePackageList } from "@/store/packages/useGetPackage";
import type { PackageStatusFilterItem } from "@/store/packages/usePackageState";
import { usePackageState } from "@/store/packages/usePackageState";
import { Typography } from "@/components/shared/typography";
import { useIntl } from "react-intl";
import { PackageStatusEnum } from "@/__generated__/graphql";
import { useState } from "react";
import { IfElse } from "@/components/shared/if-else";

export function PackageList() {
  const intl = useIntl();
  const {
    data: { rowsPerPage, packageStatus },
    setLimit,
    setPackageStatus,
  } = usePackageState();
  const packageList = usePackageList();

  const [openActiveFilter, setOpenActiveFilter] = useState<boolean>(false);

  const handleShowMore = () => {
    setLimit(rowsPerPage);
  };

  const packageStatusFilter: PackageStatusFilterItem[] = [
    {
      label: "All",
      value: "ALL",
    },
    {
      label: intl.formatMessage({
        defaultMessage: "Active",
        id: "active",
      }),
      value: PackageStatusEnum.Active,
    },
    {
      label: intl.formatMessage({
        defaultMessage: "Inactive",
        id: "inactive",
      }),
      value: PackageStatusEnum.Terminated,
    },
  ];

  function getStatusLabel(status: PackageStatusEnum | "ALL"): string {
    if (status === "ALL") {
      return "All";
    }

    switch (status) {
      case PackageStatusEnum.Active:
        return intl.formatMessage({
          defaultMessage: "Active",
          id: "active",
        });
      case PackageStatusEnum.Terminated:
        return intl.formatMessage({
          defaultMessage: "Inactive",
          id: "inactive",
        });
      default:
        return "All";
    }
  }
  const hideButton = rowsPerPage > packageList.length;

  return (
    <View className="flex flex-col gap-4">
      <PackagesFilter
        setOpenActiveFilter={setOpenActiveFilter}
        setPackageStatus={setPackageStatus}
        statusLabel={getStatusLabel(packageStatus)}
        openActiveFilter={openActiveFilter}
        packageStatusFilter={packageStatusFilter}
        packageStatus={packageStatus}
      />
      {!!packageList.length && (
        <>
          <View className="flex flex-col gap-4 pb-4">
            {packageList.map((item) => (
              <PackageCard key={item.id} item={item} />
            ))}
            <IfElse if={!hideButton}>
              <View className="flex flex-col items-center">
                <Button
                  variant="tertiary"
                  color="CTA2"
                  iconName="arrowCounterClockwise"
                  className="gap-2 text-primary"
                  onClick={handleShowMore}
                  children={intl.formatMessage({
                    defaultMessage: "Show More",
                    id: "show-more",
                  })}
                />
              </View>
            </IfElse>
          </View>
        </>
      )}

      {!packageList.length && (
        <View className="flex flex-col items-center">
          <Image src={EmptyPackages} width={160} height={160} />
          <Typography variant="body-xl">
            {intl.formatMessage({
              defaultMessage: "You don't have any Packages",
              id: "package.emptyMessage",
            })}
          </Typography>
        </View>
      )}
    </View>
  );
}

import { TimeSlotWithSearch } from "@/components/shared/time-slot-with-search";
import { Button, View } from "@/components/ui";
import type { RescheduleVisitContentProps } from "..";
import { InfoDialog } from "@/components/shared/info-dialog";
import { ChooseWorkerModal } from "@/components/shared/booking/choose-worker-modal";
import { SuitableWorkerModal } from "../../suitable-worker-modal";
import { Column, Container } from "@/components/ui/layout";
import { RescheduleVisitInfo } from "../../reschedule-visit-info";
import { IconButton } from "@/components/ui/icon-button";
import { Typography } from "@/components/shared/typography";
import { useRoute } from "@/components/shared/router";

export function RescheduleVisitContentScreen({
  timeSlotProps,
  onConfirm,
  disabledButton,
  chooseWorkerDepartment,
  handleApply,
  infoDialogSuccess,
  onCloseInfoDialog,
  onCloseOpenSuitableWorker,
  openChooseWorker,
  openSuitableWorker,
  selectedWorkers,
  setOpenChooseWorker,
}: RescheduleVisitContentProps) {
  const {
    name,
    initialStartDate,
    department,
    endDate,
    loading,
    onChooseWorkers,
    onClickWorkerPortfolio,
    onSelectSlot,
    selectedSlot,
    setEndDate,
    setStartDate,
    startDate,
    timeSlots,
    workerLabel,
    workersIds,
  } = timeSlotProps;

  const { pull } = useRoute();

  return (
    <Container gap="xl">
      <Column desktop="8">
        <View>
          <View className="flex flex-row items-center gap-1.5">
            <IconButton
              variant="ghost"
              color="CTA2"
              iconName="arrowLeft"
              onClick={pull}
            />
            <Typography variant="label-2xl" className="my-4">
              Available Time Slot
            </Typography>
          </View>
        </View>
        <View className="flex flex-col gap-2 pb-8">
          <TimeSlotWithSearch
            name={name}
            initialStartDate={initialStartDate}
            department={department}
            showTimeRange
            loading={loading}
            selectedSlot={selectedSlot}
            timeSlots={timeSlots}
            workersIds={workersIds}
            workerLabel={workerLabel}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            enableChooseWorker
            onChooseWorkers={onChooseWorkers}
            onSelectSlot={onSelectSlot}
            onClickWorkerPortfolio={onClickWorkerPortfolio}
          />
          <View className="w-1/4 self-end">
            <Button
              variant="primary"
              color="CTA"
              loading={loading}
              fullWidth="full"
              onClick={onConfirm}
              disabled={disabledButton}
              children="Reschedule"
            />
          </View>
          <InfoDialog
            open={infoDialogSuccess}
            title="Success"
            subtitle="Your visit schedule has been updated"
            onClose={onCloseInfoDialog}
          />
          <ChooseWorkerModal
            department={chooseWorkerDepartment}
            open={openChooseWorker}
            workerLabel={workerLabel}
            setOpen={setOpenChooseWorker}
            selectedWorkers={selectedWorkers}
            handleApply={handleApply}
          />
          <SuitableWorkerModal
            open={openSuitableWorker}
            onClose={onCloseOpenSuitableWorker}
          />
        </View>
      </Column>
      <Column desktop="4">
        <View className="p-4 md:my-10">
          <RescheduleVisitInfo />
        </View>
      </Column>
    </Container>
  );
}

import { Card, CardContent, View } from "@/components/ui";
import { User } from "@/components/shared/icons";
import { useAuthState } from "@/store/auth";
import { Typography } from "@/components/shared/typography";

export const AccountProfileOverview = () => {
  const {
    data: { userInfo },
  } = useAuthState();
  return (
    <Card shadow="sm">
      <CardContent className="flex flex-row items-center gap-3 p-3">
        <View className="w-auto rounded-full bg-primary p-2">
          <User weight="fill" className="size-6 text-white" />
        </View>
        <View>
          <Typography variant="body-xl">{userInfo.name}</Typography>
          <Typography variant="body-md" color="muted-foreground">
            {userInfo.accountType}
          </Typography>
        </View>
      </CardContent>
    </Card>
  );
};

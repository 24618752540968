import type { ReactNode } from "react";
import { Typography } from "@/components/shared/typography";
import { View } from "@/components/ui";

export function ListItem({
  label,
  children,
}: {
  label: string;
  children: ReactNode;
}) {
  return (
    <View className="flex flex-row items-center justify-between gap-1">
      <Typography variant="body-lg" color="foreground-intermediate">
        {label}
      </Typography>
      {children}
    </View>
  );
}

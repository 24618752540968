import {
  type BetaServiceItem,
  ServiceCategoryMap,
  betaServiceItems,
  otherServiceItems,
  popularServiceItems,
  type ServiceCategory,
  type ServiceItem,
} from "../../lib";
import { View } from "@/components/ui";
import { BetaServiceIcon, ServiceIcon } from "@/components/shared/service-icon";
import { ListHeading } from "@/components/shared/list-header";
import { Sparkle } from "@/components/shared/icons";
import { useIntl } from "react-intl";
import {
  AccordionContent,
  AccordionTrigger,
  AccordionItem,
  Accordion,
} from "@/components/ui/accordion";
import { getPlatform } from "@/lib/utils";

export function AllServicesContent() {
  const intl = useIntl();
  const platform = getPlatform();
  return (
    <Accordion type={platform === "native" ? "single" : "multiple"} collapsable>
      {Object.entries(ServiceCategoryMap).map(([key, title]) => {
        return (
          <AccordionItem key={key} value={key} className="native:px-4">
            <AccordionTrigger>
              {key === "PopularService" ? (
                <ListHeading icon={Sparkle} title={title} />
              ) : (
                <ListHeading title={title} />
              )}
            </AccordionTrigger>
            <AccordionContent>
              <View>
                <View className="flex flex-row flex-wrap overflow-hidden">
                  {popularServiceItems(intl)
                    .concat(otherServiceItems(intl))
                    .filter((item) =>
                      item.categories.includes(key as keyof ServiceCategory),
                    )
                    .map((service: ServiceItem) => (
                      <View className="mt-2 w-1/4 px-4 pb-4">
                        <ServiceIcon key={service.name} {...service} />
                      </View>
                    ))}
                  {betaServiceItems(intl)
                    .filter((item) =>
                      item.categories.includes(key as keyof ServiceCategory),
                    )
                    .map((service: BetaServiceItem) => (
                      <View className="mt-2 w-1/4 px-4 pb-4">
                        <BetaServiceIcon key={service.name} {...service} />
                      </View>
                    ))}
                </View>
              </View>
            </AccordionContent>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
}

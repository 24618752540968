import { LoginContent } from "@/components/shared/login-register/login-content";
import { Column, Container } from "@/components/ui/layout";
import { GetTheApp } from "@/containers/get-the-app";
import { Card, CardContent, View } from "@/components/ui";
import { EmailLoginContent } from "@/components/shared/login-register/email-login-content";
import { useEffect } from "react";
import { useAuthState } from "@/store/auth";
import { LoginType } from "@/types/users";
import { useRoute } from "@/components/shared/router";
import { getPlatform } from "@/lib/utils";
import { useWindowDimensions } from "@/components/hooks/use-window-dimension";
import { useAuth } from "@/components/shared/auth-provider";

export const LoginPage = () => {
  const { replace } = useRoute();
  const platform = getPlatform();
  const { isMobile } = useWindowDimensions();
  const {
    data: { type },
    setType,
  } = useAuthState();

  const { isAuthenticated } = useAuth();

  useEffect(() => {
    setType(LoginType.Login);
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      replace({
        pageKey: "homepage",
      });
    }
  }, [isAuthenticated]);

  return (
    <View className="mx-8 pt-12">
      <Container>
        <Column desktop="8">
          <Card className="p-2">
            <CardContent>
              {type === LoginType.Login ? (
                <LoginContent isModal={false} />
              ) : (
                <EmailLoginContent isModal={false} />
              )}
            </CardContent>
          </Card>
        </Column>
        <Column desktop="4">
          {platform === "web" && (
            <GetTheApp imageVariant={isMobile ? "luceapp" : "qrcode"} />
          )}
        </Column>
      </Container>
    </View>
  );
};

import { Card, CardContent, View } from "@/components/ui";
import { CaretRight } from "@/components/shared/icons";
import { useWindowDimensions } from "@/components/hooks/use-window-dimension";
import { PackageCardLabel } from "./package-card-label";
import type { Package } from "@/__generated__/graphql";
import { formatDateString } from "@/lib/helpers/date";
import { Typography } from "../typography";
import { useRoute } from "../router";

export function PackageCard({ item }: { item: Package }) {
  const { isMobile } = useWindowDimensions();
  const { push } = useRoute();

  const {
    packageDescription,
    department,
    repeatEvery,
    startDate,
    endDate,
    fullAddress,
  } = item;

  const handleClick = () => {
    push({ pageKey: "package", params: { id: item.id } });
  };

  return (
    <Card shadow={isMobile ? "none" : "sm"} onClick={handleClick}>
      <CardContent className="p-4">
        <View className="mb-3 flex w-full flex-row items-start justify-between border-b border-secondary-border pb-3">
          <View className="flex w-full flex-1">
            <Typography variant={{ md: "body-xl", sm: "body-md" }}>
              {packageDescription}
            </Typography>
          </View>
          <PackageCardLabel department={department} repeatEvery={repeatEvery} />
        </View>

        <View className="flex flex-row items-center justify-between md:items-end">
          <View className="flex max-w-[200px] flex-col gap-1 md:max-w-[320px]">
            <Typography
              variant={{ md: "body-lg", sm: "body-md" }}
              color="foreground-intermediate"
              numberOfLines={1}
            >
              {`Active from ${formatDateString(startDate)} - ${endDate ? formatDateString(endDate) : "Not Set"}`}
            </Typography>
            <Typography
              variant={{ md: "body-lg", sm: "body-md" }}
              color="foreground-intermediate"
            >
              {fullAddress}
            </Typography>
          </View>

          <View className="flex flex-row items-center">
            <Typography
              variant="body-md"
              className="hidden text-button-cta2-hover md:flex"
            >
              View Detail
            </Typography>
            <CaretRight className="size-3 text-button-cta2-hover" />
          </View>
        </View>
      </CardContent>
    </Card>
  );
}

import { View } from "@/components/ui";
import { ArrowLeft } from "@/components/shared/icons";
import { Link } from "@/components/shared/link";
import { InfoCard } from "@/components/shared/info-card";
import { PaymentActionsButton } from "@/containers/profile/payment/payment-card-screen/payment-buttons-action";
import { ModalRemoveCard } from "@/containers/profile/payment/modal-remove-card";
import type { PaymentScreeProps } from "..";
import { PaymentCardScreen } from "../payment-card-screen";
import { Typography } from "@/components/shared/typography";
import { useIntl } from "react-intl";
import { AutoPayWidget } from "@/components/shared/invoices/auto-pay";

export const PaymentScreen = ({
  handleRemoveConfirmation,
  loading,
  onDeleteCard,
  onUpdateCard,
  isUpdateCard,
  paymentMethod,
  infoCardDesc,
  handleCloseRemoveConfirmation,
  openRemoveConfirmation,
  setOpenRemoveConfirmation,
  creditCardPayment,
  onPay,
}: PaymentScreeProps) => {
  const intl = useIntl();
  return (
    <>
      <View className="z-10 h-full flex-1 p-4 md:p-0">
        <View className="mb-4 border-b md:mb-8 md:pb-4">
          <View className="flex flex-row items-center gap-2 p-4 md:p-0">
            <Link href="/profile">
              <ArrowLeft className="visible size-5 md:hidden" />
            </Link>
            <Typography variant={{ md: "label-2xl", sm: "label-xl" }}>
              {intl.formatMessage({
                id: "payment.card.title",
                defaultMessage: "Your Card Payment",
              })}
            </Typography>
          </View>
        </View>

        <View className="flex flex-col items-center gap-4">
          {!!paymentMethod && (
            <PaymentCardScreen paymentCard={paymentMethod} loading={loading} />
          )}

          <AutoPayWidget />

          <InfoCard title="Info" description={infoCardDesc} />
        </View>

        <PaymentActionsButton
          isUpdateCard={isUpdateCard}
          onRemoveConfirmation={handleRemoveConfirmation}
          hasPayment={!!paymentMethod}
          loading={loading}
          onRemoveToUpdate={onUpdateCard}
          hasCreditCardPayment={creditCardPayment}
          onPay={onPay}
        />
      </View>

      <ModalRemoveCard
        onClose={handleCloseRemoveConfirmation}
        onDeleteCard={onDeleteCard}
        open={openRemoveConfirmation}
        setOpenRemoveConfirmation={setOpenRemoveConfirmation}
      />
    </>
  );
};

import type {
  InvoiceDetailFragmentFragment,
  InvoicesByFiltersQuery,
} from "@/__generated__/graphql";

export type InvoiceDetail = Omit<InvoiceDetailFragmentFragment, "__typename">;

export type Invoice =
  InvoicesByFiltersQuery["invoicesByFilters"]["invoices"][0];

export enum InvoiceFilterStatusEnum {
  UNPAID = "UNPAID",
  PAID = "PAID",
}

export enum PaymentMethodScreenModal {
  SELECT_PAYMENT = "SELECT_PAYMENT",
  PAYNOW = "PAYNOW",
  CREDIT_CARD = "CREDIT_CARD",
  ADD_CREDIT_CARD = "ADD_CREDIT_CARD",
}

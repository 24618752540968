import { BottomDrawerModal } from "@/components/shared/bottom-drawer-modal";
import type {
  VisitDetailData,
  VisitDetailHandler,
} from "@/components/shared/visits/visit-detail";
import { VisitDetailContent } from "@/components/shared/visits/visit-detail";
import { ModalHeader } from "./modal-header";

type Props = {
  handleClose(): void;
  visitData: VisitDetailData;
  loading: boolean;
} & VisitDetailHandler;

export function VisitDetailModal({
  handleClose,
  visitData,
  loading,
  handleReschedule,
  handleChangeWorker,
  handleReportVisit,
  handleSkipVisit,
  handleRateVisit,
}: Props) {
  return (
    <BottomDrawerModal open onOpenChange={handleClose}>
      <ModalHeader onClose={handleClose} />
      <VisitDetailContent
        visitData={visitData}
        loading={loading}
        handleReschedule={handleReschedule}
        handleChangeWorker={handleChangeWorker}
        handleReportVisit={handleReportVisit}
        handleSkipVisit={handleSkipVisit}
        handleRateVisit={handleRateVisit}
      />
    </BottomDrawerModal>
  );
}

import { View, RadioGroup, Button, Card } from "@/components/ui";
import { useBookingState } from "@/store/booking/useBookingState";
import { BottomDrawerModal } from "@/components/shared/bottom-drawer-modal";
import { ListHeading } from "@/components/shared/list-header";
import { Cube } from "@/components/shared/icons";
import { ServiceRadioCard } from "../service-radio-card";
import { useEffect, useMemo } from "react";
import {
  getHomeBeautyPackageFromCode,
  homeBeautyServiceTypeOptions,
} from "@/constants/booking";
import { formatPrice } from "@/lib/helpers/number";
import { showToast } from "@/components/ui/toast/show-toast";
import { useBookingRoute } from "@/components/hooks/use-booking-route";
import { useIntl } from "react-intl";
import { getServiceUnit } from "@/lib/booking-lib";
import { Spin } from "@/components/shared/spin";
import { Typography } from "../../typography";
import { getPlatform } from "@/lib/utils";

export const ChooseHBPackageModal = () => {
  const intl = useIntl();
  const { forward } = useBookingRoute();
  const {
    data: {
      chooseHBPackageModalOpen,
      packageDetailsFilter,
      packageDetails,
      selectedPackageIds,
      homeBeautyPackagesLoading,
    },
    setBookingState,
  } = useBookingState();
  const platform = getPlatform();

  const service = useMemo(() => {
    return packageDetailsFilter.serviceTypes?.[0];
  }, [packageDetailsFilter.serviceTypes?.[0]]);

  const options = useMemo(() => {
    return packageDetails
      .filter(
        (option) =>
          // @ts-expect-error: actually `ServiceTypeEnum` is equal to `PackageSericeTypeEnum`. Really should remove one
          option.serviceType === service,
      )
      .map((pkg) => {
        const homeBeautyPkg = getHomeBeautyPackageFromCode(pkg.code);
        return {
          title: homeBeautyPkg.title,
          description: homeBeautyPkg.description,
          imgUrl: homeBeautyPkg.imgUrl,
          value: pkg.id,
          subTitle: `Starting ${formatPrice(pkg.serviceBillingValue)}/${getServiceUnit("home-beauty")}`,
          thirdTitle: `${pkg.duration * 60} mins`,
        };
      });
  }, [service, packageDetails]);

  useEffect(() => {
    if (chooseHBPackageModalOpen) {
      setBookingState({
        packageDetailsFilter: {
          ...packageDetailsFilter,
          serviceTypes: [homeBeautyServiceTypeOptions[0].value],
        },
      });
    }
  }, [chooseHBPackageModalOpen]);

  useEffect(() => {
    if (chooseHBPackageModalOpen) {
      if (options.length) {
        setBookingState({
          selectedPackageIds: [options[0].value],
        });
      }
    }
  }, [chooseHBPackageModalOpen, options]);

  const onSelect = (value: string) => {
    setBookingState({
      selectedPackageIds: [value],
    });
  };

  const onContinue = () => {
    if (!selectedPackageIds.length) {
      return showToast({
        title: "Please choose your package!",
        type: "info",
      });
    }
    setBookingState({
      chooseHBPackageModalOpen: false,
      homeBeautyFreeGelServiceIds: [],
    });
    forward();
  };

  const getPlatformTitle = (title: string) => {
    if (platform === "native" && title === "Manicure Pedicure")
      return "ManiPedi";
    return title;
  };

  return (
    <BottomDrawerModal
      open={chooseHBPackageModalOpen}
      onOpenChange={() => {
        setBookingState({
          chooseHBPackageModalOpen: false,
        });
      }}
    >
      <View className="flex flex-col gap-4">
        <ListHeading title={"Select Service"} icon={Cube} />
        <Typography variant="body-sm" color={"foreground-intermediate"}>
          Choose the service category that suits your needs best.
        </Typography>
        <View className="flex flex-row gap-2">
          {homeBeautyServiceTypeOptions.map((option) => {
            return (
              <Card
                className="flex-1 cursor-pointer p-2"
                onClick={() =>
                  setBookingState({
                    packageDetailsFilter: {
                      ...packageDetailsFilter,
                      serviceTypes: [option.value],
                    },
                  })
                }
                variant={service === option.value ? "info" : "default"}
              >
                <Typography variant={"body-md"}>
                  {getPlatformTitle(option.title)}
                </Typography>
                <Typography
                  variant={"body-sm"}
                  color={"foreground-intermediate"}
                >
                  starting from
                </Typography>
                <View className="flex flex-row items-center">
                  <Typography
                    className="leading-1"
                    variant={"label-sm"}
                    color="brand-primary"
                  >
                    {formatPrice(option.price)}
                  </Typography>
                  <Typography
                    className="leading-1"
                    variant={"body-md"}
                    color={"foreground-intermediate"}
                  >
                    /session
                  </Typography>
                </View>
              </Card>
            );
          })}
        </View>

        <Card className="bg-background-intermediate p-4">
          {homeBeautyPackagesLoading && (
            <Spin className="my-12 size-12 shrink-0" />
          )}
          <RadioGroup value={selectedPackageIds[0]} onValueChange={() => {}}>
            {options.map((option) => {
              return (
                <ServiceRadioCard
                  key={option.value}
                  active={selectedPackageIds[0] === option.value}
                  onSelect={() => onSelect(option.value)}
                  imgUrl={option.imgUrl}
                  title={option.title}
                  subTitle={option.subTitle}
                  thirdTitle={option.thirdTitle}
                  value={option.value}
                />
              );
            })}
          </RadioGroup>
        </Card>

        <Button
          variant="primary"
          color="CTA"
          size="lg"
          rounded="full"
          onClick={onContinue}
          children={intl.formatMessage({
            defaultMessage: "Continue",
            id: "proceed",
          })}
        />
      </View>
    </BottomDrawerModal>
  );
};

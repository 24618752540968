import { Button } from "@/components/ui";
import type { AddToCalendarProps } from "@/store/booking/useBookingState";
import ICalLink from "react-icalendar-link";
import { showToast } from "@/components/ui/toast/show-toast";

export const AddToCalendarButton = ({
  title,
  description,
  startTime,
  endTime,
  location,
}: AddToCalendarProps) => {
  const event = {
    title,
    description,
    startTime,
    endTime,
    location,
  };

  return (
    <ICalLink
      {...{
        event,
        filename: `${title} Appointment.ics`,
      }}
    >
      <Button
        variant="secondary"
        color="CTA2"
        size={{ sm: "sm", md: "md" }}
        onClick={() =>
          showToast({
            type: "success",
            title: `Your calendar has been downloaded`,
          })
        }
        children="Add to Calendar"
      />
    </ICalLink>
  );
};

export const AddToCalendar = AddToCalendarButton;

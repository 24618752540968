import { Dialog, DialogContent, View, Button } from "@/components/ui";
import { Info } from "@/components/shared/icons";
import { useAuthState } from "@/store/auth";
import { AuthModals, LoginType } from "@/types/users";
import { useRoute } from "@/components/shared/router";
import { Typography } from "../../typography";

export function ForgetPasswordModal() {
  const { replace } = useRoute();
  const {
    data: { forgotPasswordModalOpen, emailLoginModalOpen },
    showModal,
    setType,
  } = useAuthState();
  const onBack = () => {
    if (emailLoginModalOpen) showModal(AuthModals.EMAIL_LOGIN);
    else showModal();
  };
  const onGoToLogin = () => {
    if (emailLoginModalOpen) {
      showModal(AuthModals.LOGIN);
    } else {
      showModal();
      setType(LoginType.Login);
      replace({ pageKey: "login" });
    }
  };

  return (
    <Dialog open={forgotPasswordModalOpen} onOpenChange={onBack}>
      <DialogContent className="w-[343px]">
        <View className="flex flex-col items-center">
          <Typography color="brand-primary">Forgot your password?</Typography>
          <Info
            size={60}
            className="my-2 color-foreground-attenuated"
            weight="fill"
          />
          <Typography variant="body-md" align="center">
            We’ve switched our app so you can login with just your phone number,
            even if you signed up with an email previously. If you forgot what
            number you signed up with, you can always contact support at{" "}
          </Typography>
          <Typography variant="body-md" className="pt-2">
            +65 9328832873
          </Typography>
          <Button
            variant="primary"
            color="CTA"
            fullWidth="full"
            rounded="full"
            className="mt-6"
            onClick={onGoToLogin}
            children="Sign in using Phone Number"
          />
          <Button
            variant="secondary"
            color="CTA2"
            fullWidth="full"
            rounded="full"
            className="mt-2"
            onClick={onBack}
            children="Back"
          />
        </View>
      </DialogContent>
    </Dialog>
  );
}

import { Button, View } from "@/components/ui";
import { WhatsappIcon } from "@/components/shared/icons";
import { useIntl } from "react-intl";
import { onChatSales } from "@/lib/chat-sales";
import { Typography } from "../typography";

export const ChatWithSales = () => {
  const intl = useIntl();
  return (
    <View
      className={`flex flex-col gap-1 rounded-xl bg-[length:64px_76px] bg-[right_8px_top_16px] bg-no-repeat md:bg-primary-surface md:bg-[url('@/assets/images/cs-illustration.png')] md:p-4`}
    >
      <Typography variant={"label-2xl"} color={"foreground-default"}>
        {intl.formatMessage({
          defaultMessage: " Need Help?",
          id: "need-help",
        })}
      </Typography>
      <Typography variant="body-xl" color="foreground-intermediate">
        {intl.formatMessage({
          defaultMessage: "Contact our Sales",
          id: "contact-sales",
        })}
      </Typography>

      <Button
        variant="primary"
        color="success"
        className="mt-2 hidden md:flex"
        iconName="whatsappLogo"
        iconColor="fill-white"
        onClick={() => {
          onChatSales(true);
        }}
        children={intl.formatMessage({
          defaultMessage: "Chat with Sales",
          id: "chat-sales",
        })}
      />
    </View>
  );
};

export const ChatWithSalesBall = () => {
  return (
    <View
      className="absolute -top-16 right-5 z-10 flex size-12 animate-bounce cursor-pointer items-center justify-center rounded-full bg-white"
      onClick={() => {
        onChatSales(true);
      }}
    >
      <WhatsappIcon className="size-8 text-success" />
    </View>
  );
};

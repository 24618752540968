import {
  Card,
  View,
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@/components/ui";
import { cn } from "@/lib/utils";
import { Star, User } from "@/components/shared/icons";
import { Typography } from "@/components/shared/typography";

export const AvatarTimeSlotCard = ({
  timeRange,
  workerName,
  active,
  workerRating,
  avatarUrl,
  onCardClick,
  onClickProfolio,
}: {
  timeRange: string;
  workerName: string;
  workerRating: number;
  avatarUrl: string;
  active?: boolean;
  onCardClick?: () => void;
  onClickProfolio: () => void;
}) => {
  return (
    <Card
      className={cn(
        "grow-0 cursor-pointer items-center px-0 py-3 hover:divide-primary-border hover:bg-primary-surface md:p-4",
        {
          "border-primary-border bg-primary-surface": active,
        },
      )}
      gap={"md"}
      onClick={onCardClick}
    >
      <Typography variant={{ sm: "label-sm", md: "label-md" }}>
        {timeRange}
      </Typography>
      <View className="flex size-8 items-center justify-center overflow-hidden rounded-full md:size-10">
        <Avatar alt="home-beauty-avatar">
          <AvatarImage src={avatarUrl ?? ""} />
          <AvatarFallback>
            <View className="p-2">
              <User className="h-full w-full" />
            </View>
          </AvatarFallback>
        </Avatar>
      </View>
      <Typography
        color="foreground-intermediate"
        variant={{ md: "body-md", sm: "body-sm" }}
      >
        {workerName}
      </Typography>
      <View className="flex cursor-pointer flex-row items-center gap-1">
        <Star weight="fill" className="color-warning" size={12} />
        <View
          className="flex flex-row divide-x border-b border-dashed"
          onClick={onClickProfolio}
        >
          <Typography
            variant={{ md: "body-md", sm: "body-xs" }}
            color="info"
            className="mr-1"
          >
            {workerRating ? `${parseFloat(workerRating.toFixed(1))}/5` : "-"}
          </Typography>
          <Typography
            variant={{ md: "body-md", sm: "body-xs" }}
            color="info"
            className="pl-1"
          >
            Portfolio
          </Typography>
        </View>
      </View>
    </Card>
  );
};

import { createRequestFactory } from "@/lib/request-factory";
import {
  SignUpDocument,
  type SignUpMutation,
  type SignUpMutationVariables,
} from "@/__generated__/graphql";
import type { UserInfo } from "@/types/users";
import type { LoginArgs } from "@/components/shared/auth-provider";

type Response = {
  userInfo: UserInfo;
  loginArgs: LoginArgs;
};

export const useSignUpStore = createRequestFactory<
  SignUpMutation,
  Response,
  SignUpMutationVariables
>({
  method: "mutation",
  graphqlDocument: SignUpDocument,
  transformFunction: (res) => {
    if (res.signUp?.result.__typename === "SignUpOk") {
      const {
        jwt,
        refreshToken,
        client: { id, user, contacts, addresses },
      } = res.signUp.result;
      const userInfo = {
        id,
        firstName: contacts[0].firstName,
        lastName: contacts[0].lastName,
        user,
        contacts,
        addresses,
      };
      const loginArgs = {
        jwt,
        clientId: userInfo.id,
        refreshToken,
      };
      return { userInfo, loginArgs } as Response;
    }
    if (res.signUp?.result.__typename === "EntityExistsProblem") {
      throw new Error(res.signUp.result.message || "Account already exists");
    }

    throw new Error(
      res.signUp?.result.__typename === "ValidationProblem"
        ? res.signUp?.result.message
        : "Sign Up failed",
    );
  },
});

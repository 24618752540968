import { View } from "@/components/ui";
import { PopularServices } from "./popular-services";
import { OtherServices } from "./other-services";
import { GetTheApp } from "@/containers/get-the-app";
import { Column, Container } from "@/components/ui/layout";
// import { SearchBar } from "@/components/shared/search-bar";
import { ShareReferralBanner } from "@/containers/banners/share-referral";
import { ClaimRewardBanner } from "@/containers/banners/claim-reward";
import { getPlatform } from "@/lib/utils";
import { PromoPopup } from "@/components/shared/promo-popup";
import { UnratedVisitWidget } from "@/components/shared/visits/unrated-visit-widget";
import { RecommendedServices } from "@/components/shared/recommended-services";
import { Promos } from "./promos";
import { PreviousBooking } from "../previous-booking";
import { useWindowDimensions } from "@/components/hooks/use-window-dimension";

export function Homepage() {
  const isWeb = getPlatform() === "web";
  const { isMobile } = useWindowDimensions();

  return (
    <Container>
      <Column desktop="8">
        <View className="mb-4 flex flex-col gap-4 px-4 pt-4 md:px-0 md:pt-10">
          {/* TODO: Disable notification on first launch */}
          {/* <SearchBar /> */}
          <PromoPopup />
          <PopularServices />
          <OtherServices />
          {isMobile && <PreviousBooking />}
          <ShareReferralBanner />
          <RecommendedServices />
          <ClaimRewardBanner />
          <Promos />
        </View>
      </Column>
      <Column desktop="4">
        <View className="hidden md:block md:pt-10">
          <GetTheApp />
          <PreviousBooking />
          {isWeb && <UnratedVisitWidget />}
        </View>
      </Column>
    </Container>
  );
}

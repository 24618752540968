import { useTokenizePaymentMethodForm } from "@/store/profile/forms/useTokenizePaymentMethodForm";
import { getErrorMessage } from "@/lib/helpers/string";
import { showToast } from "@/components/ui/toast/show-toast";
import { useAuthState } from "@/store/auth";
import { useClientStore } from "@/store/auth/client";
import { useDeletePaymentMethodStore } from "@/store/profile/deletePaymentMethod";
import { useUpdateClientPreferencesStore } from "@/store/profile/updateClientPreferences";
import { useState } from "react";
import type { UserPaymentMethod } from "@/types/users";
import { PaymentScreen } from "./payment-screen";
import { useIntl } from "react-intl";
import { usePayInvoiceStore } from "@/store/invoices/payInvoice";
import { useInvoiceState } from "@/store/invoices";
import { useRoute } from "@/components/shared/router";
import { useProfileState } from "@/store/profile";

export type PaymentScreeProps = {
  handleRemoveConfirmation: () => void;
  loading: boolean;
  onDeleteCard: () => Promise<void>;
  onUpdateCard: () => void;
  isUpdateCard: boolean;
  paymentMethod: UserPaymentMethod | null;
  infoCardDesc: string;
  handleCloseRemoveConfirmation: () => void;
  openRemoveConfirmation: boolean;
  setOpenRemoveConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
  onPay: () => Promise<void>;
  creditCardPayment: boolean;
};

export const Payment = () => {
  const { push, pull } = useRoute();
  const intl = useIntl();
  const {
    data: { userInfo },
  } = useAuthState();
  const {
    data: { invoiceId, creditCardScreenModal },
    showPaymentMethodModal,
  } = useInvoiceState();

  const {
    data: { isUpdateCard },
    setIsUpdateCard,
  } = useProfileState();

  const [openRemoveConfirmation, setOpenRemoveConfirmation] =
    useState<boolean>(false);

  const { fetch: client } = useClientStore();
  const { fetch: deletePaymentMethod, loading: deletePaymentMethodLoading } =
    useDeletePaymentMethodStore();
  const {
    fetch: updateClientPreferences,
    loading: updateClientPreferencesLoading,
  } = useUpdateClientPreferencesStore();
  const { fetch: payInvoice, loading: payInvoiceLoading } =
    usePayInvoiceStore();

  const { reset } = useTokenizePaymentMethodForm();

  const paymentMethod = userInfo.paymentMethod;

  const onDeleteCard = async () => {
    if (paymentMethod) {
      try {
        const deleteRes = await deletePaymentMethod(
          {
            requestPayload: {
              input: {
                clientId: userInfo.id,
                paymentMethodId: paymentMethod.id,
              },
            },
          },
          { selfHandleError: true },
        );

        if (deleteRes.error) {
          throw new Error(deleteRes.error);
        }

        const updatePreferences = await updateClientPreferences(
          {
            requestPayload: {
              input: {
                clientId: userInfo.id,
                preferences: {
                  auto_pay: false,
                },
              },
            },
          },
          { selfHandleError: true },
        );

        if (updatePreferences.error) {
          throw new Error(updatePreferences.error);
        }

        setOpenRemoveConfirmation(false);

        client({
          requestPayload: {
            id: userInfo.id,
          },
        });
        pull();
        reset();
      } catch (error) {
        const errMsg = getErrorMessage(error, "Error deleting payment method");
        showToast({
          title: errMsg,
          type: "error",
        });
      }
    }
  };

  const loading =
    deletePaymentMethodLoading ||
    payInvoiceLoading ||
    updateClientPreferencesLoading;

  const handleRemoveConfirmation = () => {
    setOpenRemoveConfirmation(true);
  };

  const handleCloseRemoveConfirmation = () => {
    setOpenRemoveConfirmation(false);
  };

  const onUpdateCard = () => {
    push({ pageKey: "AddCreditCard" });
    setIsUpdateCard(!isUpdateCard);
    reset();
  };

  const infoCardDesc = intl.formatMessage({
    defaultMessage:
      "We will automatically charge your card on file after the due date of your invoice. A processing fee charged by the credit card processor of 3.4% + 50 cents will be added to the bill. Alternatively, you can manually pay via PayNow directly with the invoice which does not have a processing fee.",
    id: "autoPay.info",
  });

  const handlePay = async () => {
    await payInvoice(
      { requestPayload: { input: { invoiceId } } },
      { selfHandleError: true },
    );

    const successMsg = `Success pay Invoice #${invoiceId}`;
    showToast({
      title: successMsg,
      type: "success",
    });

    showPaymentMethodModal();
    client({
      requestPayload: {
        id: userInfo.id,
      },
    });
    push({ pageKey: "invoices" });
  };

  return (
    <PaymentScreen
      handleRemoveConfirmation={handleRemoveConfirmation}
      loading={loading}
      onDeleteCard={onDeleteCard}
      onUpdateCard={onUpdateCard}
      isUpdateCard={isUpdateCard}
      paymentMethod={paymentMethod}
      infoCardDesc={infoCardDesc}
      handleCloseRemoveConfirmation={handleCloseRemoveConfirmation}
      openRemoveConfirmation={openRemoveConfirmation}
      setOpenRemoveConfirmation={setOpenRemoveConfirmation}
      onPay={handlePay}
      creditCardPayment={creditCardScreenModal}
    />
  );
};

import { PaymentMethodScreenModal } from "@/types/invoice";
import { create } from "zustand";

type InvoiceState = {
  invoiceId: string;
  baseAmount: number;
  paidAmount: number;
  amount: number;
  balance: number;
  selectPaymentScreenModal: boolean;
  paynowScreenModal: boolean;
  creditCardScreenModal: boolean;
  addCreditCardScreenModal: boolean;
  appliedVoucher: boolean;
};

export type PaymentType = "paynow" | "credit-card" | "apply-credit";

export const useInvoiceState = create<{
  data: InvoiceState;
  setInvoiceId: (id: string) => void;
  setBaseAmount: (baseAmount: number) => void;
  setPaidAmount: (paidAmount: number) => void;
  setAmount: (amount: number) => void;
  setBalance: (balance: number) => void;
  setAppliedVoucher: (appliedVoucher: boolean) => void;
  showPaymentMethodModal: (...modalNames: PaymentMethodScreenModal[]) => void;
}>((set, get) => ({
  data: {
    invoiceId: "",
    baseAmount: 0,
    paidAmount: 0,
    amount: 0,
    totalAmount: 0,
    balance: 0,
    selectPaymentScreenModal: false,
    paynowScreenModal: false,
    creditCardScreenModal: false,
    addCreditCardScreenModal: false,
    appliedVoucher: false,
  },
  setInvoiceId: (invoiceId: string) => {
    set({ data: { ...get().data, invoiceId } });
  },
  setBaseAmount: (baseAmount: number) => {
    set({ data: { ...get().data, baseAmount } });
  },
  setPaidAmount: (paidAmount: number) => {
    set({ data: { ...get().data, paidAmount } });
  },
  setAmount: (amount: number) => {
    set({ data: { ...get().data, amount } });
  },
  setBalance: (balance: number) => {
    set({ data: { ...get().data, balance } });
  },
  setAppliedVoucher: (appliedVoucher: boolean) => {
    set({ data: { ...get().data, appliedVoucher } });
  },
  showPaymentMethodModal: (
    ...modalNames: (PaymentMethodScreenModal | null)[]
  ) => {
    set({
      data: {
        ...get().data,
        selectPaymentScreenModal: modalNames.includes(
          PaymentMethodScreenModal.SELECT_PAYMENT,
        ),
        paynowScreenModal: modalNames.includes(PaymentMethodScreenModal.PAYNOW),
        creditCardScreenModal: modalNames.includes(
          PaymentMethodScreenModal.CREDIT_CARD,
        ),
        addCreditCardScreenModal: modalNames.includes(
          PaymentMethodScreenModal.ADD_CREDIT_CARD,
        ),
      },
    });
  },
}));

import { createRequestFactory } from "@/lib/request-factory";
import {
  ClientApplyCreditDocument,
  type ClientApplyCreditMutation,
  type ClientApplyCreditMutationVariables,
} from "@/__generated__/graphql";

type Response = {
  success: boolean;
};

export const useClientApplyCreditStore = createRequestFactory<
  ClientApplyCreditMutation,
  Response,
  ClientApplyCreditMutationVariables
>({
  method: "mutation",
  graphqlDocument: ClientApplyCreditDocument,
  transformFunction: (res) => {
    if (res.clientApplyCredit) {
      return { success: res.clientApplyCredit.success };
    }
    throw new Error("Apply Credit Account failed");
  },
});

import { View } from "@/components/ui";
import { useRoute } from "@/components/shared/router";
import { bookingRoutes } from "@/components/hooks/use-booking-route";
import { Typography } from "../typography";
import { handleOpenLink } from "@/lib/platform";
import { sendAnalyticData } from "@/lib/monitoring/analytics";
import {
  type BetaServiceItem,
  type ServiceItem,
} from "@/containers/homepage/lib";

export function ServiceIcon({ icon, title, name }: ServiceItem) {
  const { push } = useRoute();
  const onClickService = () => {
    sendAnalyticData({ name: "view_service", values: { service_type: name } });
    push(bookingRoutes[name][0]);
  };

  return (
    <View
      className="native:max-w-[77px] flex flex-1 cursor-pointer flex-col items-center gap-1"
      onClick={onClickService}
    >
      <View className="flex h-[56px] w-[56px] items-center justify-center rounded-2xl bg-primary-surface p-2">
        {icon}
      </View>

      <Typography
        align="center"
        variant="body-md"
        className="line-clamp-2"
        numberOfLines={2}
      >
        {title}
      </Typography>
    </View>
  );
}

export function BetaServiceIcon({ icon, title, name, link }: BetaServiceItem) {
  const onClickService = () => {
    sendAnalyticData({
      name: "view_beta_service",
      values: { service_type: name },
    });
    handleOpenLink(link);
  };

  return (
    <View
      className="flex flex-1 cursor-pointer flex-col items-center gap-1"
      onClick={() => onClickService()}
    >
      <View className="relative">
        <View className="native:px-1 native:py-0 absolute -left-2 -top-2 z-[1000] flex items-center justify-center rounded-xl border border-warning bg-warning-surface px-1.5 py-1">
          <Typography
            variant={"body-sm"}
            align={"center"}
            className="text-warning-on-surface"
          >
            Beta
          </Typography>
        </View>
        <View className="flex h-[56px] w-[56px] items-center justify-center rounded-2xl bg-primary-surface p-2">
          {icon}
        </View>
      </View>

      <Typography
        align="center"
        variant="body-md"
        className="line-clamp-2"
        numberOfLines={2}
      >
        {title}
      </Typography>
    </View>
  );
}

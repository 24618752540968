// Import the functions you need from the SDKs you need
import type { FirebaseApp } from "firebase/app";
import { getApps, initializeApp } from "firebase/app";
import {
  getAnalytics,
  logEvent as fbLogEvent,
  setUserId,
} from "firebase/analytics";
import { app } from "@/config/env";

const firebaseConfig = {
  apiKey: app.FIREBASE.API_KEY,
  authDomain: app.FIREBASE.AUTH_DOMAIN,
  projectId: app.FIREBASE.PROJECT_ID,
  storageBucket: app.FIREBASE.STORAGE_BUCKET,
  messagingSenderId: app.FIREBASE.MESSAGING_SENDER_ID,
  appId: app.FIREBASE.APP_ID,
  measurementId: app.FIREBASE.MEASUREMENT_ID,
};

// Initialize Firebase as a Singleton
let firebaseApp: FirebaseApp;

export function getFirebaseApp(): FirebaseApp {
  if (!firebaseApp) {
    if (getApps().length === 0) {
      firebaseApp = initializeApp(firebaseConfig);
    } else {
      firebaseApp = getApps()[0];
    }
  }
  return firebaseApp;
}

const analytics = getAnalytics(getFirebaseApp());

export function logAnalyticEvent(
  name: string,
  options?: { [key: string]: unknown },
): void {
  return fbLogEvent(analytics, name, options);
}

export function setAnalyticUserId(userId: string | null) {
  setUserId(analytics, userId);
}

import { View } from "@/components/ui";
import { useRoute } from "@/components/shared/router";
import { PackageDetailCard } from "../package-detail-card";
import { useParams } from "@/components/hooks/use-params";
import { Typography } from "@/components/shared/typography";
import { IconButton } from "@/components/ui/icon-button";

export const PackageDetail = () => {
  const { pull, push } = useRoute();
  const { id } = useParams<{ id: string }>();

  if (!id) {
    push({
      pageKey: "packages",
    });
    return null;
  }

  return (
    <View className="flex flex-col gap-0 md:gap-6">
      <View className="static flex flex-row items-center justify-between border-neutral-200 p-4 pb-0 md:border-b md:p-0">
        <View className="flex flex-row items-center gap-2">
          <IconButton
            variant="ghost"
            color="foreground"
            iconName="arrowLeft"
            onClick={pull}
            className="border border-secondary-border bg-white px-0"
          />

          <Typography variant={{ md: "label-2xl", sm: "label-xl" }}>
            Package Details
          </Typography>
        </View>
      </View>

      <PackageDetailCard id={id} />
    </View>
  );
};

import type { ContactFormData } from "@/store/auth/forms/useContactForm";
import { useContactForm } from "@/store/auth/forms/useContactForm";
import { useAuthState } from "@/store/auth";
import { useRoute } from "@/components/shared/router";
import { useCreateContactStore } from "@/store/profile/createContact";
import { defaultNationCode } from "@/constants";
import { showToast } from "@/components/ui/toast/show-toast";
import { getErrorMessage } from "@/lib/helpers/string";
import { useUpdateContactStore } from "@/store/profile/updateContact";
import { useClientStore } from "@/store/auth/client";
import type { Control } from "react-hook-form";
import { CreateContactScreen } from "./create-contact-screen";

export type CreateContactProps = {
  control: Control<ContactFormData>;
  onSubmit?: () => void;
  createContactLoading?: boolean;
  trigger(): void;
};

export const CreateContact = () => {
  const { pull } = useRoute();
  const {
    data: { userInfo },
  } = useAuthState();

  const { fetch: createContact, loading: createContactLoading } =
    useCreateContactStore();
  const { fetch: updateContact, loading: updateContactLoading } =
    useUpdateContactStore();
  const { fetch: client } = useClientStore();

  const defaultValues: ContactFormData = {
    firstName: "",
    lastName: "",
    primary: false,
    email: [{ value: "" }],
    phoneNumber: [{ value: "", nationCode: defaultNationCode }],
  };

  const { control, handleSubmit, trigger } = useContactForm(defaultValues);

  const onSubmit = handleSubmit(async (data: ContactFormData) => {
    try {
      const formattedPhoneNumbers = data.phoneNumber?.map((phone) => {
        const [, countryCode] = phone.nationCode.split("/");
        return `+${countryCode}${phone.value}`;
      });

      const formattedEmails = data.email?.map((email) => email.value);

      const res = await createContact(
        {
          requestPayload: {
            input: {
              clientId: userInfo.id,
              firstName: data.firstName ?? "",
              lastName: data.lastName ?? "",
              email: formattedEmails ?? [],
              phoneNumber: formattedPhoneNumbers ?? [],
              primary: data.primary ?? false,
            },
          },
        },
        {
          selfHandleError: true,
        },
      );

      if (res.data?.contact) {
        if (res.data.contact.primary) {
          const existingContacts = userInfo.contacts || [];
          const lastPrimaryContact = existingContacts.find(
            (cont) => cont.primary,
          );

          try {
            if (lastPrimaryContact?.id) {
              const lastPrimaryRes = await updateContact(
                {
                  requestPayload: {
                    input: {
                      id: lastPrimaryContact.id,
                      firstName: lastPrimaryContact.firstName,
                      lastName: lastPrimaryContact.lastName,
                      email: lastPrimaryContact.email,
                      phoneNumber: lastPrimaryContact.phoneNumber,
                      primary: false,
                    },
                  },
                },

                { selfHandleError: true },
              );

              if (lastPrimaryRes)
                throw new Error(
                  `Last primary contact error: ${lastPrimaryRes.error}`,
                );
            }

            //TODO: send error when fail to get primary to sentry later

            showToast({
              type: "success",
              title: "Success update contact",
            });

            if (res.error) throw new Error(res.error);
          } catch (error) {
            const errMsg = getErrorMessage(error, "Error update contacts");
            showToast({
              title: errMsg,
              type: "error",
            });
          }
        }

        client({
          requestPayload: {
            id: userInfo.id,
          },
        });
        pull();
      }
      showToast({
        title: "Success create contact",
        type: "success",
      });
      if (res.error) throw new Error(res.error);
    } catch (error) {
      const errMsg = getErrorMessage(error, "Error create contact");
      showToast({
        title: errMsg,
        type: "error",
      });
    }
  });

  return (
    <CreateContactScreen
      trigger={trigger}
      control={control}
      onSubmit={onSubmit}
      createContactLoading={createContactLoading && updateContactLoading}
    />
  );
};

import { View, Button, Badge } from "@/components/ui";
import {
  User,
  CaretRight,
  Invoice,
  CreditCard,
  FileText,
  ShieldCheck,
} from "@/components/shared/icons";
import { Typography } from "@/components/shared/typography";
import { useIntl } from "react-intl";
import { useAuthState } from "@/store/auth";
import { AuthModals } from "@/types/users";
import { WhatsappSupportBanner } from "@/components/shared/whatsapp-support-banner";
import { links } from "@/constants";
import { useRoute } from "@/components/shared/router";
import { handleOpenLink } from "@/lib/platform";

enum AccountOverviewNavigateEnum {
  AccountInfo = "Account_Info",
  AccountInvoices = "Account_Invoices",
  AccountPayment = "Account_Payment",
  AccountTermOfServices = "AccountTermOfServices",
  AccountPrivacyPolicy = "AccountPrivacyPolicy",
}

export const AccountOverview = () => {
  const intl = useIntl();
  const { push } = useRoute();

  const {
    showModal,
    data: { userInfo },
  } = useAuthState();

  const paymentMethod = useAuthState(({ data }) => data.userInfo.paymentMethod);

  const onClickNavigate = (route: AccountOverviewNavigateEnum) => {
    switch (route) {
      case AccountOverviewNavigateEnum.AccountInfo:
        push({ pageKey: "accountInfo" });
        break;

      case AccountOverviewNavigateEnum.AccountInvoices:
        push({ pageKey: "invoices" });
        break;

      case AccountOverviewNavigateEnum.AccountPayment:
        push({ pageKey: paymentMethod ? "payment" : "AddCreditCard" });
        break;

      case AccountOverviewNavigateEnum.AccountTermOfServices:
        handleOpenLink(links.legalTermCondition);
        break;

      case AccountOverviewNavigateEnum.AccountPrivacyPolicy:
        handleOpenLink(links.legalPrivacyPolicy);
        break;

      default:
        push({ pageKey: "accountInfo" });
    }
  };

  return (
    <View className="flex flex-col gap-6 md:hidden">
      <View>
        <Typography variant="body-xl" className="mb-3">
          {intl.formatMessage({
            defaultMessage: "Account",
            id: "account",
          })}
        </Typography>

        <View
          className="flex w-full flex-1 flex-row items-center gap-3 border-b-2 border-secondary-border py-3"
          onClick={() =>
            onClickNavigate(AccountOverviewNavigateEnum.AccountInfo)
          }
        >
          <User className="size-6 text-foreground-intermediate" />
          <Typography variant="bold-lg">
            {intl.formatMessage({
              defaultMessage: "Account Info",
              id: "account.info",
            })}
          </Typography>
          <CaretRight className="ml-auto size-5" size={20} />
        </View>

        <View
          className="flex w-full flex-1 flex-row items-center gap-3 border-b-2 border-secondary-border py-3"
          onClick={() =>
            onClickNavigate(AccountOverviewNavigateEnum.AccountInvoices)
          }
        >
          <Invoice className="size-6 text-foreground-intermediate" />
          <Typography variant="bold-lg">
            {intl.formatMessage({
              defaultMessage: "Invoices",
              id: "account.invoices",
            })}
          </Typography>
          {userInfo.billingAccount.totalOverdueInvoice ? (
            <Badge outline={false} variant="danger-fill" className="px-3">
              {userInfo.billingAccount.totalOverdueInvoice}
            </Badge>
          ) : undefined}
          <CaretRight className="ml-auto size-5" size={20} />
        </View>

        <View
          className="flex w-full flex-1 flex-row items-center gap-3 border-b-2 border-secondary-border py-3"
          onClick={() =>
            onClickNavigate(AccountOverviewNavigateEnum.AccountPayment)
          }
        >
          <CreditCard className="size-6 text-foreground-intermediate" />

          <Typography variant="bold-lg">
            {intl.formatMessage({
              defaultMessage: "Payment",
              id: "account.payment",
            })}
          </Typography>

          <CaretRight className="ml-auto size-5" size={20} />
        </View>
      </View>

      <View className="border-b-2 border-secondary-border pb-4">
        <WhatsappSupportBanner />
      </View>

      <View>
        <Typography variant="body-xl" className="mb-3">
          Other Info
        </Typography>
        <View
          className="flex w-full flex-1 flex-row items-center gap-3 border-b-2 border-secondary-border py-3"
          onClick={() =>
            onClickNavigate(AccountOverviewNavigateEnum.AccountTermOfServices)
          }
        >
          <FileText className="size-6 text-foreground-intermediate" />

          <Typography variant="bold-lg">Terms of Services</Typography>

          <CaretRight className="ml-auto size-5" size={20} />
        </View>

        <View
          className="flex w-full flex-1 flex-row items-center gap-3 border-b-2 border-secondary-border py-3"
          onClick={() =>
            onClickNavigate(AccountOverviewNavigateEnum.AccountPrivacyPolicy)
          }
        >
          <ShieldCheck className="size-6 text-foreground-intermediate" />

          <Typography variant="bold-lg">Privacy Policy</Typography>

          <CaretRight className="ml-auto size-5" size={20} />
        </View>
      </View>

      <View className="flex flex-col gap-4">
        <Button
          variant="secondary"
          color="danger"
          onClick={() => showModal(AuthModals.LOGOUT)}
          iconName="signOut"
          iconColor="danger"
          children={intl.formatMessage({
            defaultMessage: "Logout",
            id: "logout",
          })}
        />
      </View>
    </View>
  );
};

import { BottomDrawerModal } from "@/components/shared/bottom-drawer-modal";
import { Button, DialogFooter, DialogHeader, View } from "@/components/ui";
import { Divider } from "@/components/shared/divider";
import useShareSocialMedia from "./useSharerSocialMedia";
import { useSharerModalStore } from "@/store/sharer";
import { Typography } from "@/components/shared/typography";
import { ShareButton } from "./share-button";
export function ShareReferralModal() {
  const { open, setOpen } = useSharerModalStore();

  const {
    copyLinkAction,
    sharerOtherAction,
    whatsappShareAction,
    telegramShareAction,
    smsShareAction,
    facebookMessengerShareAction,
  } = useShareSocialMedia();

  const handleCopy = () => {
    setOpen(!open);
    copyLinkAction();
  };

  if (open) {
    return (
      <BottomDrawerModal open={open} onOpenChange={setOpen}>
        <DialogHeader>
          <Typography variant={{ md: "label-2xl", sm: "label-xl" }}>
            Share this code with family and friends
          </Typography>
        </DialogHeader>
        <View className="my-2 flex flex-1 flex-row justify-center gap-4">
          <ShareButton
            iconName="whatsappLogo"
            onClick={whatsappShareAction}
            label="WhatsApp"
          />
          <ShareButton
            iconName="telegramLogo"
            onClick={telegramShareAction}
            label="Telegram"
          />
          <ShareButton
            iconName="messengerLogo"
            onClick={facebookMessengerShareAction}
            label="Messenger"
          />
          <ShareButton
            iconName="chatDots"
            onClick={smsShareAction}
            label="SMS"
          />
        </View>
        <Divider />
        <DialogFooter>
          <View className="flex flex-1 flex-row justify-center gap-2">
            <Button
              variant="subtle"
              onClick={handleCopy}
              children="Copy"
              iconName="copy"
              fullWidth="full"
            />
            <Button
              variant="subtle"
              onClick={sharerOtherAction}
              children="Other"
              iconName="dotsThreeOutline"
              fullWidth="full"
            />
          </View>
        </DialogFooter>
      </BottomDrawerModal>
    );
  }

  return null;
}

import { Card, CardContent } from "@/components/ui";
import { Ratings } from "@/components/ui/ratings";
import { useMemo } from "react";
import { Typography } from "../../typography";

export function VisitRating({
  rating,
  variant = "visitCard",
  onClick,
}: {
  rating: number;
  variant?: "visitCard" | "visitDetail";
  onClick?(rate?: number): void;
}) {
  const label = useMemo(() => {
    if (!rating) {
      return "Let's rate it";
    }

    switch (variant) {
      case "visitDetail":
        return "Reviewed";

      default:
        return "You have rated it";
    }
  }, [rating, variant]);

  return (
    <Card className="bg-transparent" radius="xl">
      <CardContent className="flex flex-row items-center justify-between gap-2 px-3 py-2">
        <Typography
          variant={variant === "visitDetail" ? "body-md" : "label-md"}
          className="flex-1"
        >
          {label}
        </Typography>
        <Ratings
          variant="yellow"
          rating={rating}
          onClick={onClick}
          className="gap-2"
        />
      </CardContent>
    </Card>
  );
}

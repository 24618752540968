import { Button } from "@/components/ui";
import { ErrorMessage } from "../../error-message";
import { PhoneNumberInput } from "@/components/shared/phone-number-input";
import type {
  LoginFormData,
  LoginFormReturn,
} from "@/store/auth/forms/useLoginForm";
import { useIntl } from "react-intl";

export const PhoneNumberInputForm = ({
  onSubmitForm,
  formReturn,
  loading,
  errorMessage,
}: {
  onSubmitForm: (data: LoginFormData) => void;
  loading?: boolean;
  errorMessage?: string;
  formReturn: LoginFormReturn;
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitted },
    trigger,
  } = formReturn;
  const intl = useIntl();
  const onSubmitFormData = handleSubmit((data) =>
    onSubmitForm({
      ...data,
      nationCode: data.nationCode.split("/")[1],
    }),
  );

  return (
    <>
      <PhoneNumberInput control={control} errors={errors} trigger={trigger} />

      {isSubmitted && (
        <ErrorMessage errorMessage={errorMessage || ""} className="mt-4" />
      )}

      <Button
        variant="primary"
        color="CTA"
        rounded="full"
        loading={loading}
        onClick={onSubmitFormData}
        className="mb-4 mt-4"
        disabled={!!(errors?.nationCode || errors?.phoneNumber)}
        children={intl.formatMessage({
          defaultMessage: "Sign In",
          id: "sign-in",
        })}
      />
    </>
  );
};
